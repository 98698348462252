import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
import 'vue2-animate/dist/vue2-animate.min.css'
import 'ant-design-vue/dist/antd.css'
import Antd from 'ant-design-vue'
import VueLazyload from 'vue-lazyload'
import Draggable from 'vuedraggable'
import VueFriendlyIframe from 'vue-friendly-iframe'
import AppPage from '@/layout/app-page'
import Pagination from '@/components/pagination'
import Drawer from '@/components/drawer'
import Modal from '@/components/modal'
import Panel from '@/components/panel'
import Loading from '@/components/loading'
import Tag from '@/components/tag'
import Status from '@/components/status'
import ContextMenu from '@/components/context-menu'
import VueClipboard from 'vue-clipboard2'
import Dispatcher from '@/utils/dispatcher'
import ImageModal from '@/components/image-modal'
import initConstantsToApp from '@/constants/index'


Vue.use(Antd)
Vue.use(ContextMenu)
Vue.use(VueClipboard)
Vue.use(VueLazyload)
Vue.use(VueFriendlyIframe)
Vue.use(Dispatcher)
Vue.component(AppPage.name, AppPage)
Vue.component(Pagination.name, Pagination)
Vue.component(Drawer.name, Drawer)
Vue.component(Loading.name, Loading)
Vue.component(Status.name, Status)
Vue.component(Tag.name, Tag)
Vue.component(Modal.name, Modal)
Vue.component(Panel.name, Panel)
Vue.component('draggable', Draggable)
Vue.component(ImageModal.name, ImageModal)

Vue.config.productionTip = false

initConstantsToApp(Vue)

new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
  }
}).$mount('#app')
