<template>
  <a-modal
      v-model="visible"
      :title="title"
      :mask-closable="closable"
      :closable="closable"
      :confirm-loading="loading"
      :ok-text="okText"
      :cancel-text="cancelText"
      :z-index="zIndex"
      :ok-button-props="{ props: { disabled: buttonDisabled } }"
      :cancel-button-props="{ props: { disabled: !closable || buttonDisabled } }"
      :width="width"
      :body-style="bodyStyle"
  >
    <div style="position: relative;">
      <slot></slot>
    </div>
    <div slot="footer">
      <div v-if="!buttonDisabled">
        <a-button @click="handleCancel" :loading="loading">{{cancelText}}</a-button>
        <slot name="buttons"></slot>
        <a-button type="primary" @click="handleOk" :loading="loading">{{okText}}</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'modal',
  props: {
    title: { type: String, default: '对话框' },
    loading: { type: Boolean, default: false },
    okText: { type: String, default: '确定' },
    cancelText: { type: String, default: '取消' },
    zIndex: { type: Number, default: 1000 },
    width: { type: Number, default: 500 },
    maxHeight: { type: Number, default: 0 },
    buttonDisabled: { type: Boolean, default: false }
  },
  data () {
    return {
      visible: false
    }
  },
  computed: {
    closable () {
      return !this.loading
    },
    bodyStyle () {
      if (this.maxHeight > 0) {
        return {
          'max-height': this.maxHeight + 'px',
          'overflow-y': 'auto'
        }
      } else {
        return {}
      }
    }
  },
  watch: {
    visible (v) {
      if (!v) {
        this.$emit('closed')
      }
    }
  },
  methods: {
    open () {
      this.visible = true
    },
    close () {
      if (this.closable) {
        this.visible = false
      }
    },
    handleOk () {
      this.$emit('ok')
    },
    handleCancel () {
      this.visible = false
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
