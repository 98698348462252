<template>
  <div class="task-list-content">
    <div class="header">
      <div class="title">{{ businessTypeTitle(businessType) }}</div>
      <div class="label">{{ businessTypeDescribe(businessType) }}</div>
      <div class="new-btn" @click="handleNewTask">
        <a-icon type="plus" style="margin-right: 8px;"/>新建任务
      </div>
    </div>
    <div class="task-content">
      <div class="task-list">
        <template v-for="task in taskArr">
          <div class="task-item" :class="{'active' : currentTask && currentTask.id === task.id}" :key="task.id">
            <div class="info" @click="handleTaskClick(task)">
              <img width="40" height="40" :src="taskIconStr(task.image)">
              <span style="font-size: 13px;">{{task.title}}</span>
              <span style="font-size: 8px;" v-show="task.status === STATUS_LOADING()">(AI准备中)</span>
              <span style="font-size: 8px;" v-show="task.status === STATUS_CALCULATION()">(AI计算中)</span>
              <span style="font-size: 8px;" v-show="task.status === STATUS_FINISH()">(已完成)</span>
            </div>
            <div class="opt">
              <div v-if="task.status === STATUS_LOADING() || task.status === STATUS_CALCULATION()">
                <a-spin>
                  <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                </a-spin>
              </div>
              <div class="opt-wrap" v-else>
<!--                <div class="opt-point" @click.stop="handleEditPointProfileClick(task)">-->
<!--                  <a-icon type="edit" />-->
<!--                </div>-->
                <div class="opt-point" @click.stop="handleOptPointProfileClick(task.id)">
                  <a-icon type="ellipsis" />
                </div>
                <div class="delete-point-menu" v-if="optPointMenuVisible && task.id === optPointMenuTaskId">
                  <div class="m-item" @click="handleEditPointProfileClick(task)"><a-icon type="edit" class="m-icon"/>修改</div>
                  <div class="m-item" @click="handleDeleteTask(task)"><a-icon type="delete" class="m-icon" />删除</div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <modal ref="updateAiTaskTitle"
           title="修改任务标题"
           ok-text="修改 "
           @ok="updateAiTaskTitleHandleOk"
           @cancel="updateAiTaskTitleHandleCancel">
      <a-input v-if="editingAiTask" :placeholder="editingAiTask.title" v-model="newTitle" />
    </modal>
  </div>
</template>

<script>
import menuData from '../../../../src/layout/workspace-menu-data'
import { addAITask, getAITaskList, updateAITaskTitle, deleteAITask } from '@/http/api/ai-task'
import kit from '@/utils/kit'
import { STATUS_CALCULATION, STATUS_FINISH, STATUS_LOADING } from '@/pages/workspace/agent-platform/task-constants'

export default {
  props: {
    currentTask: { type: Object },
    businessType: { type: String }
  },
  data () {
    return {
      menus: menuData,
      taskArr: [],
      isCreatingTask: false,
      optPointMenuVisible: false,
      optPointMenuTaskId: '',
      isLoadingUpdateTitle: false,
      editingAiTask: null,
      taskAssign: {
        img: '',
        mask: '',
        description: '',
        customDescription: '',
        negativeDescription: '',
        templateSelected: [],
        loadingSegment: false,
        status: '',
        processTotal: 0,
        imgWidth: 0,
        imgHeight: 0,
        maskWidth: 0,
        maskHeight: 0,
        maskSegmentArr: [],
        originMaskSegment: null,
        regionBtnShow: true
      },
      newTitle: ''
    }
  },
  watch: {
    businessType (newVal) {
      this.handleGetAITaskList()
      this.$emit('handleTaskClick', null)
    }
  },
  computed: {
    taskIconStr () {
      return function (url) {
        return kit.str.isBlank(url) ? require('../../../assets/images/svg/mask.svg') : url
      }
    },
    businessTypeTitle () {
      return function (val) {
        const obj = menuData.find(item => item.type === val)
        return obj.title
      }
    },
    businessTypeDescribe () {
      return function (val) {
        const obj = menuData.find(item => item.type === val)
        return obj.describe
      }
    }
  },
  mounted () {
    this.handleGetAITaskList()
  },
  methods: {
    STATUS_LOADING () {
      return STATUS_LOADING
    },
    STATUS_CALCULATION () {
      return STATUS_CALCULATION
    },
    STATUS_FINISH () {
      return STATUS_FINISH
    },
    findTaskFormTaskArr (taskId) {
      return this.taskArr.find(item => item.id === taskId)
    },
    handleGetAITaskList () {
      getAITaskList()
        .complete(() => {})
        .success(res => {
          this.taskArr = res.data.map(item => {
            return Object.assign({}, item, this.taskAssign)
          })
        })
        .send(this.businessType)
    },
    handleNewTask () {
      if (!this.isCreatingTask) {
        this.isCreatingTask = true
        addAITask()
          .complete(() => {
            this.isCreatingTask = false
          })
          .success(res => {
            const task = Object.assign({}, this.taskAssign, res.data)
            this.taskArr.unshift(task)

            this.handleTaskClick(task)
          })
          .send(this.businessType)
      }
    },
    handleTaskClick (task) {
      this.$emit('handleTaskClick', task)
    },
    handleEditPointProfileClick (task) {
      this.newTitle = ''
      this.editingAiTask = task
      this.$refs.updateAiTaskTitle.open()
    },
    handleOptPointProfileClick (taskId) {
      if (!this.optPointMenuVisible) {
        kit.dom.addEvent(document.documentElement, 'click', this.hideDeletePointMenu)
        this.optPointMenuVisible = true
      }
      this.optPointMenuTaskId = taskId
    },
    hideDeletePointMenu () {
      this.optPointMenuVisible = false
      this.optPointMenuTaskId = ''
      kit.dom.removeEvent(document.documentElement, 'click', this.hideDeletePointMenu)
    },
    handleDeleteTask (task) {
      const that = this
      this.$confirm({
        title: '提示',
        content: h => <div style="color:red;">确定要删除任务[{task.title}]吗?</div>,
        onOk () {
          deleteAITask()
            .complete(() => {})
            .success(res => {
              kit.arr.remove(that.taskArr, task, 'id')
              that.$emit('handleDeleteTask', task.id)
            })
            .send(task.id)
        }
      })
    },
    updateAiTaskTitleHandleOk () {
      if (!this.isLoadingUpdateTitle) {
        this.isLoadingUpdateTitle = true
        updateAITaskTitle()
          .complete(() => {
            this.updateAiTaskTitleHandleCancel()
          })
          .success(res => {
            this.editingAiTask.title = this.newTitle
          })
          .send(this.editingAiTask.id, this.newTitle)
      }
    },
    updateAiTaskTitleHandleCancel () {
      this.$nextTick(() => {
        this.isLoadingUpdateTitle = false
        this.$refs.updateAiTaskTitle.close()
      })
    }
  }
}
</script>

<style scoped lang="less">
.task-list-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    position: relative;
    width: 100%;
    flex-shrink: 0;
    padding: 0 8px;
    .title {
      color: #403d3c;
      margin-bottom: 4px;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
    }
    .label {
      color: #7d7675;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
    }

    .new-btn {
      position: relative;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #7530fe;
      border-radius: 12px;
      color: #7530fe;
      margin: 16px 0;
      cursor: pointer;
    }
  }
  .task-content {
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1;
    overflow-y: auto;
    .task-list {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      .task-item {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 8px;
        width: 100%;
        height: 56px;
        margin-bottom: 16px;
        cursor: pointer;
        .info {
          display: flex;
          align-items: center;
          flex: 1;
          img {
            border-radius: 2px;
            object-fit: cover;
          }
          span {
            margin-left: 12px;
          }
        }

        &.active {
          background-color: rgba(80,80,80,0.1);
        }
      }
      .opt {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .opt-wrap {
          position: relative;
          .opt-point {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
              background-color: #e3e3e3;
            }
          }
          .delete-point-menu {
            position: absolute;
            right: 0;
            cursor: pointer;
            top: 32px;
            color: #403d3c;
            z-index: 88;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: auto;
            min-width: 88px;
            height: auto;
            font-size: 16px;
            border: 1px solid #f5f3f3;
            background-color: #ffffff;
            border-radius: 12px;
            .m-item {
              position: relative;
              .m-icon {
                margin-right: 5px;
              }
              &:not(:last-child) {
                margin-bottom: 5px;
                padding: 8px 12px 3px 12px;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
              }
              &:last-child {
                padding: 3px 12px 8px 12px;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
              }
              &:hover {
                background-color: #e3e3e3;
              }
            }
          }
        }
      }
    }
  }
}
</style>
