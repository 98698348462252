<template>
  <a-result status="404" title="404" sub-title="对不起，你访问的页面不存在。">
    <template #extra>
      <a-button type="primary" @click="goHome">
        返回主页
      </a-button>
    </template>
  </a-result>
</template>

<script>
import { ROUTE_HOME } from '@/router/router-constants'

export default {
  methods: {
    goHome () {
      this.$router.push({ name: ROUTE_HOME })
    }
  }
}
</script>

<style scoped>

</style>
