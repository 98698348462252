/**
 * @author jiangxingshang
 * @date 2023/3/8
 */
import kit from '@/utils/kit'

export const POINT_HISTORY_CONSUME = 'consume'
export const POINT_HISTORY_SYSTEM = 'system'
export const POINT_HISTORY_CHARGE = 'charge'

const mapping = {
  consume: POINT_HISTORY_CONSUME,
  system: POINT_HISTORY_SYSTEM,
  charge: POINT_HISTORY_CHARGE
}

const text = {}
text[mapping.consume] = '消费'
text[mapping.system] = '管理员充值'
text[mapping.charge] = '客户充值'


export default {
  mapping: mapping,
  text: text,
  options: kit.obj.toArray(text)
}
