import defineApi from '../define-api'
import http from '../application-api'
import { userStore } from '@/store'
import path from '@/http/api/url-prefix'

/**
 * 发送登录请求，将服务端返回的用户信息保存到本地。
 * @type {Function}
 */
export const login = defineApi((config, phone, code) => {
  config = Object.assign({
    params: {
      phone: phone,
      code: code
    }
  }, config)
  const successCallback = config.success
  config.success = function (resp) {
    userStore.setUserInfo(resp.data)
    successCallback && successCallback.apply(config.context, arguments)
  }
  http.post(path('/login'), config)
})

/**
 * 发送登出请求，请求成功后，移除本地登录用户信息，并新获取sessionId后再将回调函数交给外部。
 * @type {Function}
 */
export const logout = defineApi((config) => {
  const successCallback = config.success
  config.success = function (resp) {
    userStore.removeUserInfo()
    successCallback && successCallback.apply(config.context, arguments)
  }
  http.post(path('/logout'), config)
})

export const getValidateCodeForPhone = defineApi((config, phone) => {
  config.params = {
    phone: phone
  }
  http.get('/customer/login/validate_code', config)
})

export const getCustomerMy = defineApi((config) => {
  http.get('/customer/my', config)
})

export const getPointHistoryListMy = defineApi((config, params) => {
  config.params = params
  http.get('/point_history/list/my', config)
})
