<template>
  <div class="output-content">
    <div class="task-output-content" v-if="currentTask">
      <div class="task-title">
        <div class="title">{{currentTask.title}}</div>
      </div>
      <div>
        <div class="task-output">
          <template v-for="(exec, index) in currentTaskExecList">
            <div :key="exec.id" class="task-result">
              <div class="result-info">
                <div class="info-content">
                  <div class="number" v-if="exec.number">{{exec.number}}</div>

                  <template v-if="businessType === TYPE_RTT()">
                    <div class="info-prompt" v-if="exec.img2Img && exec.img2Img.type === 'input'">
                      <div class="result-prompt-text" v-if="exec.img2Img.prompt">{{exec.img2Img.prompt}}</div>
                      <div class="result-prompt-text" v-if="exec.img2Img.negativePrompt">排除：{{exec.img2Img.negativePrompt}}</div>
                    </div>
                    <div class="info-prompt" v-if="exec.img2Img && exec.img2Img.type === 'template'">
                      <div class="result-prompt-text">{{exec.img2Img.templateTitleList.join(',')}},{{exec.img2Img.templatePrompt}}</div>
                    </div>
                  </template>

                  <template v-if="businessType === TYPE_ENLARGE()">
                    <div class="info-prompt" v-if="exec.enlargeDto && exec.enlargeDto.type === 'scale'">
                      <div class="result-prompt-text">{{exec.enlargeDto.scales.join(',')}}</div>
                    </div>
                    <div class="info-prompt" v-if="exec.enlargeDto && exec.enlargeDto.type === 'free'">
                      <div class="result-prompt-text">top:{{exec.enlargeDto.top}}px; right:{{exec.enlargeDto.right}}px; bottom:{{exec.enlargeDto.bottom}}px; left:{{exec.enlargeDto.left}}px;</div>
                    </div>
                  </template>

                  <div class="info-opt">
                    <div v-if="exec.processTotal > 0" class="edit-again-btn" @click="cancelAITaskExecListBtnClick(exec.id)"><a-icon class="icon" type="close-circle" />取消排队</div>
                    <div class="edit-again-btn" @click="handleExecResultEditAgainClick(exec)"><a-icon class="icon" type="control" />再次编辑</div>
                  </div>
                </div>
                <div><span class="num">第{{currentTaskExecList.length - index}}次执行</span><span class="time">{{exec.executeTime}}</span><span class="consume-point">消耗点数:{{exec.consumePoint}}</span></div>
              </div>

              <div class="result-imgs"
                   :style="{'grid-template-columns': `repeat(${exec.imageResult.length}, 1fr)`}"
              >
                <template v-for="(item, i) in exec.imageResult">
                  <div class="item" :key="item.id" @click="handleResultImgClick(exec, i)">

                    <div class="item-wrapper">
                      <template v-if="businessType === TYPE_RTT()">
                        <img class="origin-image" :src="item.status === 'completed' || item.status === 'filter' ? item.image : exec.img2Img.image">
                      </template>
                      <template v-if="businessType === TYPE_ENLARGE()">
                        <img class="origin-image" :src="item.status === 'completed' || item.status === 'filter' ? item.image : exec.enlargeDto.image">
                      </template>

                      <div class="result-content">
                        <div class="text-tip fail" v-if="item.status === 'failed'">{{item.statusMessage}}</div>
                        <div class="text-tip fail" v-else-if="item.status === 'filter'">{{item.statusMessage}}</div>
                        <div class="text-tip fail" v-else-if="item.status === 'cancel'">用户取消</div>
                        <div class="text-tip queue" v-else-if="item.status === 'queue'">正在排队</div>
                        <div class="text-tip progress" v-else-if="item.status === 'progress'"><a-icon type="loading" /> 正在AI运算...</div>
                      </div>

                      <div class="download-btn" v-if="item.status === 'completed'" @click.stop>
                        <a-dropdown size="small">
                          <a-menu slot="overlay" @click="handleExecImageDownloadBtnClickFor($event, exec, item)">
                            <a-menu-item key="origin">下载原图</a-menu-item>
<!--                            <a-menu-item key="high">下载高清图</a-menu-item>-->
                          </a-menu>
                          <a-button style="margin-left: 8px"> 下载 <a-icon type="down" /> </a-button>
                        </a-dropdown>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import kit from '@/utils/kit'
import {
  getAITaskExecHighImage,
  getAITaskExecImageGenerateCheckResult,
  getAITaskExecListByTaskId,
  cancelAITaskExecListById
} from '@/http/api/ai-task'
import { STATUS_CALCULATION, STATUS_FINISH, STATUS_CANCEL } from '@/pages/workspace/agent-platform/task-constants'
import { TYPE_ENLARGE, TYPE_RTT } from '@/pages/workspace/business-type'

export default {
  props: {
    currentTask: { type: Object },
    businessType: { type: String }
  },
  data () {
    return {
      currentTaskExecList: [],
      changeCustomerMyInfoTimeOut: null,
      checkResultInterval: null,
      checkResultExecutionIds: [],
      isCheckingResult: false,
      isDelayCheckResult: false
    }
  },
  watch: {
    businessType (newVal) {
      this.clearCheckResultInterval()
    }
  },
  computed: {
  },
  beforeDestroy () {
    this.clearCheckResultInterval()
    if (this.changeCustomerMyInfoTimeOut) {
      clearTimeout(this.changeCustomerMyInfoTimeOut)
    }
    this.isCheckingResult = false
    this.isDelayCheckResult = false
  },
  methods: {
    findFromCheckResultExecutionIds (taskId) {
      return this.checkResultExecutionIds.find(item => item.taskId === taskId)
    },
    handleGetAITaskExecListByTaskId (taskId, isRefreshPoint) {
      this.currentTaskExecList = []
      getAITaskExecListByTaskId()
        .complete(() => {})
        .success(res => {
          this.currentTaskExecList = res.data.map(item => Object.assign({}, item, { imageResult: [] }))

          this.$emit('changeOpenEditShow', this.currentTaskExecList.length === 0)

          this.handleALLAITaskExecImage()

          const exec = this.currentTaskExecList.filter(item => item.processTotal > 0)
          if (exec.length > 0) {
            for (let i = 0; i < exec.length; i++) {
              if (this.checkResultExecutionIds.findIndex(item => item.executionId === exec[i].id) === -1) {
                this.checkResultExecutionIds.push({
                  executionId: exec[i].id,
                  taskId: taskId
                })
                this.currentTask.status = STATUS_CALCULATION
              }
            }
            this.handleAITaskExecImageGenerateCheckResult()
          } else {
            if (this.checkResultExecutionIds.length === 0) {
              this.clearCheckResultInterval()
            }
          }
        })
        .send(taskId)
      if (isRefreshPoint) {
        this.$dispatcher.dispatch('changeCustomerMyInfoListen')
      }
    },
    handleALLAITaskExecImage () {
      const ids = this.currentTaskExecList.map(item => item.id).join(',')
      getAITaskExecImageGenerateCheckResult()
        .success(res => {
          const data = res.data
          this.currentTaskExecList.forEach(item => {
            const obj = data[item.id]
            if (obj) {
              item.imageResult = obj
            }
          })
        })
        .send(ids)
    },
    handleAITaskExecImageGenerateCheckResult () {
      if (!this.checkResultInterval) {
        this.checkResultInterval = setInterval(this.handleCheckResult(), 5000)
      }
    },
    handleCheckResult () {
      if (this.checkResultExecutionIds.length > 0) {
        this.isCheckingResult = true
        const executionIds = this.checkResultExecutionIds.map(item => item.executionId).join(',')
        getAITaskExecImageGenerateCheckResult()
          .final(() => {
            this.isCheckingResult = false
            if (this.isDelayCheckResult) {
              this.isDelayCheckResult = false
              this.resetHandleAITaskExecImageGenerateCheckResult()
            }
          })
          .success(res => {
            const data = res.data
            const finishArr = []
            this.checkResultExecutionIds.forEach(item => {
              const objs = data[item.executionId]
              const total = objs.length

              if (item.taskId === this.currentTask.id) {
                const taskExec = this.currentTaskExecList.find(o => o.id === item.executionId)
                if (taskExec && objs) {
                  taskExec.imageResult = objs
                  this.$forceUpdate()
                }
              }

              let completedNum = 0

              for (let i = 0; i < total; i++) {
                const obj = objs[i]
                if (obj.status === 'success' || obj.status === 'fail' || obj.status === 'failed' || obj.status === 'cancel' || obj.status === 'completed') {
                  completedNum++
                }
                if (completedNum >= total) {
                  finishArr.push(item)
                }
              }
            })

            if (finishArr.length > 0) {
              this.$dispatcher.dispatch('changeCustomerMyInfoListen')
            }

            for (let i = 0; i < finishArr.length; i++) {
              const taskId = finishArr[i].taskId
              const executionId = finishArr[i].executionId
              kit.arr.remove(this.checkResultExecutionIds, finishArr[i], 'executionId')
              const obj = this.checkResultExecutionIds.find(o => o.taskId === taskId)
              if (!obj) {
                const task = this.$parent.$refs.taskListContent.findTaskFormTaskArr(taskId)
                const exec = this.currentTaskExecList.find(item => item.id === executionId)
                if (task) {
                  task.status = STATUS_FINISH
                  task.processTotal = 0
                  exec.processTotal = 0
                  this.$forceUpdate()
                }
              }
            }
          })
          .send(executionIds)
      } else {
        this.clearCheckResultInterval()
      }
      return this.handleCheckResult
    },
    clearCheckResultInterval () {
      if (this.checkResultInterval) {
        clearInterval(this.checkResultInterval)
        this.checkResultExecutionIds = []
        this.checkResultInterval = null
      }
    },
    handleExecResultEditAgainClick (exec) {
      this.$emit('handleExecResultEditAgain', exec)
    },
    handleResultImgClick (exec, index) {
      let arr = []
      if (this.businessType === this.TYPE_RTT()) {
        if (exec.imageResult) {
          arr = exec.imageResult.map(item => {
            return {
              ori: exec.img2Img.image,
              mask: kit.str.isBlank(exec.img2Img.imageMaskEdit) ? exec.img2Img.imageMask : exec.img2Img.imageMaskEdit,
              opi: item.image,
              showPromptDetail: false,
              imageWidth: exec.img2Img.imageWidth,
              imageHeight: exec.img2Img.imageHeight
            }
          })
        }
      } else if (this.businessType === this.TYPE_ENLARGE()) {
        if (exec.imageResult) {
          arr = exec.imageResult.map(item => {
            return {
              ori: exec.enlargeDto.image,
              mask: '',
              opi: item.image,
              showPromptDetail: false,
              imageWidth: 300,
              imageHeight: 300
            }
          })
        }
      }
      this.$emit('handleImageViewerOpen', { arr, index })
    },
    handleExecAITaskImageGenerateRes (id) {
      this.checkResultExecutionIds.push({
        executionId: id,
        taskId: this.currentTask.id
      })
      // this.handleAITaskExecImageGenerateCheckResult()
      this.handleGetAITaskExecListByTaskId(this.currentTask.id, false)
    },
    handleExecImageDownloadBtnClickFor (e, exec, item) {
      if (e.key === 'origin') {
        this.handleExecGeneralImageDownloadBtnClick(item)
      } else if (e.key === 'high') {
        this.handleExec4KImageDownloadBtnClick(exec, item)
      }
    },
    handleExecGeneralImageDownloadBtnClick (item) {
      this.imageDownload(item.image)
    },
    imageDownload (url) {
      const link = document.createElement('a')
      fetch(url).then(res => res.blob()).then(blob => {
        link.href = URL.createObjectURL(blob)
        link.download = ''
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    handleExec4KImageDownloadBtnClick (exec, item) {
      const that = this
      const highImage = exec.highImageResult[item.number]
      if (highImage) {
        that.imageDownload(highImage)
      } else {
        this.$confirm({
          content: h => <div>下载4K图,大约需要<span style="color:red;">30秒</span>,请不要离开当前操作任务</div>,
          onOk () {
            return new Promise((resolve, reject) => {
              getAITaskExecHighImage()
                .complete(() => {
                  resolve()
                })
                .success(res => {
                  if (!kit.str.isBlank(res.data)) {
                    that.imageDownload(res.data)
                    exec.highImageResult[item.number] = res.data
                  } else {
                    this.$message.error('获取高清图失败')
                  }
                })
                .send(exec.id, item.number)
            }).catch(() => {})
          },
          cancelText: '取消',
          onCancel () {}
        })
      }
    },
    cancelAITaskExecListBtnClick (id) {
      this.$confirm({
        title: '提示',
        content: '确定取消排队吗?',
        onOk: () => {
          cancelAITaskExecListById()
            .complete(() => {})
            .success(res => {
              const exec = this.currentTaskExecList.find(item => item.id === id)
              if (exec) {
                exec.status = STATUS_CANCEL
                exec.processTotal = 0
                this.$forceUpdate()
              }
              if (this.isCheckingResult) {
                this.isDelayCheckResult = true
              } else {
                this.resetHandleAITaskExecImageGenerateCheckResult()
              }
            })
            .send(id)
        }
      })
    },
    resetHandleAITaskExecImageGenerateCheckResult () {
      if (this.checkResultInterval) {
        clearInterval(this.checkResultInterval)
        this.checkResultInterval = null
      }
      this.handleAITaskExecImageGenerateCheckResult()
    },
    TYPE_RTT () {
      return TYPE_RTT
    },
    TYPE_ENLARGE () {
      return TYPE_ENLARGE
    }
  }
}
</script>

<style scoped lang="less">
@deep: ~'>>>';
.output-content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  .task-output-content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    &@{deep} ::-webkit-scrollbar {
      display: none !important;
    }
    .task-title {
      position: sticky;
      min-height: 58px;
      width: 100%;
      display: flex;
      align-items: center;
      background-color: #f5f3f3;
      padding: 16px 40px;
      top: 0;
      border-bottom: 1px solid #e0dddc;
      z-index: 1;
      .title {
        font-size: 18px;
        color: #403d3c;
        font-weight: 500;
        line-height: 26px;
      }
    }
    .task-output {
      position: relative;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 180px;
      .task-loading {
        padding: 20px 20px 20px 32px;
        color: #3d8acf;
      }
      .task-result {
        max-width: 1400px;
        padding: 20px 20px 20px 32px;
        .result-info {
          width: 100%;
          padding-left: 8px;
          .info-content {
            display: flex;
            align-items: center;
            .number {
              background-color: #3d3d3f;
              color: #fff;
              font-size: 12px;
              padding: 3px 8px;
              border-radius: 2px;
              margin-right: 10px;
            }
            .info-prompt {
              display: flex;
              flex: 1;
              flex-direction: column;
              overflow:hidden;
              .result-prompt-text {
                padding: 2px 0;
                color: #2b2b2b;
                text-overflow:ellipsis;
                white-space:nowrap;
                overflow:hidden;
              }
            }
            .info-opt {
              flex-shrink: 0;
              margin-right: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              .edit-again-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #7d7675;
                cursor: pointer;
                border-radius: 10px;
                background-color: #ffffff;
                padding: 2px 8px;
                margin: 0 8px;
                .icon {
                  transform: rotate(90deg);
                  margin-right: 3px;
                }
              }
            }
          }
          .num {
            color: #7d7675;
            background-color: #edeaea;
            border-radius: 4px;
            padding: 0 4px;
            margin-right: 4px;
          }
          .time {
            margin-left: 5px;
            color: #b8b2b2;
          }

          .consume-point {
            margin-left: 20px;
            color: #b8b2b2;
          }
        }
        .result-imgs {
          position: relative;
          display: grid;
          grid-gap: 10px;
          margin-top: 10px;
          .item {
            position: relative;
            cursor: pointer;
            display: flex;
            max-width: 500px;
            justify-content: center;
            align-items: flex-start;
            img {
              width: 100%;
              height: auto;
              object-fit: cover;
            }
            .item-wrapper {
              position: relative;
              overflow: hidden;
              .result-content {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                .text-tip {
                  margin: 20px 10px 0 10px;
                  padding: 10px 0;
                  border-radius: 15px;
                  background-color: rgba(0,0,0,.6);
                  text-align: center;
                  color: #fff;
                  &.fail {
                    color: #a94442;
                  }
                }
              }
              .download-btn {
                position: absolute;
                bottom: 10px;
                right: 10px;
                visibility: hidden;
              }
              &:hover .download-btn {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
}
</style>
