/**
 * @author jiangxingshang
 * @date 2023/3/8
 */
import kit from '@/utils/kit'

export const BUSINESS_TYPE_RTT = 'rtt'
export const BUSINESS_TYPE_HIGH_IMAGE = 'highImage'

const mapping = {
  rtt: BUSINESS_TYPE_RTT,
  highImage: BUSINESS_TYPE_HIGH_IMAGE
}

const text = {}
text[mapping.rtt] = '人台图'
text[mapping.highImage] = '高清图'


export default {
  mapping: mapping,
  text: text,
  options: kit.obj.toArray(text)
}
