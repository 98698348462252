<template>
  <div class="opt-tab-wzms">
    <span>正向提示语</span>
    <a-textarea class="description-textarea" v-model="descriptionProp" @change="handleDescriptionPropChange"/>
    <span>反向提示语</span>
    <a-textarea class="description-textarea" v-model="negativeDescriptionProp"  @change="handleNegativeDescriptionPropChange"/>
    <div class="description-tips">
      <p style="color: #403d3c;">描述你想要生成的图片内容，支持中英文，例如：</p>
      <ul style="margin-top: 12px;color: #7d7675;">
        <li>穿着碎花连衣裙的女孩走在东京街头</li>
        <li>胖胖的可爱小男孩在海边玩沙子</li>
        <li>一张豪华真皮沙发放置在酒店的大堂</li>
        <li>变形金刚玩具出现在火星基地</li>
      </ul>
      <p style="color: #403d3c;margin-top: 24px">也可以非常详细地描述画面中的人物、地点、动作、时间、天气、环境、道具、气氛等要素，并同时指定拍摄的手法，如“iPhone拍摄、眼神看向镜头、大景深”。</p>
      <div style="margin-top: 12px;color: #7d7675;">
        <p style="color: #403d3c;">案例一：</p>
        <p style="margin-top: 4px;">一位年轻的中国女性，蓬松的金色短发，纹身，高度细致的皮肤，逼真的细节眼睛，自然的皮肤纹理，自信的表情，时髦的嘻哈装扮，走在背景是城市废墟的室外，晴天，阳光，明亮，夏日午后，清晰对焦，高品质，iPhone拍摄，超逼真。</p>
      </div>
      <div style="margin-top: 12px;color: #7d7675;">
        <p style="color: #403d3c;">案例二：</p>
        <p style="margin-top: 4px;">A beautiful and elegant woman wearing a deep red V-neck dress is posing for a photo on a sailboat in front of a Hawaiian beach in the year 2020. The image should have a vintage film feel with a warm color tone and faded effect. The woman should be the main focus of the image, with the sailboat and beach in the background. Please use Artstation as a reference and include intricate details and hyperdetailed elements.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    description: { type: String },
    negativeDescription: { type: String }
  },
  watch: {
    description: {
      handler (newVal, oldVal) {
        this.descriptionProp = newVal
      }
    },
    negativeDescription: {
      handler (newVal, oldVal) {
        this.negativeDescriptionProp = newVal
      }
    }
  },
  data () {
    return {
      descriptionProp: this.description,
      negativeDescriptionProp: this.negativeDescription
    }
  },
  computed: {

  },
  methods: {
    handleDescriptionPropChange (e) {
      this.$emit('update:description', this.descriptionProp)
    },
    handleNegativeDescriptionPropChange (e) {
      this.$emit('update:negativeDescription', this.negativeDescriptionProp)
    }
  }
}
</script>

<style lang="less" scoped>
.opt-tab-wzms {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  .description-textarea {
    width: 100%;
    background-color: #faf9f9;
    height: 168px;
    border-radius: 12px;
    color: #403d3c;
    padding: 12px;
    border: none;
    outline: none;
  }
  .description-tips {
    position: relative;
    list-style-position: inside;
    margin-top: 24px;
  }
}
</style>
