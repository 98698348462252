import StoreModule from './module'
import { ROUTE_HOME, ROUTE_WORKSPACE } from '@/router/router-constants'

const KEY_USER_INFO = 'user-info'

let initState
try {
  initState = JSON.parse(localStorage.getItem(KEY_USER_INFO)) || {}
} catch (e) {
  initState = {}
}

export default {
  namespaced: true,
  state: Object.assign({
    id: null,
    name: null,
    username: null,
    phone: null,
    point: 0,
    vip: null,
    url: null,
    token: null,
    uiList: [], // UI编号
    menuList: [], // 菜单编号
    roles: [], // { id, name }
    isLogin: false
  }, initState),
  getters: {
    isLogin: state => (state.isLogin)
  },
  mutations: {
    updateToken (state, token) {
      state.token = token
      localStorage.setItem(KEY_USER_INFO, JSON.stringify(state))
    },
    updateTokenIfNone (state, token) {
      if (state.token == null || state.token.length === 0) {
        state.token = token
        localStorage.setItem(KEY_USER_INFO, JSON.stringify(state))
      }
    },
    setUserInfo (state, data) {
      Object.assign(state, data)
      state.isLogin = true
      localStorage.setItem(KEY_USER_INFO, JSON.stringify(state))
    },
    removeUserInfo (state) {
      state.id = null
      state.name = null
      state.username = null
      state.phone = null
      state.point = 0
      state.vip = null
      state.url = null
      state.token = null
      state.uiList = []
      state.menuList = []
      state.roles = []
      state.isLogin = false
      localStorage.setItem(KEY_USER_INFO, JSON.stringify(state))
    },
    setCustomerPoint (state, point) {
      state.point = point
      localStorage.setItem(KEY_USER_INFO, JSON.stringify(state))
    },
    setCustomerVip (state, vip) {
      state.vip = vip
      localStorage.setItem(KEY_USER_INFO, JSON.stringify(state))
    }
  }
}

export const USER_MODULE = 'user'
export class UserStore extends StoreModule {
  constructor (store) {
    super(USER_MODULE, store)
  }

  logout (httpConfig) {
    this.dispatch('logout', httpConfig)
  }

  updateToken (token) {
    this.commit('updateToken', token)
  }

  updateTokenIfNone (token) {
    this.commit('updateTokenIfNone', token)
  }

  // 登录成功后，保存服务端返回的用户信息，并设置用户已经登录状态
  setUserInfo (data) {
    this.commit('setUserInfo', data)
  }

  // 移除本地用户信息，并设置用户为未登录状态
  removeUserInfo () {
    this.commit('removeUserInfo')
  }

  getHomePage () {
    return ROUTE_HOME
  }

  getWorkspacePage () {
    return ROUTE_WORKSPACE
  }

  setCustomerPoint (point) {
    this.commit('setCustomerPoint', point)
  }

  setCustomerVip (vip) {
    this.commit('setCustomerVip', vip)
  }
}
