<template>
  <div class="open-edit-content" v-show="currentTask">
    <div class="edit-mask" v-show="isOpenEditShow"></div>
    <div class="edit-content" v-show="isOpenEditShow">
      <div class="opt-content">
        <div class="img-area" v-if="currentTask">
          <a-spin :spinning="spinningOneShow">
            <div class="spin-content">
              <div class="area-div">
                <div class="upload-wrap">
                  <template v-if="currentTask && currentTask.img">
                    <img class="uw-img" :src="currentTask.img" alt="origin" @click="handleInitImageSelectClick">
                  </template>
                  <div v-show="!(currentTask && currentTask.img)"
                       class="dropzone"
                       ref="dropzone"
                       @click="handleInitImageSelectClick"
                       @dragenter="dragEnter"
                       @dragleave="dragLeave"
                       @dragover="dragOver"
                       @drop="dropFile">
                    <div class="label1">点击上传</div>
                    <div class="label2">或直接将图片文件拖入此区域</div>
                    <div class="label3">图片为JPG格式，大小不超过10MB</div>
                    <input ref="initImage" name="initImage" type="file" accept="image/jpeg,image/png,image/jpg" style="display: none;" @change="handleInitImageUploadChange" />
                  </div>
                </div>
                原图
              </div>
            </div>
          </a-spin>

          <div class="scale-checkbox">
            <a-checkbox-group v-model="scaleSelected" @change="onScaleCheckboxChange">
              <a-row>
                <template v-for="so in scaleOpt">
                  <a-col :span="12" :key="so.value" class="item-col">
                    <a-checkbox :value="so.value" class="item-col-cb">
                      {{so.label}}
                    </a-checkbox>
                  </a-col>
                </template>
              </a-row>
            </a-checkbox-group>
            <a-button class="scale-custom" type="primary" @click="handleCustomMode">自定义模式</a-button>
          </div>
        </div>

        <div class="scale-content">
          <template v-if="currentType === typeScale">
            <template v-for="(value, k) in scaleSelected">
              <div class="scale-label-item" :key="k">{{value}}</div>
            </template>
          </template>
          <template v-if="currentType === typeFree">
            <div class="scale-label-item">原图</div>
            <div class="scale-label-item">top:{{freeVal.top}}</div>
            <div class="scale-label-item">right:{{freeVal.right}}</div>
            <div class="scale-label-item">bottom:{{freeVal.bottom}}</div>
            <div class="scale-label-item">left:{{freeVal.left}}</div>
          </template>
        </div>

      </div>
      <div class="bottom-bar">
        <div>本次任务将消耗点数<span class="text-danger font-large-x font-bold" style="margin-left: 5px;">{{consumeAIBusinessPoint}}</span></div>
        <a-button style="width: 300px;height: 40px;border-radius: 10px;" type="primary"
                  :loading="submitLoading"
                  @click="handleExecAiTaskExecEnlarge">执行</a-button>
      </div>
    </div>
    <div class="open-icon" :class="{'edit-show': isOpenEditShow}" @click="handleOpenEditShow">
      <a-icon type="caret-left" style="color: #7530fe;" />
    </div>
  </div>
</template>

<script>
import { getAIBusinessPointInfo, aiTaskExecEnlarge } from '@/http/api/ai-task'
import { STATUS_CALCULATION } from '@/pages/workspace/agent-platform/task-constants'

export default {
  components: {},
  props: {
    currentTask: { type: Object },
    businessType: { type: String }
  },
  data () {
    return {
      dropzoneActive: false,
      isOpenEditShow: false,
      spinningOneShow: false,
      spinningTwoShow: false,
      submitLoading: false,
      currentPromptType: 0,
      typeFree: 'free',
      typeScale: 'scale',
      optActiveTab: '0',
      textPromptType: ['input', 'template'],
      textPromptTypeStr: {
        input: '文字描述',
        template: '快捷模板'
      },
      cropSize: {
        a: { width: 1024, height: 1024 },
        b: { width: 1152, height: 896 },
        c: { width: 1216, height: 832 },
        d: { width: 1344, height: 768 },
        e: { width: 1536, height: 640 },
        f: { width: 640, height: 1536 },
        g: { width: 768, height: 1344 },
        h: { width: 832, height: 1216 },
        i: { width: 896, height: 1152 }
      },
      scaleOpt: [
        { label: '9:16', value: '9:16' },
        { label: '16:9', value: '16:9' },
        { label: '3:4', value: '3:4' },
        { label: '4:3', value: '4:3' },
        { label: '2:3', value: '2:3' },
        { label: '3:2', value: '3:2' },
        { label: '1:1', value: '1:1' }
      ],
      imageSegmentResultIntervals: {},
      imageSegmentResultIntervalsIds: {},
      consumeAIBusinessPoint: 0,
      imgInfo: {
        src: '',
        imgWidth: 0,
        imgHeight: 0,
        sWidth: 0,
        sHeight: 0,
        scale: 0,
        aspectRatio: 0,
        reduct: [],
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      scaleSelected: [],
      currentType: '',
      freeVal: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        iTop: 0,
        iLeft: 0
      }
    }
  },
  mounted () {
    getAIBusinessPointInfo()
      .complete(() => {})
      .success(res => {
        this.consumeAIBusinessPoint = res.data[this.businessType]
      })
      .send()
  },
  methods: {
    handleOpenEditShow () {
      this.isOpenEditShow = !this.isOpenEditShow
    },
    changeOpenEditShow (isOpenEditShow) {
      this.isOpenEditShow = isOpenEditShow
    },
    hideSpinningTwoShow () {
      this.spinningTwoShow = false
    },
    handleInitImageSelectClick () {
      this.$refs.initImage.click()
    },
    handleExecAiTaskExecEnlarge () {
      const img = this.currentTask.img
      if (img === '') {
        this.$message.warn('请上传图片')
      } else if (this.currentType === '' ||
        (this.currentType === this.typeScale && this.scaleSelected.length === 0) ||
        (this.currentType === this.typeFree && (this.imgInfo.top === 0 && this.imgInfo.right === 0 && this.imgInfo.bottom === 0 && this.imgInfo.left === 0))) {
        this.$message.warn('请选择比例或操作自定义模式')
      } else {
        const taskId = this.currentTask.id
        const initImage = this.dataUrlToBase64(this.currentTask.img)
        let obj = {}
        if (this.currentType === this.typeScale) {
          obj = {
            taskId: taskId,
            type: this.typeScale,
            scales: this.scaleSelected,
            image: initImage
          }
        } else if (this.currentType === this.typeFree) {
          obj = {
            taskId: taskId,
            type: this.typeFree,
            top: Math.ceil(this.imgInfo.top * this.imgInfo.scale),
            left: Math.ceil(this.imgInfo.left * this.imgInfo.scale),
            right: Math.ceil(this.imgInfo.right * this.imgInfo.scale),
            bottom: Math.ceil(this.imgInfo.bottom * this.imgInfo.scale),
            image: initImage
          }
        }
        aiTaskExecEnlarge()
          .complete(() => (this.submitLoading = false))
          .success(res => {
            if (res.data.code === 500) {
              this.$message.error(res.data)
            } else {
              this.$nextTick(() => {
                this.currentTask.status = STATUS_CALCULATION
                this.isOpenEditShow = false
                this.$emit('handleExecAiTaskExecEnlarge', res.data)
              })
            }
          })
          .send(obj)
      }
    },
    handleRttOpenEditContentControl (opt) {
      switch (opt.type) {
        case 'changeOpenEditShow':
          this.changeOpenEditShow(opt.isOpenEditShow)
          break
        case 'handleExecResultEditAgain':
          this.handleExecResultEditAgain(opt.exec)
          break
        case 'handleExpandImg':
          this.handleExpandImg(opt.data)
          break
      }
    },
    handleExpandImg (data) {
      this.scaleSelected = []
      this.currentType = this.typeFree
      this.imgInfo = Object.assign({}, this.imgInfo, data)

      this.freeVal = Object.assign({}, this.freeVal, {
        top: Math.floor(data.top * this.imgInfo.scale),
        right: Math.floor(data.right * this.imgInfo.scale),
        bottom: Math.floor(data.bottom * this.imgInfo.scale),
        left: Math.floor(data.left * this.imgInfo.scale),
        iTop: data.iTop,
        iLeft: data.iLeft
      })
    },
    handleExecResultEditAgain (exec) {
      const enlargeDto = exec.enlargeDto

      this.changeOpenEditShow(true)
      this.urlToBase64(enlargeDto.image).then(res => {
        this.currentTask.img = res.result
        this.imgInfo.src = res.result
        this.currentType = enlargeDto.type
        if (enlargeDto.type === this.typeScale) {
          this.scaleSelected = enlargeDto.scales
        } else if (enlargeDto.type === this.typeFree) {
          this.scaleSelected = []
          this.freeVal = Object.assign({}, this.freeVal, {
            top: enlargeDto.top,
            right: enlargeDto.right,
            bottom: enlargeDto.bottom,
            left: enlargeDto.left,
            iTop: 0,
            iLeft: 0
          })
        }
        this.changeImgInfo(res.width, res.height, enlargeDto)
      }, () => {
        this.$message.error('原图加载失败')
      })
    },
    onScaleCheckboxChange (checkedValues) {
      if (checkedValues.length > 0) {
        this.currentType = this.typeScale
      }
    },
    handleCustomMode () {
      const imgSrc = this.currentTask.img
      if (imgSrc === '') {
        this.$message.warn('请上传图片')
      } else {
        this.$emit('handleImageExpand', this.imgInfo)
      }
    },
    dragEnter (event) {
      event.preventDefault()
      this.dropzoneActive = true
    },
    dragLeave (event) {
      event.preventDefault()
      this.dropzoneActive = false
    },
    dragOver (event) {
      event.preventDefault()
    },
    dropFile (event) {
      event.preventDefault()
      this.dropzoneActive = false
      const files = event.dataTransfer.files
      this.handleFiles(files)
    },
    handleCropperImg (data) {
      this.spinningOneShow = false
      this.currentTask.img = data
      this.currentTask.mask = ''
      this.freeVal = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        iTop: 0,
        iLeft: 0
      }
    },
    handleInitImageUploadChange (e) {
      this.spinningOneShow = true
      this.getBase64(e.target.files[0])
    },
    handleFiles (files) {
      if (files.length > 0) {
        this.getBase64(files[0])
      }
    },
    getBase64 (file) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.handleCropperImg(reader.result)
        this.$refs.initImage.value = ''
        const img = new Image()
        img.src = this.currentTask.img
        img.onload = () => {
          this.imgInfo.src = img.src
          this.changeImgInfo(img.width, img.height)
        }
      })
      reader.readAsDataURL(file)
    },
    calculateScaleSize (width, height, reduct, aspectRatio) {
      const maxScreenHeight = window.innerHeight - 110 - 50
      const maxScreenWidth = window.innerWidth - 66 - 50
      const arr = []
      let sWidth = 0
      let sHeight = 0
      if (maxScreenHeight > height && maxScreenWidth > width) {
        sWidth = width
        sHeight = height
      } else {
        const rWidth = reduct[0]
        const rHeight = reduct[1]
        const maxShowHeight = Math.floor(maxScreenHeight / 3 * 2)
        if (aspectRatio <= 1) {
          sHeight = maxShowHeight
          sWidth = Math.floor(sHeight / rHeight * rWidth)
        } else {
          if (rHeight > maxShowHeight) {
            sHeight = maxShowHeight
            sWidth = Math.floor(sHeight / rHeight * rWidth)
          } else {
            const tempWidth = Math.floor(maxShowHeight / rHeight * rWidth)
            if (tempWidth < maxScreenWidth) {
              sHeight = maxShowHeight
              sWidth = tempWidth
            } else {
              sWidth = Math.floor(maxScreenWidth / 2)
              sHeight = Math.floor(sWidth / rWidth * rHeight)
            }
          }
        }
      }

      arr[0] = sWidth
      arr[1] = sHeight
      return arr
    },
    changeImgInfo (width, height, enlargeDto) {
      this.imgInfo.imgWidth = width
      this.imgInfo.imgHeight = height
      this.imgInfo.aspectRatio = width / height
      const reduct = this.reductionTo(width, height)
      this.imgInfo.reduct = reduct
      const scaleSize = this.calculateScaleSize(width, height, reduct, this.imgInfo.aspectRatio)
      this.imgInfo.sWidth = scaleSize[0]
      this.imgInfo.sHeight = scaleSize[1]
      this.imgInfo.scale = this.imgInfo.imgWidth / this.imgInfo.sWidth

      if (enlargeDto && enlargeDto.type === this.typeFree) {
        this.imgInfo.top = Math.floor(enlargeDto.top / this.imgInfo.scale)
        this.imgInfo.right = Math.floor(enlargeDto.right / this.imgInfo.scale)
        this.imgInfo.bottom = Math.floor(enlargeDto.bottom / this.imgInfo.scale)
        this.imgInfo.left = Math.floor(enlargeDto.left / this.imgInfo.scale)
        this.imgInfo.iTop = 0
        this.imgInfo.iLeft = 0
      } else {
        this.imgInfo.top = this.freeVal.top
        this.imgInfo.right = this.freeVal.right
        this.imgInfo.bottom = this.freeVal.bottom
        this.imgInfo.left = this.freeVal.left
        this.imgInfo.iTop = this.freeVal.iTop
        this.imgInfo.iLeft = this.freeVal.iLeft
      }
    },
    urlToBase64 (url) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = function () {
          const canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, 0)
          const result = canvas.toDataURL('image/png')
          resolve({
            result: result,
            width: image.width,
            height: image.height
          })
        }
        image.setAttribute('crossOrigin', 'Anonymous')
        image.src = url
        image.onerror = () => {
          reject(new Error('urlToBase64 error'))
        }
      })
    },
    dataUrlToBase64 (img) {
      return img.replace('data:image/png;base64,', '').replace('data:image/jpeg;base64,', '')
    },
    reductionTo (m, n) {
      const arr = []
      if (m !== 1 && n !== 1) {
        for (let i = n; i >= 2; i--) {
          if (m % i === 0 && n % i === 0) {
            m = m / i
            n = n / i
          }
        }
      }
      arr[0] = m
      arr[1] = n
      return arr
    }
  }
}
</script>

<style scoped lang="less">
@deep: ~'>>>';
.open-edit-content {
  position: absolute;
  max-width: 868px;
  width: calc(100vw - 400px);
  left: 0;
  top: 0;
  height: 100%;
  transition: all .3s;
  user-select: none;
  .edit-mask {
    position: absolute;
    background-color: rgba(0,0,0,.45);
    width: 100vw;
    height: calc(100vh - 64px);
    z-index: 2;
  }
  .edit-content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: #ffffff;
    z-index: 3;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .opt-content {
      position: relative;
      width: 100%;
      flex: 1;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }

      .img-area {
        position: relative;
        width: 100%;
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
        .spin-content {
          position: relative;
          transition: opacity 0.3s;
          .area-div {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 384px;
            position: relative;
            color: #7d7675;
            border-radius: 12px;
            .upload-wrap {
              position: relative;
              width: 384px;
              height: 384px;
              margin-bottom: 8px;
              img {
                cursor: pointer;
                border-radius: 12px;
              }

              .uw-img {
                border: 2px solid #7530fe;
                border-radius: 12px;
              }

              .dropzone {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border: 2px dashed #7530fe;
                border-radius: 12px;
                cursor: pointer;
                .label1 {
                  color: #7530fe;
                  margin-top: 12px;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                }
                .label2 {
                  margin-top: 4px;
                  color: #403d3c;
                  margin-bottom: 8px;
                }
                .label3 {
                  color: #b8b2b2;
                  font-size: 11px;
                  font-weight: 400;
                  line-height: 20px;
                }
                .crop-size {
                  position: relative;
                  display: flex;
                  flex-wrap: wrap;
                  padding: 5px 20px;
                  align-items: center;
                  .crop-size-item {
                    position: relative;
                    font-size: 11px;
                    border: 1px solid #909399;
                    background-color: #ffffff;
                    border-radius: 3px;
                    margin: 4px 6px;
                    padding: 2px 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    &.active {
                      background-color: #3d8acf;
                      color: #ffffff;
                    }
                  }
                }
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }

              &@{deep} .ant-upload {
                border-radius: 8px;
              }

              .mask-area {
                position: relative;
                width: 100%;
                height: 100%;
                border: 2px solid #7530fe;
                border-radius: 12px;

                .absolute {
                  position: absolute;
                }

                .opacity5 {
                  opacity: 0.5;
                }

                .mask-img {
                  width: 100%;
                  height: 100%;
                  cursor: default;
                }

                .opt {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;

                  .mask-area-btn {
                    position: absolute;
                    bottom: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                    background-color: #7530fe;
                    color: #ffffff;
                    padding: 8px 18px;
                    cursor: pointer;
                    &:hover {
                      background-color: #8559fe;
                    }
                  }
                }

                .region-btn {
                  position: absolute;
                  width: 100%;
                  bottom: 15px;
                  display: flex;
                  justify-content: center;
                }
              }
            }
          }
        }

        .scale-checkbox {
          position: relative;
          padding: 30px 15px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          .item-col {
            text-align: center;
            margin-top: 15px;
            .item-col-cb {
              font-size: 22px;
              width: 90px;
              text-align: left;
            }
          }
          .scale-custom {
            width: 200px;
            height: 40px;
            border-radius: 10px;
          }
        }
      }

      .scale-content {
        position: relative;
        display: flex;
        margin-top: 30px;
        .scale-label-item {
          position: relative;
          border: 1px solid #909399;
          background-color: rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          padding: 5px 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          &.active {
            background-color: #3d8acf;
            color: #ffffff;
          }
        }
      }

      .tabs-area {
        position: relative;
        margin-top: 24px;
        width: 100%;
      }
    }
    .bottom-bar {
      position: relative;
      width: 100%;
      height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .open-icon {
    position: absolute;
    width: 20px;
    height: 96px;
    top: calc(50% - 96px / 2);
    background-color: #eae7fe;
    border-radius: 0 20px 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    cursor: pointer;
    &.edit-show {
      right: -20px;
    }
  }
}

.file-btn {
  position: relative;
  display: inline-block;
  color: #7d7675;
  text-decoration: underline;
  cursor: pointer;
  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    display: none;
  }
}

.upload-wrap @{deep} .ant-upload-list {
  display: none !important;
}

input, textarea {
  outline: none;
}

.ant-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
