<template>
    <a-layout-header class="app-header">
      <div class="left-bar">
        <img class="logo" :src="logoUrl" v-if="logoUrl" @click="handleLogoClick"/>
        <div class="app-name" @click="handleLogoClick">{{ appName }}</div>
        <div class="nav-menu" v-show="!isLoginRoute">
          <div class="item" :class="{'active': isPathWorkspace}" @click="handleWorkspaceClick">工作台</div>
        </div>

      </div>
      <div class="point-content" v-if="isLogin">
        <div>剩余点数</div><span class="text-danger font-large font-bold">{{point}}</span><div class="btn-buy">
        <router-link :to="{path:'/member'}" target="_blank">
          <div class="label">购买</div>
        </router-link>
      </div>
      </div>
      <div class="right-bar">
        <div class="bar-item user-profile" v-show="!isLoginRoute" @click.stop.prevent="handleUserProfileClick">
          <img class="user-icon" :src="imgSrc(url)" alt="user">
          <ul class="menu" v-show="menuVisible">
            <div class="info" v-if="isLogin" @click.stop>
              <div class="icon-view">
                <img class="user-icon-l" :src="imgSrc(url)" alt="user">
              </div>
              <div>{{phoneStr}}</div>
              <div class="line-height26" v-if="vip">{{vip.title}}</div>
              <div class="line-height26" v-if="vip">{{expireDate(vip.expireDate)}}</div>
            </div>
            <li class="menu-item" @click="pointHistory">点数变化</li>
            <li class="menu-item" @click="logout">退出登录</li>
          </ul>
        </div>
      </div>

    </a-layout-header>
</template>

<script>
import { logout, getCustomerMy } from '@/http/api/user'
import { ROUTE_LOGIN, ROUTE_HOME, ROUTE_WORKSPACE, ROUTE_MOBILE, ROUTE_POINT_HISTORY } from '@/router/router-constants'
import { userStore, appStore } from '@/store'
import kit from '@/utils/kit'

export default {
  created () {
  },
  data () {
    return {
      passwordModalVisible: false,
      routeHome: ROUTE_HOME,
      menuVisible: false,
      isPathWorkspace: false,
      logoUrl: require('../assets/images/logo.png')
    }
  },
  computed: {
    ...(userStore.mapState(['phone', 'url', 'dept', 'isLogin', 'point', 'vip'])),
    ...(appStore.mapState({
      appName: 'name'
    })),
    imgSrc () {
      return function (url) {
        return kit.str.isBlank(url) ? require('../assets/images/user.png') : url
      }
    },
    isLoginRoute () {
      return this.$route.path === '/login'
    },
    phoneStr () {
      return '****' + this.phone.slice(this.phone.length - 4)
    },
    expireDate () {
      return function (val) {
        return kit.str.isBlank(val) ? '永久' : val
      }
    },
    isMobileDevice () {
      const ua = navigator.userAgent.toLowerCase()
      const t1 = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(ua)
      const t2 = ua.indexOf('windows') < 0 && ua.indexOf('iphone') < 0 && navigator.maxTouchPoints > 1
      return t1 || t2
    }
  },
  watch: {
  },
  filters: {
  },
  methods: {
    logout () {
      this.$confirm({
        title: '提示',
        content: '确定要退出系统吗？',
        onOk: () => {
          logout()
            .success(() => {
              this.$router.push({ name: ROUTE_LOGIN })
            })
            .send()
        }
      })
    },
    pointHistory () {
      if (this.$route.path !== '/point-history') {
        this.$router.push({ name: ROUTE_POINT_HISTORY })
      }
    },
    handleUserProfileClick () {
      if (this.isMobileDevice) {
        this.$router.push({ name: ROUTE_MOBILE })
      } else {
        if (this.isLogin) {
          if (!this.menuVisible) {
            kit.dom.addEvent(document.documentElement, 'click', this.hideMenu)
          }
          this.menuVisible = !this.menuVisible
        } else {
          this.$router.push({ name: ROUTE_LOGIN })
        }
      }
    },
    hideMenu () {
      this.menuVisible = false
      kit.dom.removeEvent(document.documentElement, 'click', this.hideMenu)
    },
    handleLogoClick () {
      if (!this.isLogin) {
        if (this.$route.path !== '/home') {
          this.$router.push({ name: ROUTE_HOME })
        }
      } else {
        if (this.$route.path !== '/workspace') {
          this.$router.push({ name: ROUTE_WORKSPACE })
        }
      }
    },
    handleWorkspaceClick () {
      if (!this.isLogin) {
        if (this.$route.path !== '/workspace') {
          this.$router.push({ name: ROUTE_LOGIN })
        }
      } else {
        if (this.$route.path !== '/workspace') {
          this.$router.push({ name: ROUTE_WORKSPACE })
        }
      }
    },
    changeCustomerMyInfo () {
      getCustomerMy()
        .success(res => {
          userStore.setCustomerPoint(res.data.point)
          userStore.setCustomerVip(res.data.vip)
        })
        .send()
    },
    handleRouterLogin () {
      if (this.isMobileDevice) {
        this.$router.push({ name: ROUTE_MOBILE })
      } else {
        this.$router.push({ name: ROUTE_LOGIN })
      }
    }
  },
  mounted () {
    this.isPathWorkspace = this.$route.path === '/workspace'
    if (this.isLogin) {
      this.changeCustomerMyInfo()
    }

    this.$dispatcher.listen('changeCustomerMyInfoListen', 'changeCustomerMyInfoListenId', () => {
      this.changeCustomerMyInfo()
    })

    this.$dispatcher.listen('routerLoginListen', 'routerLoginListenId', () => {
      this.handleRouterLogin()
    })
  },
  beforeDestroy () {
    this.$dispatcher.removeListen('changeCustomerMyInfoListen', 'changeCustomerMyInfoListenId')
    this.$dispatcher.removeListen('routerLoginListen', 'routerLoginListenId')
  }
}
</script>

<style lang="less" scoped>
  @height: 64px;
  .app-header {
    display: flex;
    flex-direction: row;
    padding: 0 20px !important;
    height: @height;
    line-height: @height;
    background-color: #212121;
    color: #fff;
    .left-bar {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #fff;
      .logo {
        max-width: 120px;
        max-height: 40px;
        margin-right: 10px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
      }

      .app-name {
        font-size: 16px;
        cursor: pointer;
      }
      .app-version {
        font-size: 12px;
      }
      .nav-menu {
        margin-left: 40px;
        display: flex;
        align-items: center;
        .item {
          cursor: pointer;
          &.active {
            color: #7530fe;
          }
        }
      }
    }

    .point-content {
      position: relative;
      padding: 0 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      div:first-child {
        margin-right: 3px;
      }
      .btn-buy {
        display: flex;
        justify-content: center;
        align-items: center;
        .label {
          height: 26px;
          line-height: 26px;
          margin-left: 8px;
          color: #7530fe;
          padding-left: 8px;
          border-left: 1px solid #d7d4d3;
          text-decoration: underline;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
    .right-bar {
      position: relative;
      line-height: @height;
      height: @height;
      .bar-item {
        float: right;
        position: relative;
        padding: 0 10px;
        font-size: 14px;
        color: #efefef;
      }
    }
  }

  .bar-item.user-profile {
    .username {
      margin-right: 10px;
    }

    .user-icon {
      position: relative;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      cursor: pointer;
    }

    .menu {
      position: absolute;
      margin: 0;
      padding: 0;
      top: @height;
      right: 0;
      z-index: 2200;
      width: 270px;
      min-width: 150px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: #ffffff;
      box-shadow: 0 5px 10px rgba(0,0,0,.3);
      list-style-type: none;
      overflow: hidden;

      .info {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 22px;
        border-bottom: 1px solid #edeaea;
        color: #403d3c;
        .icon-view {
          position: relative;
          height: 64px;
          margin-top: 20px;
          .user-icon-l {
            width: 64px;
            height: 64px;
            border-radius: 50%;
          }
        }
      }

      .split-line {
        height: 1px;
        padding: 0;
        margin: 0 15px;
        background-color: #5c5c5c;
      }
      .menu-item {
        height: 36px;
        line-height: 36px;
        margin: 0;
        padding: 0 15px;
        font-size: 12px;
        cursor: pointer;
        user-select: none;
        color: #403d3c;
        &:hover {
          background-color: rgba(0,0,0,0.2);
        }
        &:active {
          background-color: #464646;
        }
      }
    }
  }

  .bar-item {
    .text {
      font-size: 13px;
      margin-left: 3px;
    }
    i {
      position: relative;
    }
    .badge {
      display: inline-block;
      position: absolute;
      top: -12px;
      left: -12px;
      padding: 3px 4px;
      min-width: 12px;
      text-align: center;
      border-radius: 10px;
      font-size: 12px !important;
      color: #fff;
      background-color: #df5040;
    }
  }

  //.buy-point-content {
  //  position: fixed;
  //  top: 0;
  //  left: 0;
  //  width: 100vw;
  //  height: 100vh;
  //  background-color: rgba(0,0,0,.45);
  //  z-index: 2800;
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
  //  -webkit-user-select: none;
  //  -moz-user-select: none;
  //  -ms-user-select: none;
  //  user-select: none;
  //  .by-content {
  //    position: relative;
  //    min-width: 500px;
  //    max-width: 800px;
  //    padding: 25px;
  //    background-color: #ffffff;
  //    border-radius: 10px;
  //    display: flex;
  //    flex-direction: column;
  //    line-height: 22px;
  //    .by-header {
  //      position: relative;
  //      display: flex;
  //      width: 100%;
  //      color: #000000;
  //      align-items: center;
  //      font-size: 18px;
  //      margin-bottom: 15px;
  //      .label {
  //        flex: 1;
  //        text-align: center;
  //      }
  //      .close {
  //        position: absolute;
  //        right: 0;
  //        padding: 2px;
  //        cursor: pointer;
  //      }
  //    }
  //    .by-body {
  //      display: flex;
  //      overflow-x: auto;
  //      overflow-y: hidden;
  //      padding: 10px 0;
  //      .point-product {
  //        position: relative;
  //        width: 150px;
  //        background-color: #f5f3f3;
  //        padding: 16px;
  //        border-radius: 12px;
  //        flex-shrink: 0;
  //        color: #403d3c;
  //        cursor: pointer;
  //        border: 1px solid transparent;
  //        &.active {
  //          border-color: #7530fe;
  //          background-color: #eae7fe;
  //        }
  //        &:not(:last-child) {
  //          margin-right: 10px;
  //        }
  //        .point {
  //          font-size: 16px;
  //          line-height: 24px;
  //          font-weight: 400;
  //        }
  //        .price {
  //          margin-top: 12px;
  //          font-size: 32px;
  //          line-height: 40px;
  //          font-weight: 500;
  //        }
  //        .label {
  //          font-size: 12px;
  //          line-height: 20px;
  //          font-weight: 400;
  //        }
  //      }
  //    }
  //    .by-footer {
  //      display: flex;
  //      margin-top: 15px;
  //      justify-content: center;
  //      .buy-btn {
  //        flex: 1;
  //        border-radius: 10px;
  //        height: 48px;
  //      }
  //    }
  //  }
  //
  //  /*修改滚动条样式*/
  //  ::-webkit-scrollbar{
  //    width:10px;
  //    height:10px;
  //  }
  //  ::-webkit-scrollbar-track{
  //    opacity:0;/*完全透明*/
  //    border-radius:2px;
  //  }
  //  ::-webkit-scrollbar-thumb{
  //    background: #bfbfbf;
  //    border-radius:10px;
  //  }
  //  ::-webkit-scrollbar-thumb:hover{
  //    background: #bfbfbf;
  //  }
  //  ::-webkit-scrollbar-corner{
  //    background: #ffffff;
  //    border-radius:50px;
  //  }
  //}
  //
  //.buy-point-qrcode-content {
  //  position: fixed;
  //  top: 0;
  //  left: 0;
  //  width: 100vw;
  //  height: 100vh;
  //  background-color: rgba(0,0,0,.45);
  //  z-index: 2820;
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
  //  .byq-content {
  //    position: relative;
  //    min-width: 350px;
  //    background-color: #ffffff;
  //    background-clip: padding-box;
  //    display: flex;
  //    flex-direction: column;
  //    align-items: center;
  //    color: #403d3c;
  //    border: 0;
  //    border-radius: 8px;
  //    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  //    pointer-events: auto;
  //    padding: 20px 24px;
  //    .byq-header {
  //      position: relative;
  //      display: flex;
  //      width: 100%;
  //      color: #000000;
  //      align-items: center;
  //      font-size: 18px;
  //      margin-bottom: 24px;
  //      .label {
  //        flex: 1;
  //        text-align: center;
  //        font-weight: 500;
  //        line-height: 26px;
  //      }
  //      .close {
  //        position: absolute;
  //        right: 0;
  //        padding: 2px;
  //        cursor: pointer;
  //      }
  //    }
  //    .byq-body {
  //      width: 360px;
  //      display: flex;
  //      align-items: center;
  //      flex-direction: column;
  //      background-color: #faf9f9;
  //      padding: 24px 0;
  //      border-radius: 8px;
  //      .qrcode {
  //        box-sizing: border-box;
  //        margin: 0;
  //        padding: 12px;
  //        color: rgba(0, 0, 0, 0.88);
  //        font-size: 14px;
  //        line-height: 1.5714285714285714;
  //        list-style: none;
  //        display: flex;
  //        justify-content: center;
  //        align-items: center;
  //        background-color: #fff;
  //        border-radius: 8px;
  //        border: 1px solid rgba(5, 5, 5, 0.06);
  //        position: relative;
  //        width: 240px;
  //        height: 240px;
  //        overflow: hidden;
  //
  //        .unactive {
  //          position: absolute;
  //          top: 0;
  //          right: 0;
  //          bottom: 0;
  //          left: 0;
  //          background-color: rgba(0,0,0,.45);
  //        }
  //
  //        .status {
  //          position: absolute;
  //          line-height: 22px;
  //          background-color: #ffffff;
  //          border-radius: 8px;
  //          box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  //          padding: 20px 24px;
  //          display: flex;
  //          flex-direction: column;
  //          align-items: center;
  //          cursor: pointer;
  //        }
  //      }
  //      .detail {
  //        width: 100%;
  //        display: flex;
  //        align-items: center;
  //        flex-direction: column;
  //        margin-top: 24px;
  //        line-height: 22px;
  //        .label1 {
  //          display: flex;
  //          justify-content: center;
  //          align-items: baseline;
  //        }
  //        .label2 {
  //          margin-top: 12px;
  //          display: flex;
  //          align-items: center;
  //          img {
  //            margin-right: 10px;
  //          }
  //        }
  //        .label3 {
  //          color: #b8b2b2;
  //          margin-top: 33px;
  //        }
  //      }
  //    }
  //  }
  //}
  //
  //.order-status-content {
  //  position: fixed;
  //  top: 0;
  //  left: 0;
  //  width: 100vw;
  //  height: 100vh;
  //  background-color: rgba(0,0,0,.45);
  //  z-index: 2830;
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
  //  .os-content {
  //    position: relative;
  //    min-width: 350px;
  //    background-color: #ffffff;
  //    background-clip: padding-box;
  //    display: flex;
  //    flex-direction: column;
  //    align-items: center;
  //    color: #403d3c;
  //    border: 0;
  //    border-radius: 8px;
  //    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  //    pointer-events: auto;
  //    padding: 20px 24px;
  //    line-height: 22px;
  //  }
  //}

  .ag12 {
    font-size: 12px;
    font-weight: 400;
  }
  .ag14 {
    font-size: 14px;
    font-weight: 400;
  }
  .line-height26 {
    line-height: 26px;
  }

  .ag32 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
  }
</style>
