<!--
  数据模型编辑器，基于iView的Drawer优化。
  建议为数据模型单独创建一个vue文件，将数据模型的编辑逻辑都放在那里，例如：
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    @save="onSave"
    @close="$refs['form'].resetFields()"
  >
    <form></form>
  </drawer>

  属性：
  ----------------------
  visible {boolean} 控制编辑器显示隐藏
  title {string} 编辑器的标题，默认"编辑"
  width {Number} 编辑器宽度，默认500
  disableFooterButton {boolean} 禁用底部的"保存"和"取消"按钮，默认false，如果你想自己放置按钮，设置为true
  saveButtonText {string} 默认底部的保存按钮文本
  closeButtonText {string} 关闭编辑器按钮的文本
  saveButtonLoading {boolean} 如果你使用默认的底部按钮时，可以使用这个属性来控制按钮的加载状态
  closable {boolean} 编辑器是否可以被关闭，false后，头部的关闭图标和底部的关闭按钮都不可用
  loading {Boolean} 默认false，是否显示一个加载动画（覆盖面板的body和footer）

  slot
  ------------------------
  slot="footer"：底部位置，如果你需要自己写底部的组件，请将disableFooterButton设置为true

  事件
  ------------------------
  close：编辑器被关闭
  cancel：点击了底部默认的"取消"按钮，该按钮默认会关闭编辑器
  save：点击了底部默认的"保存"按钮
-->
<template>
  <a-drawer
      :title="title"
      :width="width"
      :closable="closable"
      :visible="isVisible"
      :placement="placement"
      @close="handleCancel"
      class="drawer-container"
      :after-visible-change="afterVisibleChange"
  >
    <div class="body" ref="body">
      <drawer-loading :loading="loading"></drawer-loading>
      <div ref="bodyContent" class="body-content" :class="bodyClass" :style="bodyStyle">
        <slot></slot>
      </div>
    </div>
    <div class="footer" v-if="!(disableFooterButton && !$slots.footer)">
      <slot name="footer"></slot>
      <a-button
          v-if="!disableFooterButton"
          type="primary"
          :loading="saveButtonLoading"
          @click="$emit('save')"
      >
        {{saveButtonText}}
      </a-button>
      <a-button
          v-if="!disableFooterButton"
          @click="handleCancel"
          :disabled="!closable"
      >
        {{closeButtonText}}
      </a-button>
      <slot name="extra-button"></slot>
    </div>
  </a-drawer>
</template>

<script>
import DrawerLoading from './loading'
export default {
  name: 'drawer',
  components: {
    DrawerLoading
  },
  props: {
    placement: { type: String, default: 'right' },
    visible: { type: Boolean, default: true },
    width: { type: [Number, String], default: 500 },
    title: { type: String, default: '编辑' },
    disableFooterButton: { type: Boolean, default: false },
    saveButtonText: { type: String, default: '保存' },
    closeButtonText: { type: String, default: '关闭' },
    saveButtonLoading: { type: Boolean, default: false },
    closable: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    afterVisibleChange: { type: Function },
    bodyStyle: { type: Object },
    bodyClass: { type: String }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (v) {
        this.$emit('update:visible', v)
      }
    }
  },
  watch: {
    visible (v) {
      if (this.$refs.bodyContent) {
        this.$refs.bodyContent.scrollTop = 0
      }
      this.isVisible = v
      if (!v) {
        this.$emit('close')
      }
    }
  },
  data () {
    return {
    }
  },
  methods: {
    close () {
      if (this.closable) {
        this.isVisible = false
        return true
      }
    },
    handleCancel () {
      this.close() && this.$emit('cancel')
    }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
  @deep: ~'>>>';
  .drawer-container @{deep} .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .ant-drawer-header {
      background-color: #f8f8f8;
    }
    .ant-drawer-body {
      flex: 1;
      padding: 0 !important;
      position: relative;
    }
  }
  @footerHeight: 50px;
  .body {
    top: 0;
    left: 0;
    right: 0;
    bottom: @footerHeight;
    position: absolute;
    flex: 1;
    .body-content {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 15px 25px;
      overflow: auto;
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: @footerHeight;
    line-height: @footerHeight;
    padding: 0 10px;
    border-top: solid 1px #e8e8e8;
    text-align: right;
    background-color: #f8f8f9;
  }
</style>
