<template>
  <a-layout id="mobile">
    <div class="mobile-header">
      <div class="left-bar">
        <img class="logo" :src="logoUrl" v-if="logoUrl" @click="handleLogoClick"/>
        <div class="app-name" @click="handleLogoClick">{{ appName }}</div>
      </div>
    </div>
    <div class="mobile-body">
      <div class="img-box">
        <img class="item" src="../assets/images/illustration1.png" alt="illustration">
        <img class="item" src="../assets/images/illustration2.png" alt="illustration">
        <img class="item" src="../assets/images/illustration3.png" alt="illustration">
        <img class="item" src="../assets/images/illustration4.png" alt="illustration">
        <img class="item" src="../assets/images/illustration5.png" alt="illustration">
        <img class="item" src="../assets/images/illustration6.png" alt="illustration">
        <img class="item" src="../assets/images/illustration7.png" alt="illustration">
        <img class="item" src="../assets/images/illustration8.png" alt="illustration">
        <img class="item" src="../assets/images/illustration9.png" alt="illustration">
      </div>
      <p class="label">要开启体验，<br>请用电脑访问我们。 </p>
      <p class="label"></p>
    </div>
  </a-layout>
</template>

<script>
import { appStore } from '@/store'
import { ROUTE_HOME } from '@/router/router-constants'
export default {
  data () {
    return {
      logoUrl: require('../assets/images/logo.png')
    }
  },
  computed: {
    ...(appStore.mapState({
      appName: 'name'
    }))
  },
  methods: {
    handleLogoClick () {
      this.$router.push({ name: ROUTE_HOME })
    }
  }
}
</script>

<style lang="less" scoped>
#mobile {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  background: #ffffff;
}

@height: 64px;
.mobile-header {
  top: 0;
  display: flex;
  flex-direction: row;
  padding: 0 20px !important;
  height: @height;
  line-height: @height;
  background-color: #212121;
  color: #fff;
  z-index: 100;
  .left-bar {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
    .logo {
      max-width: 120px;
      max-height: 40px;
      margin-right: 10px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
    }

    .app-name {
      font-size: 16px;
      cursor: pointer;
    }
  }
}
.mobile-body {
  padding: 0;
  position: relative !important;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .img-box {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: calc(100% / 3);
      height: auto;
    }
  }
  .label {
    padding-left: 16px;
    padding-top: 16px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
  }
}
::-webkit-scrollbar {
  display: none;
}
</style>
