<template>
  <a-layout id="home">
    <app-header class="header"></app-header>
    <a-layout>
      <a-layout id="home-body-container" class="home-content">
        <div class="banner-content">
          <img src="../assets/images/banner.png" alt="banner">
          <div class="float-btn" @click="handleToUse">立即使用</div>
        </div>

        <div class="banner2">
          <div class="item">
            <img src="../assets/images/img-efficiency.png" alt="img-efficiency">
            <div>极高效率</div>
          </div>
          <div class="item">
            <img src="../assets/images/img-cost.png" alt="img-cost">
            <div>极低成本</div>
          </div>
          <div class="item">
            <img src="../assets/images/img-quality.png" alt="img-quality">
            <div>极高质量</div>
          </div>
        </div>

        <div class="banner3">
          <div class="item">
            <img src="../assets/images/banner3-1.png" alt="banner3-1">
            <div class="float-btn" @click="handleToUse">立即体验</div>
          </div>
          <div class="item">
            <img src="../assets/images/banner3-2.png" alt="banner3-2">
            <div class="float-btn" @click="handleToUse">立即体验</div>
          </div>
        </div>

        <div class="banner4">
          <div class="title">适配各种场景<div class="line"></div></div>
          <div class="sub-title">不再受限于拍摄模特和拍摄地点</div>

          <div class="workspace-content">
            <template v-for="wsa in workspaceArr">
              <div class="wsa-item" :key="wsa.type" :class="{'shadow': wsa.type === 'rtt'}" :style="{ background: wsa.background }">
                <div class="header">
                  <div class="icon">
                    <img :src="wsa.icon" :alt="wsa.name">
                    <div class="name">{{wsa.name}}</div>
                  </div>
                  <div class="btn" @click="handleToUse">开始使用</div>
                </div>
                <div class="label1">{{wsa.label1}}</div>
                <div class="label2">{{wsa.label2}}</div>
                <div class="illustration">
                  <template v-for="img in wsa.imgs">
                    <div class="item" :key="img">
                      <img :src="img" alt="illustration">
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>

        <div class="banner5" :style="{'background-image': `url(${banner5})`}">
          <div class="title">
            <div class="label">客户案例</div>
            <img src="../assets/images/banner5-i.png" alt="banner5-i">
          </div>
          <div class="scroll-container">
            <div class="content">
              <template v-for="(ioa, i) in 9">
                <img :key="keyStr('oa', i)" :src="illustrationOneArr[i % 4]" alt="illustration">
              </template>
            </div>
          </div>
          <div class="scroll-container">
            <div class="content2">
              <template v-for="(ita, i) in 10">
                <img :key="keyStr('ta', i)" :src="illustrationTwoArr[i % 5]" alt="illustration">
              </template>
            </div>
          </div>
        </div>

        <div class="banner4">
          <div class="title">开始体验<div class="line"></div></div>
          <div class="sub-content">
            <div class="btn-use" @click="handleToUse">立即使用</div>
          </div>
        </div>
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<script>
import AppHeader from '@/layout/app-header'

export default {
  components: { AppHeader },
  data () {
    return {
      workspaceArr: [],
      illustrationOneArr: [],
      illustrationTwoArr: [],
      banner5: require('../assets/images/banner5.jpg')
    }
  },
  computed: {
    keyStr () {
      return function (t, i) {
        return `${t}-${i}`
      }
    }
  },
  mounted () {
    this.workspaceArr = [
      {
        name: '人台图',
        type: 'rtt',
        icon: require('../assets/images/icon-rtt.png'),
        background: '#e1edf4',
        label1: '不需要真人模特',
        label2: '只需要将服装穿到人台上，即可用符合品牌调性的各类型真人模特展示服装。',
        imgs: [
          require('../assets/images/rtt-1.png'),
          require('../assets/images/rtt-2.png')
        ]
      },
      {
        name: '真人图',
        type: 'zrt',
        icon: require('../assets/images/icon-zrt.png'),
        background: '#f2e9fc',
        label1: '不再受限于拍摄地点和模特类型',
        label2: '只需要任意真人模特图，即可切换适合世界各地市场的拍摄场景和模特类型。',
        imgs: [
          require('../assets/images/zrt-1.png'),
          require('../assets/images/zrt-2.png')
        ]
      },
      {
        name: '商品图',
        type: 'spt',
        icon: require('../assets/images/icon-spt.png'),
        background: '#f1e6df',
        label1: '不再需要特定商业拍摄场景 ',
        label2: '只需要简单的商品图，就可以生成商业摄影效果的产品主图。',
        imgs: [
          require('../assets/images/spt-1.png'),
          require('../assets/images/spt-2.png'),
          require('../assets/images/spt-3.png')
        ]
      },
      {
        name: '玩具图',
        type: 'wjt',
        icon: require('../assets/images/icon-wjt.png'),
        background: '#fbf7dd',
        label1: '不再需要人工制作复杂的潮玩场景 ',
        label2: '将玩具放置在简单背景下，即可生成潮玩场景。',
        imgs: [
          require('../assets/images/wjt-1.png'),
          require('../assets/images/wjt-2.png'),
          require('../assets/images/wjt-3.png')
        ]
      },
      {
        name: '配饰图',
        type: 'pst',
        icon: require('../assets/images/icon-pst.png'),
        background: '#e8eaed',
        label1: '为配饰搭配符合品牌调性的不同风格的模特',
        label2: '只需要配饰展示图 (人台、模特皆可)，即可生成符合品牌调性的模特展示图。',
        imgs: [
          require('../assets/images/pst-1.png'),
          require('../assets/images/pst-2.png'),
          require('../assets/images/pst-3.png')
        ]
      },
      {
        name: '童装图',
        type: 'tzt',
        icon: require('../assets/images/icon-tzt.png'),
        background: '#e8f9f3',
        label1: '不再需要小孩子来充当模特',
        label2: '小小的孩子应读快乐玩耍，生意的事情交给我们帮忙吧。',
        imgs: [
          require('../assets/images/tzt-1.png'),
          require('../assets/images/tzt-2.png'),
          require('../assets/images/tzt-3.png')
        ]
      }
    ]

    this.illustrationOneArr = [
      require('../assets/images/illustration1.png'),
      require('../assets/images/illustration2.png'),
      require('../assets/images/illustration3.png'),
      require('../assets/images/illustration4.png')
    ]
    this.illustrationTwoArr = [
      require('../assets/images/illustration5.png'),
      require('../assets/images/illustration6.png'),
      require('../assets/images/illustration7.png'),
      require('../assets/images/illustration8.png'),
      require('../assets/images/illustration9.png')
    ]
  },
  methods: {
    handleToUse () {
      this.$dispatcher.dispatch('routerLoginListen')
    }
  }
}
</script>

<style lang="less" scoped>
#home {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
}
.home-content {
  padding: 0;
  position: relative !important;
  background: #ffffff;
  overflow: auto;
  .banner-content {
    position: relative;
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
    .float-btn {
      position: absolute;
      top: calc(100% * 0.6867);
      left: calc(100% * 0.0828125);
      padding: 12px 65px;
      background-color: #a20bd4;
      border-radius: 50px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 22px;
      &:hover {
        background-color: #b42ae1;
      }
    }
  }

  .banner2 {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 65px 0;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #070707;
      font-weight: 400;
      font-size: 22px;
      line-height: 35px;
    }
  }

  .banner3 {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 55px 0;
    .item {
      position: relative;
      width: calc(100% * 0.8349);
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
      &:not(:last-child) {
        margin-bottom: 65px;
      }

      .float-btn {
        position: absolute;
        top: calc(100% * 0.08465);
        right: calc(100% * 0.092327);
        padding: 12px 65px;
        background-color: #a20bd4;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 22px;
        &:hover {
          background-color: #b42ae1;
        }
      }
    }
  }

  .banner4 {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0;
    .title {
      position: relative;
      font-size: 60px;
      font-weight: 500;
      color: #1A1A1A;
      .line {
        position: absolute;
        bottom: 4px;
        right: 0;
        width: 123px;
        height: 8px;
        background: linear-gradient(10deg, #12D6DF 20%, #F70FFF 80%);
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
      }
    }
    .sub-title {
      font-size: 24px;
      font-weight: 400;
      color: #4D4D4D;
      line-height: 35px;
      margin-top: 10px;
    }

    .workspace-content {
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 60px 15px;
      justify-content: space-around;
      .wsa-item {
        width: calc(100% * 0.49);
        min-width: 750px;
        display: flex;
        flex-direction: column;
        border-radius: 22px;
        padding: 20px;
        margin-bottom: 20px;
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .icon {
            display: flex;
            align-items: center;
            .name {
              font-size: 30px;
              font-weight: 400;
              color: #070707;
              line-height: 30px;
              margin-left: 20px;
            }
          }
          .btn {
            position: relative;
            padding: 8px 30px;
            background-color: #a20bd4;
            border-radius: 50px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            font-size: 20px;
            &:hover {
              background-color: #b42ae1;
            }
          }
        }
        .label1 {
          font-size: 26px;
          font-weight: 400;
          color: #070707;
          line-height: 30px;
          margin-top: 15px;
        }
        .label2 {
          font-size: 18px;
          font-weight: 400;
          color: #4D4D4D;
          line-height: 22px;
          margin-top: 12px;
        }
        .illustration {
          position: relative;
          width: 100%;
          display: flex;
          margin-top: 20px;
          .item {
            flex: 1;
            img {
              width: 100%;
              height: auto;
            }
            &:not(:last-child) {
              margin-right: 8px;
            }
          }
        }
      }

      .shadow {
        box-shadow: 0 0 12px 0 #261F6F;
      }
    }

    .sub-content {
      position: relative;
      width: 100%;
      padding: 80px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn-use {
        padding: 10px 80px;
        background: linear-gradient(10deg, #12D6DF 20%, #F70FFF 80%);
        border-radius: 47px;
        opacity: 1;
        border: 3px solid #F7F7F7;
        font-size: 40px;
        font-weight: 500;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }

  .banner5 {
    position: relative;
    width: 100%;
    background-repeat: no-repeat;
    background-size:100% 100%;
    .title {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 90px 0 40px 0;
      .label {
        font-size: 60px;
        font-weight: 500;
        color: #FFFFFF;
        margin-right: 20px;
      }
    }
    .scroll-container {
      overflow: hidden;
      &:last-child {
        margin-bottom: 80px;
      }

      .content {
        display: flex;
        flex-wrap: nowrap;
        animation: scroll 25s linear infinite;
        img {
          margin: 5px;
        }
      }

      .content2 {
        display: flex;
        flex-wrap: nowrap;
        animation: scroll2 20s linear infinite;
        img {
          margin: 5px;
        }
      }

      @keyframes scroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-2192px);
        }
      }

      @keyframes scroll2 {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-2740px);
        }
      }
    }
  }
}

::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 700px) {
  .home-content {
    .banner-content {
      .float-btn {
        padding: 6px 32px;
        font-size: 14px;
      }
    }

    .banner2 {
      padding: 40px 0;
      .item {
        font-size: 16px;
        line-height: 22px;
        img {
          width: 90px;
        }
      }
    }

    .banner3 {
      padding: 40px 0;
      .item {
        &:not(:last-child) {
          margin-bottom: 40px;
        }

        .float-btn {
          top: calc(100% * 0.05);
          right: calc(100% * 0.09);
          padding: 6px 32px;
          font-size: 12px;
        }
      }
    }

    .banner4 {
      padding: 40px 0;
      .title {
        font-size: 30px;
        .line {
          bottom: 0;
          width: 63px;
        }
      }
      .sub-title {
        font-size: 18px;
        line-height: 25px;
      }

      .workspace-content {
        padding: 40px 8px;
        .wsa-item {
          width: 100%;
          min-width: 100%;
          padding: 20px;
          margin-bottom: 20px;
          .header {
            .icon {
              .name {
                font-size: 20px;
                line-height: 30px;
                margin-left: 10px;
              }
            }
            .btn {
              padding: 8px 30px;
              font-size: 16px;
            }
          }
          .label1 {
            font-size: 19px;
            line-height: 25px;
            margin-top: 10px;
          }
          .label2 {
            font-size: 15px;
            line-height: 20px;
            margin-top: 10px;
          }
          .illustration {
            margin-top: 20px;
            .item {
              flex: 1;
              img {
                width: 100%;
                height: auto;
              }
              &:not(:last-child) {
                margin-right: 8px;
              }
            }
          }
        }
      }

      .sub-content {
        padding: 40px 0;
        .btn-use {
          padding: 10px 40px;
          font-size: 20px;
        }
      }
    }

    .banner5 {
      .title {
        padding: 40px 0 20px 0;
        .label {
          font-size: 30px;
          margin-right: 15px;
        }
      }
      .scroll-container {
        overflow: hidden;
        &:last-child {
          margin-bottom: 60px;
        }
      }
    }
  }
}

@media only screen and (min-width: 701px) and (max-width: 850px) {
  .home-content {
    .banner-content {
      .float-btn {
        padding: 8px 42px;
        font-size: 18px;
      }
    }

    .banner2 {
      padding: 40px 0;
      .item {
        font-size: 16px;
        line-height: 22px;
        img {
          width: 90px;
        }
      }
    }

    .banner3 {
      padding: 40px 0;
      .item {
        &:not(:last-child) {
          margin-bottom: 40px;
        }

        .float-btn {
          top: calc(100% * 0.05);
          right: calc(100% * 0.09);
          padding: 8px 50px;
          font-size: 16px;
        }
      }
    }

    .banner4 {
      padding: 40px 0;
      .title {
        font-size: 42px;
        .line {
          bottom: 0;
          width: 83px;
        }
      }
      .sub-title {
        font-size: 20px;
        line-height: 25px;
      }

      .workspace-content {
        padding: 40px 8px;
        .wsa-item {
          width: 100%;
          min-width: 100%;
          padding: 20px;
          margin-bottom: 20px;
          .header {
            .icon {
              .name {
                font-size: 20px;
                line-height: 30px;
                margin-left: 10px;
              }
            }
            .btn {
              padding: 8px 50px;
              font-size: 20px;
            }
          }
          .label1 {
            font-size: 19px;
            line-height: 25px;
            margin-top: 10px;
          }
          .label2 {
            font-size: 15px;
            line-height: 20px;
            margin-top: 10px;
          }
          .illustration {
            margin-top: 20px;
            .item {
              flex: 1;
              img {
                width: 100%;
                height: auto;
              }
              &:not(:last-child) {
                margin-right: 8px;
              }
            }
          }
        }
      }

      .sub-content {
        padding: 40px 0;
        .btn-use {
          padding: 10px 40px;
          font-size: 20px;
        }
      }
    }

    .banner5 {
      .title {
        padding: 40px 0 20px 0;
        .label {
          font-size: 42px;
          margin-right: 15px;
        }
      }
      .scroll-container {
        overflow: hidden;
        &:last-child {
          margin-bottom: 60px;
        }
      }
    }
  }
}

@media only screen and (min-width: 851px) and (max-width: 1100px) {
  .home-content {
    .banner-content {
      .float-btn {
        padding: 8px 50px;
        font-size: 20px;
      }
    }
  }
}
</style>
