<template>
  <div class="painting-brush-show-model" v-if="isImagePaintingBrushShow">
    <div class="model-mask"></div>
    <div ref="modelHeader" class="model-header">
      <a-button @click="handleModelClose">取消</a-button>
      <div class="header-center">
        <div class="item-btn" :class="{'btn-disabled': isUndoDisabled}" @click="handleCanvasUndoClick">撤销</div>
        <div class="item-btn" :class="{'btn-disabled': isRedoDisabled}" @click="handleCanvasRedoClick">重做</div>
        <div class="item-btn" :class="{'active': currentOptType === 'move'}" @click="handleOptClick('move')">
          <img width="26" height="26" :src="currentOptType === 'move' ? require('../../../../assets/images/svg/move-active.svg') : require('../../../../assets/images/svg/move.svg')" class="icon" alt="move">
          移动</div>
        <div class="item-btn" :class="{'active': currentOptType === 'paint'}" @click="handleOptClick('paint')">
          <img width="26" height="26" :src="currentOptType === 'paint' ? require('../../../../assets/images/svg/paint-active.svg') : require('../../../../assets/images/svg/paint.svg')" class="icon" alt="paint">
          画笔</div>
        <div class="item-btn" :class="{'active': currentOptType === 'eraser'}" @click="handleOptClick('eraser')">
          <img width="26" height="26" :src="currentOptType === 'eraser' ? require('../../../../assets/images/svg/eraser-active.svg') : require('../../../../assets/images/svg/eraser.svg')" class="icon" alt="eraser">
          橡皮</div>

        <div class="scale-opt">
          <div class="opt-btn" @click="handleScaleReduceClick"><a-icon type="minus" class="icon"/>缩小</div>
          <div class="scale-label">{{scaleStr}}</div>
          <div class="opt-btn" @click="handleScaleAmplifyClick"><a-icon type="plus" class="icon"/>放大</div>
        </div>
      </div>
      <a-button @click="handleModelComplete">完成</a-button>
    </div>
    <div ref="canvasModelContent" class="model-content">
      <div ref="canvasLabel" class="describe-label">请手动涂抹希望保持不变的区域</div>
      <div class="canvas-content" ref="canvasContent" :style="{ height: `${imgHeight}px` }">
        <div class="canvas-wrap" ref="canvasWrap" :style="posStyle" @mousedown="wrapMove" @mousemove="pointMove">
          <img :src="imageSrc" alt="canvas" class="canvas-image">
          <canvas id="canvasMask" class="canvas-style"></canvas>
          <div class="style-point" v-show="isBrushThicknessShow" :style="brushThicknessStyle"></div>
        </div>
      </div>

      <div class="brush-width-content" v-if="isCanvasCanDraw">
        <a-slider class="bw-slider" vertical :min="1" :max="100" :value="brushWidth" tooltipPlacement="left" @change="handleBrushWidthChange"/>
      </div>
    </div>
  </div>
</template>

<script>
import pako from 'pako'

export default {
  data () {
    return {
      isImagePaintingBrushShow: false,
      imageSrc: null,
      maskList: [],
      winWidth: 0,
      imgScale: 1,
      posStyle: {
        transform: 'scale(1)'
      },
      scale: 1,
      pointStyle: {
        left: 0,
        top: 0
      },
      mouseTop: 10,
      mouseLeft: 20,
      currentOptType: 'default',
      canvas: null,
      ctx: null,
      undoHistory: [],
      redoHistory: [],
      undoImageData: null,
      MOUSE_LEFT_BUTTON: 0,
      isDrawing: false,
      isBrushThicknessShow: false,
      cx: 0,
      cy: 0,
      brushWidth: 18,
      headerHeight: 0,
      labelHeight: 0,
      imgWidth: 0,
      imgHeight: 0
    }
  },
  computed: {
    scaleStr () {
      return `${this.scale * 100}%`
    },
    brushThicknessStyle () {
      return {
        width: `${this.brushWidth}px`,
        height: `${this.brushWidth}px`,
        top: `${this.mouseTop}px`,
        left: `${this.mouseLeft}px`,
        'border-radius': '50%',
        position: 'fixed'
      }
    },

    isCurrentOptPaint () {
      return this.currentOptType === 'paint'
    },
    isCurrentOptEraser () {
      return this.currentOptType === 'eraser'
    },
    isCanvasCanDraw () {
      return this.currentOptType === 'paint' || this.currentOptType === 'eraser'
    },
    isUndoDisabled () {
      return this.undoHistory.length === 0
    },
    isRedoDisabled () {
      return this.redoHistory.length === 0
    }
  },
  methods: {
    open (img, maskList, winWidth, imgWidth, imgHeight) {
      this.imageSrc = img
      this.maskList = JSON.parse(JSON.stringify(maskList))
      this.winWidth = winWidth
      this.imgWidth = imgWidth
      this.imgHeight = imgHeight
      this.posStyle = Object.assign({}, this.posStyle, {
        width: ` ${imgWidth}px`,
        height: `${imgHeight}px`
      })
      this.isImagePaintingBrushShow = true
      this.$nextTick(() => {
        this.labelHeight = this.$refs.canvasLabel.clientHeight
        this.headerHeight = this.$refs.modelHeader.clientHeight
        this.canvas = document.querySelector('#canvasMask')
        this.canvas.width = this.imgWidth * this.scale
        this.canvas.height = this.imgHeight * this.scale
        this.ctx = this.canvas.getContext('2d')
        for (let i = 0; i < this.maskList.length; i++) {
          const mask = this.maskList[i]
          const pic = document.getElementById(`temp-${i}`)
          const width = mask.scaleW ? mask.scaleW : mask.width
          const height = mask.scaleH ? mask.scaleH : mask.height
          this.ctx.drawImage(pic, mask.left, mask.top, width, height)
        }

        this.canvasMousedown()
        this.canvasMousemove()
        this.canvasMouseup()
      })
    },
    handleModelClose () {
      this.isImagePaintingBrushShow = false
      this.imageSrc = null
      this.maskList = []
      this.undoHistory = []
      this.redoHistory = []
      this.posStyle = {
        transform: 'scale(1)'
      }
      this.scale = 1
      this.currentOptType = 'default'
      this.isBrushThicknessShow = false

      this.canvas.onmousedown = null
      this.canvas.onmousemove = null
      this.canvas.onmouseup = null
    },
    handleModelComplete () {
      const result = this.canvas.toDataURL('image/png')
      this.$emit('regionResult', result)
      this.handleModelClose()
    },
    handleOptClick (type) {
      if (this.currentOptType === type) {
        this.currentOptType = 'default'
        this.isBrushThicknessShow = false
      } else {
        this.currentOptType = type
        this.isBrushThicknessShow = this.isCanvasCanDraw
      }
    },
    handleBrushWidthChange (value) {
      this.brushWidth = value
    },
    handleScaleReduceClick () {
      let scale = parseFloat(this.scale)
      if (scale > 0.2) {
        if (scale <= 1) {
          scale = (scale - 0.1).toFixed(1)
        } else if (scale <= 2) {
          scale -= 0.25
        } else if (scale <= 5) {
          scale -= 1
        }
      }
      this.scale = scale
      Object.assign(this.posStyle, {
        transform: `scale(${this.scale})`
      })
    },
    handleScaleAmplifyClick () {
      let scale = parseFloat(this.scale)
      if (scale < 5) {
        if (scale < 1) {
          scale = (scale + 0.1).toFixed(1)
        } else if (scale < 2) {
          scale += 0.25
        } else if (scale < 5) {
          scale += 1
        }
      }
      this.scale = scale
      Object.assign(this.posStyle, {
        transform: `scale(${this.scale})`
      })
    },
    wrapMove (e) {
      if (this.currentOptType === 'move') {
        const disX = e.clientX - parseFloat(this.posStyle.left ? this.posStyle.left : this.winWidth / 2 - this.imgWidth / 2)
        const disY = e.clientY - parseFloat(this.posStyle.top ? this.posStyle.top : 0)
        document.onmousemove = (e) => {
          e.preventDefault()
          const left = e.clientX - disX
          const top = e.clientY - disY
          this.$nextTick(() => {
            this.posStyle = Object.assign({}, this.posStyle, {
              left: ` ${left}px`,
              top: `${top}px`
            })
          })
        }
        document.onmouseup = (e) => {
          document.onmousemove = null
          document.onmouseup = null
        }
      }
    },
    pointMove (ev) {
      if (this.isCanvasCanDraw) {
        ev = ev || window.event
        const x = ev.offsetX
        const y = ev.offsetY
        this.$nextTick(() => {
          this.mouseLeft = x
          this.mouseTop = y
        })
      }
    },
    canvasMousedown () {
      this.canvas.onmousedown = (event) => {
        if (event.button !== this.MOUSE_LEFT_BUTTON) {
          return
        }
        if (this.isCanvasCanDraw) {
          this.undoImageData = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height)

          this.cx = event.offsetX
          this.cy = event.offsetY

          this.isDrawing = true
        }
      }
    },
    canvasMousemove () {
      this.canvas.onmousemove = (event) => {
        if (event.button !== this.MOUSE_LEFT_BUTTON) {
          return
        }
        if (this.isCanvasCanDraw) {
          if (this.isDrawing) {
            const dx = event.offsetX
            const dy = event.offsetY
            if (this.isCurrentOptPaint) {
              this.drawLine(this.ctx, '#7630fd', this.brushWidth, this.cx, this.cy, dx, dy)
            } else if (this.isCurrentOptEraser) {
              this.drawEraserLine(this.ctx, 'black', this.brushWidth, this.cx, this.cy, dx, dy)
            }
            this.cx = dx
            this.cy = dy
          }
        }
      }
    },
    canvasMouseup () {
      this.canvas.onmouseup = (event) => {
        if (event.button !== this.MOUSE_LEFT_BUTTON) {
          return
        }
        if (this.isCanvasCanDraw) {
          if (this.isDrawing) {
            const dx = event.offsetX
            const dy = event.offsetY
            if (this.isCurrentOptPaint) {
              this.drawLine(this.ctx, '#7630fd', this.brushWidth, this.cx, this.cy, dx, dy)
            } else if (this.isCurrentOptEraser) {
              this.drawEraserLine(this.ctx, 'black', this.brushWidth, this.cx, this.cy, dx, dy)
            }
            this.cx = 0
            this.cy = 0
            this.isDrawing = false
            this.ctx.globalCompositeOperation = 'source-over'

            if (this.undoImageData) {
              const compressed = pako.deflate(new Uint8Array(this.undoImageData.data))
              this.undoHistory.push(compressed)

              this.redoHistory.splice(0, this.redoHistory.length)
              this.undoImageData = null
            }
          }
        }
      }
    },
    handleCanvasUndoClick () {
      if (this.isUndoDisabled) return

      const imageData = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height)
      const compressedCurrent = pako.deflate(new Uint8Array(imageData.data))
      this.redoHistory.push(compressedCurrent)

      const compressed = this.undoHistory.pop()
      try {
        const decompressed = pako.inflate(compressed)
        const uint8ClampedArray = new Uint8ClampedArray(decompressed)
        const imageData = new ImageData(uint8ClampedArray, this.canvas.width, this.canvas.height)

        this.ctx.putImageData(imageData, 0, 0)
      } catch (error) {
      }
    },
    handleCanvasRedoClick () {
      if (this.isRedoDisabled) return

      const imageData = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height)
      const compressedCurrent = pako.deflate(new Uint8Array(imageData.data))
      this.undoHistory.push(compressedCurrent)

      const compressed = this.redoHistory.pop()
      try {
        const decompressed = pako.inflate(compressed)
        const uint8ClampedArray = new Uint8ClampedArray(decompressed)
        const imageData = new ImageData(uint8ClampedArray, this.canvas.width, this.canvas.height)

        this.ctx.putImageData(imageData, 0, 0)
      } catch (error) {
      }
    },
    drawLine (context, strokeStyle, lineWidth, x0, y0, x1, y1) {
      context.globalCompositeOperation = 'source-over'
      this.baseDrawLine(context, strokeStyle, lineWidth, x0, y0, x1, y1)
    },
    drawEraserLine (context, strokeStyle, lineWidth, x0, y0, x1, y1) {
      context.globalCompositeOperation = 'destination-out'
      this.baseDrawLine(context, strokeStyle, lineWidth, x0, y0, x1, y1)
    },
    baseDrawLine (context, strokeStyle, lineWidth, x0, y0, x1, y1) {
      context.beginPath()
      context.lineCap = 'round'
      context.strokeStyle = strokeStyle
      context.lineWidth = lineWidth
      context.moveTo(x0, y0)
      context.lineTo(x1, y1)
      context.stroke()
    }
  }
}
</script>

<style lang="less" scoped>
.painting-brush-show-model {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  height: 100%;
  display: flex;
  background: #f5f3f3;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .model-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .model-header {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    background-color: #ffffff;

    .header-center {
      position: relative;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      .item-btn {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0 12px;
        height: 48px;
        color: #403d3c;
        background-color: #faf9f9;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        .icon {
          padding-right: 5px;
        }
        &.active {
          color: #7530fe;
        }
        &.btn-disabled {
          color: #7d7675;
          cursor: default;
        }
      }

      .scale-opt {
        display: flex;
        align-items: center;
        margin-left: 25px;
        height: 48px;
        color: #403d3c;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        background-color: #faf9f9;
        .opt-btn {
          cursor: pointer;
        }
        .icon {
          padding-right: 6px;
        }
        .scale-label {
          text-align: center;
          width: 42px;
          margin: 0 12px;
          color: #7d7675;
        }
      }
    }

    .model-head-btn {
      font-size: 40px;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .model-content {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 65px;
    overflow: auto;

    .describe-label {
      color: #000000;
      text-align: center;
      margin: 0 auto;
      padding: 20px;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }

    .canvas-content {
      width: 100vw;
      height: auto;
      position: relative;
      display: flex;
      justify-content: center;
      overflow: visible;
      .canvas-wrap {
        position: absolute;
        cursor: pointer;
        overflow: visible;

        .canvas-image {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .canvas-style {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border: none;
          cursor: default;
        }

        .style-point {
          background: hsla(0,0%,100%,.5);
          pointer-events: none;
          transition: none;
          transform: translate3d(round(-50%, 1px), round(-50%, 1px),0);
          box-sizing: border-box;
          border: 2px solid hsla(0,0%,100%,.8);
          border-radius: 1000px;
          overflow: visible;
        }
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }

    .brush-width-content {
      position: fixed;
      width: 48px;
      height: 222px;
      background-color: #ffffff;
      top: calc(50% - 90px);
      right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 16px;
      z-index: 1;
      .bw-slider {
        padding-left: 4px;
        padding-right: 4px;
        width: 12px;
        height: 100%;
      }
    }
  }
}

</style>
