import {
  ROUTE_AGENT_PLATFORM,
  ROUTE_EXPAND_IMAGE
} from '@/router/router-constants'

const menu = [
  { id: ROUTE_AGENT_PLATFORM, type: 'rtt', title: '人台图', icon: 'model', describe: '只需要将服装穿到人台上，即可用符合品牌调性的各类型真人模特展示服装。' },
  { id: ROUTE_EXPAND_IMAGE, type: 'enlarge', title: '扩图', icon: 'model', describe: '画面扩展。' }
]

export default menu
