/*
    对系统各页面进行路由配置，路由必须都提供唯一的名称，路由跳转建议使用name而不是path。
    每个相关模块的路由都新建一个配置文件在./config目录下，然后在本文件集中配置。
    /////////////////////////////////////
    /////////  路由的特殊设置  ////////////
    /////////////////////////////////////
    安全验证：
    ------------------------
    默认所有路由都是需要验证用户是否登录，如果你需要将某个路由跳过安全验证，请做如下配置：
    meta: {auth: false}

    路由访问：
    ------------------------
    有时候用户第一次打开系统时就直接访问深度路由，如：#/a/b/c，该路由前面还有两级父路由#/a和#/a/b，如果
    你不希望某个路由可以直接被用户打开，而是必须从它的父路由过来的，你可以做如下设置：
    meta: {fromParent: true}
    比如，用户必须在#/a/b跳转到#/a/b/c，如果从其他路由如：#/1/2 -> #/a/b/c 或者在浏览器直接打开#/a/b/c
    的话，页面就会被跳转到#/a/b/c的上一级路由，也就是#/a/b
 */

import Vue from 'vue'
import Router from 'vue-router'
import Security from './router-security'
import RouterBefore from './router-before'
import {
  ROUTE_HOME,
  ROUTE_404,
  ROUTE_LOGIN,
  ROUTE_WORKSPACE,
  ROUTE_MEMBER,
  ROUTE_MOBILE,
  ROUTE_POINT_HISTORY
} from '@/router/router-constants'

import Home from '@/pages/home'
import NotFound from '@/pages/404'
import Login from '@/pages/login'
import Workspace from '@/pages/workspace'
import Member from '@/pages/member'
import Mobile from '@/pages/mobile'
import PointHistory from '@/pages/history/point-history'


import { userStore } from '@/store'

Vue.use(Router)


let router = new Router({
  mode: 'history',
  routes: [
    {
      name: 'Root',
      path: '/',
      redirect: to => {
        return { name: userStore.state.isLogin ? userStore.getWorkspacePage() : userStore.getHomePage() }
      }
    },
    { name: ROUTE_404, path: '/404', component: NotFound, meta: { auth: false } },
    { name: ROUTE_LOGIN, path: '/login', component: Login, meta: { auth: false } },
    { name: ROUTE_HOME, path: '/home', component: Home, meta: { auth: false } },
    {
      name: ROUTE_WORKSPACE,
      path: '/workspace',
      component: Workspace
    },
    {
      name: ROUTE_MEMBER,
      path: '/member',
      component: Member
    },
    {
      name: ROUTE_POINT_HISTORY,
      path: '/point-history',
      component: PointHistory
    },
    {
      name: ROUTE_MOBILE,
      path: '/mobile',
      component: Mobile,
      meta: { auth: false }
    }
  ]
})

router = Security(router)
router = RouterBefore(router)

export default router
