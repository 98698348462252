<template>
  <div class="img-cropper" v-show="showImgCropper">
    <div class="img-cropper-content">
      <div class="opt-content">
        <div class="crop-size">
          <template v-for="(value, k) in cropSize">
            <div class="crop-size-item" :class="{'active': currentCropSize === k}" :key="k" @click="handleCropSizeItemClick(k)">{{value.width}}x{{value.height}}</div>
          </template>
        </div>
        <div class="opt">
          <a-button @click="close">关闭</a-button>
          <a-button type="primary" @click="cropperImg">确定</a-button>
        </div>
      </div>
      <div class="cut-content">
        <div class="cut">
          <vue-cropper ref="cropper"
                       :img="option.img"
                       :output-size="option.size"
                       :output-type="option.outputType"
                       :full="option.full"
                       :fixed="option.fixed"
                       :fixed-number="fixedNumber"
                       :fixed-box="option.fixedBox"
                       :original="option.original"
                       :auto-crop="option.autoCrop"
                       :auto-crop-width="cropSize[currentCropSize].width"
                       :auto-crop-height="cropSize[currentCropSize].height"
                       :center-box="option.centerBox"
                       :can-move-box="option.canMoveBox"
                       :high="option.high"
                       :mode="option.mode"
                       :max-img-size="option.max" ></vue-cropper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueCropper } from 'vue-cropper'
export default {
  components: { VueCropper },
  data () {
    return {
      showImgCropper: false,
      model: false,
      modelSrc: '',
      previews: {},
      autoCropWidth: 1024,
      autoCropHeight: 1024,
      option: {
        img: '',
        size: 1,
        full: false,
        outputType: 'png',
        fixedBox: true,
        fixed: false,
        original: true,
        autoCrop: true,
        autoCropWidth: 1024,
        autoCropHeight: 1024,
        centerBox: false,
        canMoveBox: false,
        high: true,
        max: 99999,
        mode: 'contain'
      },
      fixedNumber: [16, 9],
      currentCropSize: 'a',
      cropSize: {
        a: { width: 1024, height: 1024 },
        b: { width: 1152, height: 896 },
        c: { width: 1216, height: 832 },
        d: { width: 1344, height: 768 },
        e: { width: 1536, height: 640 },
        f: { width: 640, height: 1536 },
        g: { width: 768, height: 1344 },
        h: { width: 832, height: 1216 },
        i: { width: 896, height: 1152 }
      }
    }
  },
  methods: {
    open (img) {
      this.option.img = img
      this.showImgCropper = true
    },
    close () {
      this.showImgCropper = false
      this.option.img = ''
      this.currentCropSize = 'a'
    },
    handleCropSizeItemClick (k) {
      this.currentCropSize = k
    },
    cropperImg () {
      this.$refs.cropper.getCropData((data) => {
        this.$emit('handleCropperImg', data)
        this.close()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.img-cropper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.45);
  padding: 20px;
  z-index: 3350;
  overflow: hidden;

  .img-cropper-content {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #909399;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 12px;

    display: flex;
    flex-direction: column;
    overflow: hidden;

    .opt-content {
      position: relative;
      display: flex;
      margin-bottom: 12px;
      justify-content: space-between;

      .crop-size {
        position: relative;
        display: flex;
        .crop-size-item {
          position: relative;
          border: 1px solid #909399;
          background-color: #ffffff;
          border-radius: 8px;
          padding: 5px 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          cursor: pointer;
          &.active {
            background-color: #3d8acf;
            color: #ffffff;
          }
        }
      }

      .opt {
        position: relative;
        display: flex;
      }
    }

    .cut-content {
      position: relative;
      width: 100%;
      height: 100%;
      flex: 1;
      overflow: auto;
      .cut {
        position: relative;
        width: 100%;
        height: 100%;
        min-width: 1600px;
        min-height: 1600px;
      }
    }
  }
}

</style>
