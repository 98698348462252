/**
 * @see src/http/api/constants.js
 */
import StoreModule from './module'

export default {
  namespaced: true,
  state: {
    optionForList: {},
    optionForMap: {}
  },
  mutations: {
    update (state, data) {
      state.optionForList = data

      const m = {}
      for (const key of Object.keys(data)) {
        const mm = {}
        m[key] = mm
        for (const item of data[key]) {
          mm[item.id] = item.name
        }
      }
      state.optionForMap = m
    }
  }
}

export const CONSTANTS_MODULE = 'constants'
export class ConstantsStore extends StoreModule {
  constructor (store) {
    super(CONSTANTS_MODULE, store)
  }

  updateConstants (constants) {
    this.commit('update', constants)
  }

  getConstantList (key) {
    return this.state.optionForList[key] || []
  }

  getConstantMap (key) {
    return this.state.optionForMap[key] || {}
  }
}
