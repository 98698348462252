<template>
  <a-layout id="workspace">
    <app-header class="header"></app-header>
    <a-layout>
      <a-layout-sider :width="isWorkspaceMenuShow ? 80 : 0" style="background: #fff">
        <workspace-menu @menuForward="menuForward"></workspace-menu>
      </a-layout-sider>
      <a-layout id="app-body-container" class="app-content">
        <template v-if="activeMenuId === ROUTE_AGENT_PLATFORM()">
          <agent-platform :business-type="businessType"></agent-platform>
        </template>
        <template v-if="activeMenuId === ROUTE_EXPAND_IMAGE()">
          <agent-platform :business-type="businessType"></agent-platform>
        </template>
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<script>
import WorkspaceMenu from '@/layout/workspace-menu'
import AppHeader from '@/layout/app-header'
import { ROUTE_AGENT_PLATFORM, ROUTE_EXPAND_IMAGE } from '@/router/router-constants'
import AgentPlatform from '@/pages/workspace/agent-platform/index.vue'
import { TYPE_RTT } from '@/pages/workspace/business-type'

export default {
  components: { WorkspaceMenu, AppHeader, AgentPlatform },
  data () {
    return {
      isWorkspaceMenuShow: true,
      activeMenuId: ROUTE_AGENT_PLATFORM,
      businessType: TYPE_RTT
    }
  },
  mounted () {
  },
  methods: {
    ROUTE_AGENT_PLATFORM () {
      return ROUTE_AGENT_PLATFORM
    },
    ROUTE_EXPAND_IMAGE () {
      return ROUTE_EXPAND_IMAGE
    },
    menuForward (menu) {
      this.activeMenuId = menu.id
      this.businessType = menu.type
    }
  }
}
</script>

<style scoped>
#workspace {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
}
.app-content {
  padding: 0;
  position: relative !important;
}
</style>
