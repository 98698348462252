<template>
  <div class="img-expand" v-show="showImgExpand">
    <div class="img-expand-content" v-if="imgInfo">
      <div class="opt-content">
        <div class="crop-size" >
          <div>原图:{{imgInfo.imgWidth}}x{{imgInfo.imgHeight}}; 当前图:{{imgInfo.sWidth}}x{{imgInfo.sHeight}}; 线框图:<span style="color: #3d8acf;">{{controlBox.width}}x{{controlBox.height}}</span>;</div>
        </div>
        <div class="opt">
          <a-button @click="close">关闭</a-button>
          <a-button type="primary" @click="handleExpandOk">确定</a-button>
        </div>
      </div>
      <div class="expand-content">
        <div ref="expand" class="expand">
          <div ref="expandBox" class="expand-box">
            <div :style="expandImgStyle"></div>
            <div class="control-box" :style="controlBoxStyle">
              <div class="control-point cp-tl" :style="controlPointTLStyle" @mousedown.stop="handleControlPointMouseDown($event, 'tl')"><div class="control-point-corner"></div></div>
              <div class="control-point cp-tr" :style="controlPointTRStyle" @mousedown.stop="handleControlPointMouseDown($event, 'tr')"><div class="control-point-corner"></div></div>
              <div class="control-point cp-bl" :style="controlPointBLStyle" @mousedown.stop="handleControlPointMouseDown($event, 'bl')"><div class="control-point-corner"></div></div>
              <div class="control-point cp-br" :style="controlPointBRStyle" @mousedown.stop="handleControlPointMouseDown($event, 'br')"><div class="control-point-corner"></div></div>

              <div class="control-point cp-t" :style="controlPointTStyle" @mousedown.stop="handleControlPointMouseDown($event, 't')"><div class="control-point-middle-h"></div></div>
              <div class="control-point cp-r" :style="controlPointRStyle" @mousedown.stop="handleControlPointMouseDown($event, 'r')"><div class="control-point-middle-v"></div></div>
              <div class="control-point cp-b" :style="controlPointBStyle" @mousedown.stop="handleControlPointMouseDown($event, 'b')"><div class="control-point-middle-h"></div></div>
              <div class="control-point cp-l" :style="controlPointLStyle" @mousedown.stop="handleControlPointMouseDown($event, 'l')"><div class="control-point-middle-v"></div></div>

              <div class="show-val-box" v-if="isShowValBox" :style="showValBoxStyle">
                <div v-show="isShowTopVal">Top:{{expandVal.top}}</div>
                <div v-show="isShowRightVal">Right:{{expandVal.right}}</div>
                <div v-show="isShowBottomVal">Bottom:{{expandVal.bottom}}</div>
                <div v-show="isShowLeftVal">Left:{{expandVal.left}}</div>
              </div>
            </div>
            <div class="expand-img-box" :style="expandImgBoxStyle" @mousedown="handleExpandImgMouseDown">
              <img class="expand-img" :src="imgInfo.src" alt="image" :style="expandImgStyle">
            </div>
          </div>
        </div>
        <div class="expand-label">
          <div>Top:{{expandVal.top}}</div>
          <div>Right:{{expandVal.right}}</div>
          <div>Bottom:{{expandVal.bottom}}</div>
          <div>Left:{{expandVal.left}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showImgExpand: false,
      model: false,
      modelSrc: '',
      previews: {},
      autoCropWidth: 1024,
      autoCropHeight: 1024,
      fixedNumber: [16, 9],
      imgInfo: null,
      controlBox: {
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      expandImgBox: {
        top: 0,
        left: 0
      },
      mousePoint: {
        top: 0,
        left: 0
      },
      controlVal: '',
      isShowValBox: false,
      isShowTopVal: false,
      isShowRightVal: false,
      isShowBottomVal: false,
      isShowLeftVal: false,
      maxTop: 0,
      maxLeft: 0,
      expandVal: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        iTop: 0,
        iLeft: 0
      }
    }
  },
  computed: {
    expandImgStyle () {
      const imgInfo = this.imgInfo
      return {
        width: `${imgInfo.sWidth}px`,
        height: `${imgInfo.sHeight}px`
      }
    },
    expandImgBoxStyle () {
      const imgInfo = this.imgInfo
      const expandImgBox = this.expandImgBox
      return {
        width: `${imgInfo.sWidth}px`,
        height: `${imgInfo.sHeight}px`,
        top: `${expandImgBox.top}px`,
        left: `${expandImgBox.left}px`
      }
    },
    controlBoxStyle () {
      const controlBox = this.controlBox
      return {
        width: `${controlBox.width}px`,
        height: `${controlBox.height}px`,
        top: `${controlBox.top}px`,
        left: `${controlBox.left}px`
      }
    },
    showValBoxStyle () {
      const mousePoint = this.mousePoint
      return {
        top: `${mousePoint.top}px`,
        left: `${mousePoint.left}px`
      }
    },
    controlPointTLStyle () {
      return {
        top: '0px',
        left: '0px'
      }
    },
    controlPointTRStyle () {
      const controlBox = this.controlBox
      return {
        top: '0px',
        left: `${controlBox.width}px`
      }
    },
    controlPointBLStyle () {
      const controlBox = this.controlBox
      return {
        top: `${controlBox.height}px`,
        left: '0px'
      }
    },
    controlPointBRStyle () {
      const controlBox = this.controlBox
      return {
        top: `${controlBox.height}px`,
        left: `${controlBox.width}px`
      }
    },
    controlPointTStyle () {
      const controlBox = this.controlBox
      return {
        top: '0px',
        left: `${controlBox.width / 2}px`
      }
    },
    controlPointRStyle () {
      const controlBox = this.controlBox
      return {
        top: `${controlBox.height / 2}px`,
        left: `${controlBox.width}px`
      }
    },
    controlPointBStyle () {
      const controlBox = this.controlBox
      return {
        top: `${controlBox.height}px`,
        left: `${controlBox.width / 2}px`
      }
    },
    controlPointLStyle () {
      const controlBox = this.controlBox
      return {
        top: `${controlBox.height / 2}px`,
        left: '0px'
      }
    }
  },
  methods: {
    open (opt) {
      this.imgInfo = opt
      this.expandImgBox = Object.assign({}, this.expandImgBox, {
        top: this.imgInfo.iTop,
        left: this.imgInfo.iLeft
      })
      const width = this.imgInfo.sWidth + this.imgInfo.left + this.imgInfo.right
      const height = this.imgInfo.sHeight + this.imgInfo.top + this.imgInfo.bottom
      const top = -this.imgInfo.top + this.imgInfo.iTop
      const left = -this.imgInfo.left + this.imgInfo.iLeft
      const right = left + width
      const bottom = top + height
      this.controlBox = Object.assign({}, this.controlBox, {
        width: width,
        height: height,
        top: top,
        left: left,
        right: right,
        bottom: bottom
      })
      this.showImgExpand = true
      this.changeExpandVal()
    },
    close () {
      this.showImgExpand = false
      this.imgInfo = null
      this.isShowValBox = false
    },
    handleExpandOk () {
      this.$emit('handleExpandImg', this.expandVal)
      this.close()
    },
    handleExpandImgMouseDown (e) {
      const oX = e.clientX
      const oY = e.clientY
      const oTop = this.expandImgBox.top
      const oLeft = this.expandImgBox.left
      document.onmousemove = e => {
        const cX = e.clientX - oX
        const cY = e.clientY - oY
        this.expandImgBox.top = Math.min(Math.max(cY + oTop, this.controlBox.top), (this.controlBox.bottom - this.imgInfo.sHeight))
        this.expandImgBox.left = Math.min(Math.max(cX + oLeft, this.controlBox.left), (this.controlBox.right - this.imgInfo.sWidth))
        this.mousePoint.top = 10
        this.mousePoint.left = 20
        this.isShowValBox = true
        this.isShowTopVal = true
        this.isShowRightVal = true
        this.isShowBottomVal = true
        this.isShowLeftVal = true
        this.changeExpandVal()
      }
      document.onmouseup = e => {
        this.isShowValBox = false
        this.isShowTopVal = false
        this.isShowRightVal = false
        this.isShowBottomVal = false
        this.isShowLeftVal = false
        document.onmousemove = null
        document.onmouseup = null
      }
    },
    handleControlPointMouseDown (e, cv) {
      const oX = e.clientX
      const oY = e.clientY
      const oTop = this.controlBox.top
      const oLeft = this.controlBox.left
      const oRight = this.controlBox.right
      const oBottom = this.controlBox.bottom
      this.maxTop = this.$refs.expand.getBoundingClientRect().y - this.$refs.expandBox.getBoundingClientRect().y
      this.maxLeft = this.$refs.expand.getBoundingClientRect().left - this.$refs.expandBox.getBoundingClientRect().left
      document.onmousemove = e => {
        this.isShowValBox = true
        const cX = e.clientX - oX
        const cY = e.clientY - oY
        switch (cv) {
          case 'tl':
            this.controlBox.top = Math.max(Math.min(Math.min(cY + oTop, this.controlBox.bottom - this.imgInfo.sHeight), this.expandImgBox.top), this.maxTop)
            this.controlBox.left = Math.max(Math.min(Math.min(cX + oLeft, this.controlBox.right - this.imgInfo.sWidth), this.expandImgBox.left), this.maxLeft)
            this.controlBox.width = this.controlBox.right - this.controlBox.left
            this.controlBox.height = this.controlBox.bottom - this.controlBox.top
            this.mousePoint.top = 10
            this.mousePoint.left = 20
            this.isShowTopVal = true
            this.isShowLeftVal = true
            break
          case 'tr':
            this.controlBox.top = Math.max(Math.min(Math.min(cY + oTop, this.controlBox.bottom - this.imgInfo.sHeight), this.expandImgBox.top), this.maxTop)
            this.controlBox.right = Math.max(Math.max(oRight + cX, this.imgInfo.sWidth + this.controlBox.left), this.expandImgBox.left + this.imgInfo.sWidth)
            this.controlBox.height = this.controlBox.bottom - this.controlBox.top
            this.controlBox.width = this.controlBox.right - oLeft
            this.mousePoint.top = 10
            this.mousePoint.left = this.controlBox.width + 20
            this.isShowTopVal = true
            this.isShowRightVal = true
            break
          case 'bl':
            this.controlBox.bottom = Math.max(Math.max(oBottom + cY, this.imgInfo.sHeight + this.controlBox.top), this.expandImgBox.top + this.imgInfo.sHeight)
            this.controlBox.left = Math.max(Math.min(Math.min(cX + oLeft, this.controlBox.right - this.imgInfo.sWidth), this.expandImgBox.left), this.maxLeft)
            this.controlBox.width = this.controlBox.right - this.controlBox.left
            this.controlBox.height = this.controlBox.bottom - oTop
            this.mousePoint.top = this.controlBox.height - 30
            this.mousePoint.left = 20
            this.isShowBottomVal = true
            this.isShowLeftVal = true
            break
          case 'br':
            this.controlBox.bottom = Math.max(Math.max(oBottom + cY, this.imgInfo.sHeight + this.controlBox.top), this.expandImgBox.top + this.imgInfo.sHeight)
            this.controlBox.right = Math.max(Math.max(oRight + cX, this.imgInfo.sWidth + this.controlBox.left), this.expandImgBox.left + this.imgInfo.sWidth)
            this.controlBox.width = this.controlBox.right - oLeft
            this.controlBox.height = this.controlBox.bottom - oTop
            this.mousePoint.top = this.controlBox.height - 30
            this.mousePoint.left = this.controlBox.width + 20
            this.isShowBottomVal = true
            this.isShowRightVal = true
            break
          case 't':
            this.controlBox.top = Math.max(Math.min(Math.min(cY + oTop, this.controlBox.bottom - this.imgInfo.sHeight), this.expandImgBox.top), this.maxTop)
            this.controlBox.height = this.controlBox.bottom - this.controlBox.top
            this.mousePoint.top = 10
            this.mousePoint.left = this.controlBox.width / 2 + 20
            this.isShowTopVal = true
            break
          case 'r':
            this.controlBox.right = Math.max(Math.max(oRight + cX, this.imgInfo.sWidth + this.controlBox.left), this.expandImgBox.left + this.imgInfo.sWidth)
            this.controlBox.width = this.controlBox.right - oLeft
            this.mousePoint.top = this.controlBox.height / 2 + 10
            this.mousePoint.left = this.controlBox.width + 20
            this.isShowRightVal = true
            break
          case 'b':
            this.controlBox.bottom = Math.max(Math.max(oBottom + cY, this.imgInfo.sHeight + this.controlBox.top), this.expandImgBox.top + this.imgInfo.sHeight)
            this.controlBox.height = this.controlBox.bottom - oTop
            this.mousePoint.top = this.controlBox.height - 30
            this.mousePoint.left = this.controlBox.width / 2 + 20
            this.isShowBottomVal = true
            break
          case 'l':
            this.controlBox.left = Math.max(Math.min(Math.min(cX + oLeft, this.controlBox.right - this.imgInfo.sWidth), this.expandImgBox.left), this.maxLeft)
            this.controlBox.width = this.controlBox.right - this.controlBox.left
            this.mousePoint.top = this.controlBox.height / 2 + 10
            this.mousePoint.left = 20
            this.isShowLeftVal = true
            break
        }
        this.changeExpandVal()
      }
      document.onmouseup = e => {
        this.isShowValBox = false
        this.isShowTopVal = false
        this.isShowRightVal = false
        this.isShowBottomVal = false
        this.isShowLeftVal = false
        document.onmousemove = null
        document.onmouseup = null
      }
    },
    changeExpandVal () {
      this.expandVal = Object.assign({}, {
        top: Math.abs(this.controlBox.top - this.expandImgBox.top),
        left: Math.abs(this.controlBox.left - this.expandImgBox.left),
        right: this.controlBox.right - (this.expandImgBox.left + this.imgInfo.sWidth),
        bottom: this.controlBox.bottom - (this.expandImgBox.top + this.imgInfo.sHeight),
        iTop: this.expandImgBox.top,
        iLeft: this.expandImgBox.left
      })
    }
  }
}
</script>

<style lang="less" scoped>
.img-expand {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.45);
  padding: 20px;
  z-index: 3350;
  overflow: hidden;
  user-select: none;

  .img-expand-content {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #909399;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 12px;

    display: flex;
    flex-direction: column;
    overflow: hidden;

    .opt-content {
      position: relative;
      display: flex;
      margin-bottom: 12px;
      justify-content: space-between;

      .crop-size {
        position: relative;
        display: flex;
      }

      .opt {
        position: relative;
        display: flex;
      }
    }

    .expand-content {
      position: relative;
      width: 100%;
      height: 100%;
      flex: 1;
      overflow: auto;
      user-select: none;
      .expand {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .expand-box {
          position: relative;
          overflow: visible;
          .expand-img-box {
            position: absolute;
            z-index: 1200;
            cursor: grab;
            .expand-img {
              position: absolute;
              pointer-events: none;
            }
          }
          .control-box {
            position: absolute;
            top: 0;
            left: 0;
            border: 1px dashed #3d8acf;
            overflow: visible;
            .control-point {
              position: absolute;
              z-index: 1500;
              .control-point-corner {
                position: absolute;
                width: 16px;
                height: 16px;
                top: -8px;
                left: -8px;
                background-color: #3d8acf;
                transform-origin: center center;
              }

              .control-point-middle-h {
                position: absolute;
                width: 36px;
                height: 10px;
                top: -5px;
                left: -18px;
                background-color: #3d8acf;
                transform-origin: center center;
              }

              .control-point-middle-v {
                position: absolute;
                width: 10px;
                height: 36px;
                top: -18px;
                left: -5px;
                background-color: #3d8acf;
                transform-origin: center center;
              }
            }

            .cp-tl {
              cursor: nw-resize;
            }
            .cp-tr {
              cursor: ne-resize;
            }
            .cp-bl {
              cursor: sw-resize;
            }
            .cp-br {
              cursor: se-resize;
            }

            .cp-t {
              cursor: n-resize;
            }
            .cp-r {
              cursor: w-resize;
            }
            .cp-b {
              cursor: s-resize;
            }
            .cp-l {
              cursor: e-resize;
            }

            .show-val-box {
              position: absolute;
              background-color: rgba(0,0,0,0.4);
              color: #ffffff;
              border-radius: 8px;
              padding: 10px;
              display: flex;
              flex-direction: column;
              z-index: 1400;
            }
          }
        }
      }
      .expand-label {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.4);
        color: #ffffff;
        border-radius: 8px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        z-index: 1300;
      }
    }
  }
}

</style>
