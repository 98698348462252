import defineApi from '../define-api'
import http from '../application-api'


export const getPointProductList = defineApi((config, type) => {
  config.params = {
    type: type
  }
  http.get('/point_product/list', config)
})

export const pointProductOrderCreate = defineApi((config, id) => {
  config = Object.assign({
    params: {
      id: id
    }
  }, config)
  http.post('/point_product/order/create', config)
})

export const getPayOrderStatus = defineApi((config, orderNo) => {
  config.params = {
    orderNo: orderNo
  }
  http.get('/pay_order/status', config)
})

export const getCustomerVipServices = defineApi((config) => {
  http.get('/customer_vip/services', config)
})

export const customerVipOrderCreate = defineApi((config, serviceId, priceId) => {
  config = Object.assign({
    params: {
      serviceId: serviceId,
      priceId: priceId
    }
  }, config)
  http.post('/customer_vip/order', config)
})
