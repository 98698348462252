<template>
  <a-tabs v-if="false" class="case-tabs" default-active-key="c0" size="large" @change="handleCaseTabsChange">
    <a-tab-pane key="c0" tab="我的星标">
      <div class="cases-list">
        <div class="no-data">
          没有更多了
        </div>
      </div>
    </a-tab-pane>
    <a-tab-pane key="c1" tab="优秀案例">
      <div :style="{ height: waterfallHeight + 'px'}" class="cases-list">
        <vue-waterfall-easy ref="waterfall" class="waterfull" :imgsArr="caseImgsArr" @scrollReachBottom="getCaseImgsArrData" @click="handleCaseImgClick">
          <div slot="waterfall-over">
            <div class="no-data">没有更多了</div>
          </div>
        </vue-waterfall-easy>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>

<script>

import VueWaterfallEasy from 'vue-waterfall-easy'
import kit from '@/utils/kit'
export default {
  components: { VueWaterfallEasy },
  data () {
    return {
      caseImgsArr: [],
      group: 0,
      waterfallHeight: 0
    }
  },
  methods: {
    handleCaseTabsChange (key) {
      if (key !== 'c1') {
        this.caseImgsArr = []
        this.group = 0
      } else {
        this.waterfallHeight = this.$parent.$refs.workspaceContent.clientHeight - 71
        this.getCaseImgsArrData()
      }
    },
    getCaseImgsArrData () {
      setTimeout(() => {
        if (this.group < 5) {
          this.caseImgsArr = this.caseImgsArr.concat(this.addCaseImgsArr())
          this.group++
        } else {
          this.$refs.waterfall.waterfallOver()
        }
      }, 1000)
    },
    handleCaseImgClick (event, { index, value }) {
      event.preventDefault()
      if (event.target.tagName.toLowerCase() === 'img') {
        const arr = [Object.assign({}, value, {
          showPromptDetail: true
        })]
        this.$emit('handleImageViewerOpen', {
          arr: arr,
          index: 0
        })
      }
    },
    addCaseImgsArr () {
      const num = Math.floor(Math.random() * 10 + 10)
      const test = []
      const frg = [
        'https://img1.baidu.com/it/u=1458656822,2078909008&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=750',
        'https://img.pconline.com.cn/images/upload/upc/tx/itbbs/1404/09/c10/32951689_1397033418473_mthumb.jpg',
        'https://n.sinaimg.cn/sinacn21/32/w600h1032/20180517/9c3f-harvfhu8859926.jpg',
        'https://photo.16pic.com/00/57/54/16pic_5754262_b.jpg',
        'https://img1.baidu.com/it/u=2493100667,3909349679&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=750',
        'https://img0.baidu.com/it/u=2100359536,3582862191&fm=253&fmt=auto&app=138&f=JPEG?w=256&h=499',
        'https://img1.baidu.com/it/u=4032234471,475026257&fm=253&fmt=auto&app=138&f=JPEG?w=750&h=500',
        'https://t10.baidu.com/it/u=3979728636,2704767100&fm=170&s=5A3B61C8CC169257CADE7718030050DC&w=640&h=964&img.JPEG',
        'https://n.sinaimg.cn/sinacn15/400/w500h700/20180524/35fd-haysvix9917900.jpg',
        'https://img.redocn.com/sheying/20150924/youyakaifangdehehua_5015546.jpg',
        'http://192.168.1.51:9999/bdesign/2021-07/f41615b20c734a518382322bca4074f5.png'
      ]
      for (let i = 0; i < num; i++) {
        const num2 = Math.floor(Math.random() * (frg.length + 1))
        const img = {
          id: kit.str.id(),
          src: frg[num2],
          name: `名字-${kit.str.id(6)}`,
          ori: 'http://192.168.1.51:9999/bdesign/2023-08/92c8f4a10a144ff79ff6c2e4eeb29092.jpeg',
          opi: 'http://192.168.1.51:9999/bdesign/2023-08/e65547dfee1440608e93c9af2d8a2be5.jpg',
          mask: 'http://192.168.1.51:9999/bdesign/2023-08/b917de73de114e05b5e001bfe23b06e8.png',
          type: '文字描述',
          description: '俄罗斯小宝宝，积雪，树，帐篷，',
          imageWidth: 768,
          imageHeight: 1024
        }
        test.push(img)
      }
      return test
    }
  }
}
</script>

<style scoped lang="less">
@deep: ~'>>>';
.case-tabs {
  position: relative;
  width: 88%;
  height: 100%;
  padding-left: 40px;
  .cases-list {
    position: relative;
    width: 100%;
    height: 100%;
    .no-data {
      position: relative;
      text-align: center;
      color: #2a2a2a;
      margin-top: 40px;
      margin-bottom: 80px;
    }

    &@{deep} a.img-inner-box {
      border-radius: 8px;
    }
    &@{deep} a.img-wraper > img {
      border-radius: 8px;
    }
  }
}
</style>
