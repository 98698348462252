export const ROUTE_HOME = 'home'
export const ROUTE_LOGIN = 'login'
export const ROUTE_404 = 'not-found'

export const ROUTE_WORKSPACE = 'workspace'
export const ROUTE_AGENT_PLATFORM = 'agent-platform'
export const ROUTE_EXPAND_IMAGE = 'expand-image'
export const ROUTE_MEMBER = 'member'
export const ROUTE_POINT_HISTORY = 'point-history'
export const ROUTE_MOBILE = 'mobile'


export const ROUTE_USER = 'user'
export const ROUTE_ROLE = 'role'


export const ROUTE_PROJECT = 'project'
