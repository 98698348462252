<template>
  <div class="open-edit-content" v-show="currentTask">
    <div class="edit-mask" v-show="isOpenEditShow"></div>
    <div class="edit-content" v-show="isOpenEditShow">
      <div class="opt-content">
        <div class="img-area" v-if="currentTask">
          <a-spin :spinning="spinningOneShow">
            <div class="spin-content">
              <div class="area-div">
                <div class="upload-wrap">
                  <template v-if="currentTask && currentTask.img">
                    <img class="uw-img" :src="currentTask.img" alt="origin" @click="handleInitImageSelectClick">
                  </template>
                  <div v-show="!(currentTask && currentTask.img)"
                       class="dropzone"
                       ref="dropzone"
                       @click="handleInitImageSelectClick"
                       @dragenter="dragEnter"
                       @dragleave="dragLeave"
                       @dragover="dragOver"
                       @drop="dropFile">
                    <div class="label1">点击上传</div>
                    <div class="label2">或直接将图片文件拖入此区域</div>
                    <div class="label3">图片为JPG格式，大小不超过10MB</div>
                    <input ref="initImage" name="initImage" type="file" accept="image/jpeg" style="display: none;" @change="handleInitImageUploadChange" />
                  </div>
                </div>
                原图
              </div>
            </div>
          </a-spin>
          <div class="spin-content">
            <div class="area-div">
              <a-spin :spinning="spinningTwoShow || currentTask.loadingSegment">
                <div class="upload-wrap">
                  <template>
                    <div class="mask-area">
                      <template v-if="currentTask && currentTask.mask">
                        <img class="absolute opacity5 mask-img" :src="currentTask.img" alt="origin">
                        <img id="currentTaskMask" class="absolute mask-img" :src="currentTask.mask" alt="origin">
                      </template>
                      <div v-else class="opt">
                        <a-icon style="font-size: 22px;" type="picture" />
                        <div>固化区域</div>
                        <div>纯黑区域被替换，纯白区域保持不变</div>
                      </div>
                      <div class="region-btn" v-show="currentTask.regionBtnShow">
                        <a-button icon="edit" @click="handleImageRegionClick">编辑选区</a-button>
                      </div>
                    </div>
                  </template>
                </div>
              </a-spin>
              <span>选区图<template>
                    ，或<span type="button" class="file-btn" @click="handleUploadMaskClick">上传一张蒙版图<input ref="maskInput" name="maskInput" type="file" accept="image/*" @change="handleMaskUploadChange"/></span>
                  </template></span>
            </div>
          </div>
        </div>

        <div class="tabs-area" v-if="currentTask">
          <a-tabs v-model="optActiveTab" @change="handleOptTabsChange">
            <a-tab-pane key="0" tab="文字描述">
              <text-prompt-type-input :description.sync="currentTask.description" :negative-description.sync="currentTask.negativeDescription"></text-prompt-type-input>
            </a-tab-pane>
            <a-tab-pane key="1" tab="快捷模板">
              <text-prompt-type-template ref="textPromptTypeTemplate" :custom-description.sync="currentTask.customDescription" @changeTemplateSelected="changeTemplateSelected"></text-prompt-type-template>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
      <div class="bottom-bar">
        <div>本次任务将消耗点数<span class="text-danger font-large-x font-bold" style="margin-left: 5px;">{{consumeAIBusinessPoint}}</span></div>
        <a-button style="width: 300px;height: 40px;border-radius: 10px;" type="primary"
                  :loading="submitLoading"
                  @click="handleExecAITaskImageGenerate">执行</a-button>
      </div>
    </div>
    <div class="open-icon" :class="{'edit-show': isOpenEditShow}" @click="handleOpenEditShow">
      <a-icon type="caret-left" style="color: #7530fe;" />
    </div>
  </div>
</template>

<script>
import { STATUS_CALCULATION, STATUS_LOADING } from '@/pages/workspace/agent-platform/task-constants'
import TextPromptTypeInput from '@/pages/workspace/agent-platform/comp/text-prompt-type-input.vue'
import TextPromptTypeTemplate from '@/pages/workspace/agent-platform/comp/text-prompt-type-template.vue'
import { execAITaskImageGenerate, execAITaskImageSegment, execAITaskImageSegmentResult, getAITaskImageSegmentHistory, getAIBusinessPointInfo } from '@/http/api/ai-task'
import kit from '@/utils/kit'


export default {
  components: { TextPromptTypeInput, TextPromptTypeTemplate },
  props: {
    currentTask: { type: Object },
    businessType: { type: String }
  },
  data () {
    return {
      dropzoneActive: false,
      isOpenEditShow: false,
      spinningOneShow: false,
      spinningTwoShow: false,
      submitLoading: false,
      currentPromptType: 0,
      optActiveTab: '0',
      textPromptType: ['input', 'template'],
      textPromptTypeStr: {
        input: '文字描述',
        template: '快捷模板'
      },
      cropSize: {
        a: { width: 1024, height: 1024 },
        b: { width: 1152, height: 896 },
        c: { width: 1216, height: 832 },
        d: { width: 1344, height: 768 },
        e: { width: 1536, height: 640 },
        f: { width: 640, height: 1536 },
        g: { width: 768, height: 1344 },
        h: { width: 832, height: 1216 },
        i: { width: 896, height: 1152 }
      },
      imageSegmentResultIntervals: {},
      imageSegmentResultIntervalsIds: {},
      consumeAIBusinessPoint: 0
    }
  },
  mounted () {
    getAIBusinessPointInfo()
      .complete(() => {})
      .success(res => {
        this.consumeAIBusinessPoint = res.data[this.businessType]
      })
      .send()
  },
  methods: {
    handleOpenEditShow () {
      this.isOpenEditShow = !this.isOpenEditShow
    },
    changeOpenEditShow (isOpenEditShow) {
      this.isOpenEditShow = isOpenEditShow
      if (this.isOpenEditShow) {
        this.changeTextPromptTypeTemplateShowReload()
      }
    },
    hideSpinningTwoShow () {
      this.spinningTwoShow = false
    },
    hideSpinningOneShow () {
      this.spinningOneShow = false
    },
    changeOpenEditLoad () {
      this.isOpenEditShow = true
      this.spinningOneShow = true
      this.spinningTwoShow = true
    },
    changeOptActiveTab (tab) {
      this.optActiveTab = tab
      this.currentPromptType = tab
    },
    changeTextPromptTypeTemplateShowReload () {
      if (this.currentPromptType === '1' && this.$refs.textPromptTypeTemplate) {
        this.$refs.textPromptTypeTemplate.show(this.currentTask.templateSelected, false)
      }
    },
    changeTextPromptTypeTemplateShow (arr) {
      this.$refs.textPromptTypeTemplate.show(this.currentTask.templateSelected, true, arr)
    },
    handleInitImageSelectClick () {
      this.$refs.initImage.click()
    },
    handleInitImageUploadChange (e) {
      this.spinningOneShow = true
      this.currentTask.regionBtnShow = true
      this.getBase64(e.target.files[0], imageUrl => {
        // this.$refs.imgCropper.open(imageUrl)
        this.handleCropperImg(imageUrl)
        this.$refs.initImage.value = ''
      })
    },
    handleImageRegionClick () {
      if (this.currentTask.img) {
        this.$emit('handleImageRegion')
      } else {
        this.$message.warning('当前未上传原图')
      }
    },
    handleUploadMaskClick () {
      this.$refs.maskInput.value = ''
      this.$refs.maskInput.click()
    },
    handleMaskUploadChange (e) {
      this.spinningTwoShow = true
      this.getBase64(e.target.files[0], imageUrl => {
        this.hideSpinningTwoShow()
        this.currentTask.mask = imageUrl
        this.currentTask.regionBtnShow = false

        const taskId = this.currentTask.id
        const id = this.imageSegmentResultIntervalsIds[taskId]
        clearInterval(this.imageSegmentResultIntervals[id])
        delete this.imageSegmentResultIntervals[id]
        delete this.imageSegmentResultIntervalsIds[taskId]
        this.currentTask.loadingSegment = false
        this.currentTask.status = ''

        const temp = new Image()
        temp.src = imageUrl
        temp.onload = () => {
          this.currentTask.maskWidth = temp.width
          this.currentTask.maskHeight = temp.height
        }
      })
    },
    handleOptTabsChange (key) {
      this.currentPromptType = key
      if (this.currentPromptType === '1') {
        this.$nextTick(() => {
          this.$refs.textPromptTypeTemplate.show(this.currentTask.templateSelected)
        })
      }
    },
    changeTemplateSelected (templateSelected) {
      this.currentTask.templateSelected = templateSelected
    },

    handleExecAITaskImageGenerate () {
      let description = this.currentTask.description
      const negativeDescription = this.currentTask.negativeDescription
      const img = this.currentTask.img
      const mask = this.currentTask.mask
      const textPromptType = this.textPromptType[this.currentPromptType]
      const segment = this.currentTask.originMaskSegment

      const promptTemplates = []
      let customTemplatePrompt = ''
      if (textPromptType === 'template') {
        this.currentTask.templateSelected.forEach(item => {
          promptTemplates.push(item.id)
        })
        customTemplatePrompt = this.currentTask.customDescription
        description = customTemplatePrompt
      }

      if (img === '' || mask === '') {
        this.$message.warn('请上传图片')
      } else if (textPromptType === 'input' && description === '') {
        this.$message.warn('请填正向提示语')
      } else if (textPromptType === 'template' && promptTemplates.length === 0) {
        this.$message.warn('请选择快捷模板')
      } else {
        const initImage = this.dataUrlToBase64(this.currentTask.img)
        const maskImageEdit = this.dataUrlToBase64(this.currentTask.mask)
        const taskId = this.currentTask.id

        this.maskToWhiteBg(this.currentTask.mask).then(res => {
          const maskImage = this.dataUrlToBase64(res)
          this.submitLoading = true
          execAITaskImageGenerate()
            .complete(() => (this.submitLoading = false))
            .success(res => {
              if (res.data.code === 500) {
                this.$message.error(res.data)
              } else {
                this.$nextTick(() => {
                  this.currentTask.status = STATUS_CALCULATION
                  this.isOpenEditShow = false
                  this.$emit('handleExecAITaskImageGenerateRes', res.data)
                })
              }
            })
            .send({
              taskId: taskId,
              textPromptType: textPromptType,
              textPrompt: description,
              negativeTextPrompt: negativeDescription,
              customTemplatePrompt: customTemplatePrompt,
              promptTemplateIdList: promptTemplates,
              segment: segment,
              image: initImage,
              maskImage: maskImage,
              editMaskImage: maskImageEdit
            })
        }, () => {
          this.$message.error('蒙版图转换失败')
        })
      }
    },
    maskToWhiteBg (data) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = function () {
          const canvas = document.createElement('canvas')
          canvas.width = this.naturalWidth
          canvas.height = this.naturalHeight
          const ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, 0)
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
          const length = imageData.data.length
          for (let index = 0; index < length; index += 4) {
            const red = imageData.data[index]
            const green = imageData.data[index + 1]
            const blue = imageData.data[index + 2]
            const average = Math.floor((red + green + blue) / 3)
            imageData.data[index] = average
            imageData.data[index + 1] = average
            imageData.data[index + 2] = average

            if (imageData.data[index + 3] === 0 || (imageData.data[index] === 0 && imageData.data[index + 1] === 0 && imageData.data[index + 2] === 0) ||
              (imageData.data[index] <= 40 && imageData.data[index + 1] <= 40 && imageData.data[index + 2] <= 40)) {
              imageData.data[index] = 0
              imageData.data[index + 1] = 0
              imageData.data[index + 2] = 0
              imageData.data[index + 3] = 255
            } else {
              imageData.data[index] = 255
              imageData.data[index + 1] = 255
              imageData.data[index + 2] = 255
              imageData.data[index + 3] = 255
            }
          }
          ctx.putImageData(imageData, 0, 0)

          const result = canvas.toDataURL('image/jpeg')
          resolve(result)
        }
        image.src = data
        image.onerror = () => {
          reject(new Error('maskToWhiteBg error'))
        }
      })
    },
    execAITaskImageSegment () {
      this.currentTask.loadingSegment = true
      this.currentTask.status = STATUS_LOADING
      const image = this.dataURLtoFile(this.currentTask.img, `${this.currentTask.title}.jpg`)
      const taskId = this.currentTask.id
      execAITaskImageSegment()
        .complete(() => {})
        .success(res => {
          if (res.data) {
            this.handleExecAITaskImageSegmentResult(res.data, taskId)
          } else {
            this.handleLoadingSegmentFail(taskId)
          }
        })
        .error(() => {
          this.handleLoadingSegmentFail(taskId)
        })
        .send(image)
    },
    execAITaskImageSegmentFromEditAgain (id) {
      this.currentTask.loadingSegment = true
      this.currentTask.status = STATUS_LOADING
      const taskId = this.currentTask.id
      getAITaskImageSegmentHistory()
        .complete(() => {})
        .success(res => {
          if (res.data) {
            const data = res.data
            const originData = JSON.stringify(data)
            if (this.currentTask.id === taskId) {
              this.changeTaskAfterExecAITaskImageSegment(this.currentTask, data, originData)
            } else {
              const task = this.$parent.$refs.taskListContent.findTaskFormTaskArr(taskId)
              this.changeTaskAfterExecAITaskImageSegment(task, data, originData)
            }
          } else {
            this.execAITaskImageSegment()
          }
        })
        .error(() => {
          this.execAITaskImageSegment()
        })
        .send(id)
    },
    dataURLtoFile (dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, {
        type: mime
      })
    },
    handleExecAITaskImageSegmentResult (id, taskId) {
      if (!this.imageSegmentResultIntervals[id]) {
        this.imageSegmentResultIntervalsIds[taskId] = id
        this.imageSegmentResultIntervals[id] = setInterval(() => {
          execAITaskImageSegmentResult()
            .complete(() => {})
            .success(res => {
              const data = res.data
              if (data) {
                if (data === 'error') {
                  this.handleLoadingSegmentFail(taskId)
                } else if (data !== 'progress') {
                  clearInterval(this.imageSegmentResultIntervals[id])
                  delete this.imageSegmentResultIntervals[id]
                  delete this.imageSegmentResultIntervalsIds[taskId]

                  const originData = JSON.stringify(data)
                  if (this.currentTask.id === taskId) {
                    this.changeTaskAfterExecAITaskImageSegment(this.currentTask, data, originData)
                  } else {
                    const task = this.$parent.$refs.taskListContent.findTaskFormTaskArr(taskId)
                    this.changeTaskAfterExecAITaskImageSegment(task, data, originData)
                  }
                }
              } else {
                this.handleLoadingSegmentFail(taskId)
              }
            })
            .error(() => {
              this.handleLoadingSegmentFail(taskId)
            })
            .send(id)
        }, 3000)
      }
    },
    handleLoadingSegmentFail (taskId) {
      if (this.currentTask.id === taskId) {
        this.changeTaskStatusFromLoadingSegmentFail(this.currentTask)
      } else {
        const task = this.$parent.$refs.taskListContent.findTaskFormTaskArr(taskId)
        this.changeTaskStatusFromLoadingSegmentFail(task)
      }
    },
    changeTaskStatusFromLoadingSegmentFail (task) {
      task.loadingSegment = false
      this.changeTaskStatus(task)
      this.$forceUpdate()

      if (task.imgWidth === 0) {
        const temp = new Image()
        temp.src = task.img
        temp.onload = () => {
          task.imgWidth = temp.width
          task.imgHeight = temp.height
        }
      }
    },
    changeTaskStatus (task) {
      const item = this.$parent.$refs.outputContent.findFromCheckResultExecutionIds(task.id)
      task.status = item ? STATUS_CALCULATION : ''
    },
    changeTaskAfterExecAITaskImageSegment (task, data, originData) {
      this.changeTaskStatus(task)
      task.maskSegmentArr = data.masks.reverse().map(obj => {
        const mask = obj.mask.map(item => {
          const newItem = new Array(obj.w)
          const itemLength = item.length / 2
          let start = 0
          for (let i = 0; i < itemLength; i++) {
            const end = start + item[i * 2 + 1] - 1
            newItem.fill(item[i * 2], start, end)
            start = end + 1
          }
          return newItem
        })

        return Object.assign({}, obj, {
          mask: mask
        })
      })
      task.originMaskSegment = originData
      task.imgWidth = data.width
      task.imgHeight = data.height
      task.loadingSegment = false
      this.$forceUpdate()
    },
    handleCropperImg (data) {
      this.spinningOneShow = false
      this.currentTask.img = data
      this.currentTask.mask = ''

      // const temp = new Image()
      // temp.src = data
      // temp.onload = () => {
      //   this.currentTask.imgWidth = temp.width
      //   this.currentTask.imgHeight = temp.height
      // }
      this.execAITaskImageSegment()
    },
    dragEnter (event) {
      event.preventDefault()
      this.dropzoneActive = true
    },
    dragLeave (event) {
      event.preventDefault()
      this.dropzoneActive = false
    },
    dragOver (event) {
      event.preventDefault()
    },
    dropFile (event) {
      event.preventDefault()
      this.dropzoneActive = false
      const files = event.dataTransfer.files
      this.handleFiles(files)
    },
    handleFiles (files) {
      if (files.length > 0) {
        this.getBase64(files[0], imageUrl => {
          // this.$refs.imgCropper.open(imageUrl)
          this.handleCropperImg(imageUrl)
          this.$refs.initImage.value = ''
        })
      }
    },
    getBase64 (img, callback) {
      const reader = new FileReader()
      reader.addEventListener('load', () => callback(reader.result))
      reader.readAsDataURL(img)
    },
    dataUrlToBase64 (img) {
      return img.replace('data:image/png;base64,', '').replace('data:image/jpeg;base64,', '')
    },
    handleExecResultEditAgain (exec) {
      const img2Img = exec.img2Img
      this.changeOpenEditLoad()
      this.urlToBase64(kit.str.isBlank(img2Img.imageMaskEdit) ? img2Img.imageMask : img2Img.imageMaskEdit).then((res) => {
        this.currentTask.mask = res.result
        this.currentTask.maskWidth = res.width
        this.currentTask.maskHeight = res.height
      }, () => {
        this.hideSpinningTwoShow()
        this.$message.error('蒙版图加载失败')
      })

      this.urlToBase64(img2Img.image).then((res) => {
        this.currentTask.img = res.result
        this.hideSpinningOneShow()
        this.hideSpinningTwoShow()
        this.execAITaskImageSegmentFromEditAgain(exec.id)
      }, () => {
        this.hideSpinningOneShow()
        this.$message.error('原图加载失败')
      })

      this.currentTask.description = ''
      this.currentTask.negativeDescription = ''
      if (img2Img.type === 'input') {
        this.changeOptActiveTab('0')
        this.currentTask.description = img2Img.prompt
        this.currentTask.negativeDescription = img2Img.negativePrompt
        this.$forceUpdate()
      } else if (img2Img.type === 'template') {
        this.changeOptActiveTab('1')
        this.currentTask.customDescription = img2Img.templatePrompt
        this.$nextTick(() => {
          this.changeTextPromptTypeTemplateShow(img2Img.templateIdList)
        })
      }
    },
    urlToBase64 (url) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = function () {
          const canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, 0)
          const result = canvas.toDataURL('image/png')
          resolve({
            result: result,
            width: canvas.width,
            height: canvas.height
          })
        }
        image.setAttribute('crossOrigin', 'Anonymous')
        image.src = url
        image.onerror = () => {
          reject(new Error('urlToBase64 error'))
        }
      })
    },
    handleRttOpenEditContentControl (opt) {
      switch (opt.type) {
        case 'changeOpenEditShow':
          this.changeOpenEditShow(opt.isOpenEditShow)
          break
        case 'handleCropperImg':
          this.handleCropperImg(opt.data)
          break
        case 'handleExecResultEditAgain':
          this.handleExecResultEditAgain(opt.exec)
          break
      }
    }
  }
}
</script>

<style scoped lang="less">
@deep: ~'>>>';
.open-edit-content {
  position: absolute;
  max-width: 868px;
  width: calc(100vw - 400px);
  left: 0;
  top: 0;
  height: 100%;
  transition: all .3s;
  .edit-mask {
    position: absolute;
    background-color: rgba(0,0,0,.45);
    width: 100vw;
    height: calc(100vh - 64px);
    z-index: 2;
  }
  .edit-content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: #ffffff;
    z-index: 3;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .opt-content {
      position: relative;
      width: 100%;
      flex: 1;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }

      .img-area {
        position: relative;
        width: 100%;
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
        .spin-content {
          position: relative;
          transition: opacity 0.3s;
          .area-div {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 384px;
            position: relative;
            color: #7d7675;
            border-radius: 12px;
            .upload-wrap {
              position: relative;
              width: 384px;
              height: 384px;
              margin-bottom: 8px;
              img {
                cursor: pointer;
                border-radius: 12px;
              }

              .uw-img {
                border: 2px solid #7530fe;
                border-radius: 12px;
              }

              .dropzone {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border: 2px dashed #7530fe;
                border-radius: 12px;
                cursor: pointer;
                .label1 {
                  color: #7530fe;
                  margin-top: 12px;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                }
                .label2 {
                  margin-top: 4px;
                  color: #403d3c;
                  margin-bottom: 8px;
                }
                .label3 {
                  color: #b8b2b2;
                  font-size: 11px;
                  font-weight: 400;
                  line-height: 20px;
                }
                .crop-size {
                  position: relative;
                  display: flex;
                  flex-wrap: wrap;
                  padding: 5px 20px;
                  align-items: center;
                  .crop-size-item {
                    position: relative;
                    font-size: 11px;
                    border: 1px solid #909399;
                    background-color: #ffffff;
                    border-radius: 3px;
                    margin: 4px 6px;
                    padding: 2px 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    &.active {
                      background-color: #3d8acf;
                      color: #ffffff;
                    }
                  }
                }
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }

              &@{deep} .ant-upload {
                border-radius: 8px;
              }

              .mask-area {
                position: relative;
                width: 100%;
                height: 100%;
                border: 2px solid #7530fe;
                border-radius: 12px;

                .absolute {
                  position: absolute;
                }

                .opacity5 {
                  opacity: 0.5;
                }

                .mask-img {
                  width: 100%;
                  height: 100%;
                  cursor: default;
                }

                .opt {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;

                  .mask-area-btn {
                    position: absolute;
                    bottom: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                    background-color: #7530fe;
                    color: #ffffff;
                    padding: 8px 18px;
                    cursor: pointer;
                    &:hover {
                      background-color: #8559fe;
                    }
                  }
                }

                .region-btn {
                  position: absolute;
                  width: 100%;
                  bottom: 15px;
                  display: flex;
                  justify-content: center;
                }
              }
            }
          }
        }
      }

      .tabs-area {
        position: relative;
        margin-top: 24px;
        width: 100%;
      }
    }
    .bottom-bar {
      position: relative;
      width: 100%;
      height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .open-icon {
    position: absolute;
    width: 20px;
    height: 96px;
    top: calc(50% - 96px / 2);
    background-color: #eae7fe;
    border-radius: 0 20px 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    cursor: pointer;
    &.edit-show {
      right: -20px;
    }
  }
}

.file-btn {
  position: relative;
  display: inline-block;
  color: #7d7675;
  text-decoration: underline;
  cursor: pointer;
  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    display: none;
  }
}

.upload-wrap @{deep} .ant-upload-list {
  display: none !important;
}

input, textarea {
  outline: none;
}

.ant-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
