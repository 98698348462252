<template>
  <a-layout id="login">
    <app-header class="header"></app-header>
    <a-layout>
      <transition name="fade" :duration="200">
        <div class="login-page">

          <div class="overlay absolute-full">

            <div class="panel-wrapper">
              <div class="logo"></div>
              <div class="app-info">
                <div class="app-name">登录或注册</div>
              </div>
              <div class="animate-wrapper">
                <login-panel ref="login"></login-panel>
              </div>
            </div>
            <div class="copyright"></div>
          </div>
        </div>
      </transition>
    </a-layout>
  </a-layout>
</template>

<script>
import AppHeader from '@/layout/app-header'
import LoginPanel from './login'
import { appStore } from '@/store'

export default {
  components: { AppHeader, LoginPanel },
  data () {
    return {
    }
  },
  computed: {
    ...(appStore.mapState(['version', 'name', 'logoUrl']))
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style lang="less">

#login {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
}
.app-content {
  padding: 0;
  position: relative !important;
}
.login-page {
  position: relative;
  flex: 1;
  background-color: #fff;
  .background-image {
    z-index: 0;
    background-color: #fff;
  }

  .overlay {
    //z-index: 1;
    //background-color: rgba(0,0,0,.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @panelWrapperWidth: 400px;
  @panelWidth: @panelWrapperWidth - 2; // 2px边框，20左间距
  @panelBorderColor: #efefef;
  .panel-wrapper {
    position: relative;
    width: @panelWrapperWidth;
    overflow: hidden;
    background-color: rgba(255,255,255,.8);
    //box-shadow: 0 0 8px rgba(0,0,0,.4);
    border-radius: 3px;
    .app-info {
      margin-top: 5px;
      text-align: center;
      color: #155492;
      margin-bottom: 10px;
    }
    .app-name {
      font-size: 18px;
      font-weight: bold;
      margin-right: 5px;
    }
  }
  .animate-wrapper {
    width: @panelWrapperWidth * 2;
    white-space: nowrap;
    .panel {
      display: block;
      float: left;
      min-height: 100px;
      width: @panelWidth;
      padding: 10px 0;
      margin: 0 !important;
      vertical-align: top;
    }
  }

  .login-panel {
    .code-image {
      width: 100px;
      height: 29px !important;
      cursor: pointer;
      img {
        width: inherit;
        height: inherit;
      }
    }
    .clear-username {
      display: flex;
      flex-flow: column;
      justify-content: center;
      color: #949494;
      cursor: pointer;
    }

    .validate-code-input {
      padding-right: 130px;
    }

    .validate-code-btn {
      position: absolute;
      top: -12px;
      right: 10px;
      cursor: pointer;
      color: #3d8acf;

      &.gray {
        cursor: default;
        color: #909399;
      }
    }
  }

  .panel {
    .form-item {
      display: flex;
      padding: 5px 15px;
      &:not(:first-of-type) {
        margin-top: 10px;
      }
      &.input-item {
        border-bottom: solid 1px @panelBorderColor;
        margin-left: 5px;
        margin-right: 15px;
        padding-left: 0;
        padding-right: 0;
      }
      &.button-bar {
        display: block;
        text-align: right !important;
      }
      label {
        display: flex;
        width: 60px;
        flex-flow: column;
        justify-content: center;
        text-align: right;
        margin-right: 10px;
        color: #474747;
        font-size: 14px;
      }
      input {
        flex: 1;
        padding: 4px 0;
        background-color: transparent;
        border: none;
        color: #414141;
        font-weight: bold;
        font-size: 16px;
        &:focus {
          outline: none;
        }
      }
    }
  }

  .copyright {
    position: absolute;
    width: 100%;
    bottom: 10px;
    text-align: center;
    color: #fff;
    font-size: 12px;
  }
}
</style>
