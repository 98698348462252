import defineApi from '../define-api'
import http from '../application-api'


export const getAITaskList = defineApi((config, type) => {
  config.params = {
    type: type
  }
  http.get('/ai_task/list', config)
})

export const addAITask = defineApi((config, type) => {
  config = Object.assign({
    params: {
      type: type
    }
  }, config)
  http.post('/ai_task/add', config)
})

export const execAITaskImageGenerate = defineApi((config, data) => {
  config.data = data
  http.post('/ai_task/exec/ai/img2img', config)
})

export const getAITaskExecListByTaskId = defineApi((config, taskId) => {
  config.params = {
    taskId: taskId
  }
  http.get('/ai_task/exec/list/by_task_id', config)
})

export const cancelAITaskExecListById = defineApi((config, id) => {
  config = Object.assign({
    params: {
      id: id
    }
  }, config)
  http.post('/ai_task/exec/cancel', config)
})

export const getAITaskExecImageGenerateCheckResult = defineApi((config, executionId) => {
  config.params = {
    executionId: executionId
  }
  http.get('/ai_task/exec/ai/check_result', config)
})

export const getAITaskExecHighImage = defineApi((config, id, number) => {
  config = Object.assign({
    params: {
      id: id,
      number: number
    }
  }, config)
  http.post('/ai_task/exec/get/high_image', config)
})

export const getPromptTemplateList = defineApi((config) => {
  http.get('/prompt_template/list/all', config)
})

export const execAITaskImageSegment = defineApi((config, file) => {
  const form = new FormData()
  form.append('file', file)
  config.data = form
  http.post('/ai_task/exec/image/segment', config)
})

export const execAITaskImageSegmentResult = defineApi((config, id) => {
  config = Object.assign({
    params: {
      id: id
    }
  }, config)
  http.post('/ai_task/exec/image/segment/result', config)
})

export const getAITaskImageSegmentHistory = defineApi((config, id) => {
  config = Object.assign({
    params: {
      id: id
    }
  }, config)
  http.post('/ai_task/exec/image/segment/history/by_id', config)
})

export const updateAITaskTitle = defineApi((config, id, title) => {
  config = Object.assign({
    params: {
      id: id,
      title: title
    }
  }, config)
  http.post('/ai_task/update/title', config)
})

export const deleteAITask = defineApi((config, id) => {
  config = Object.assign({
    params: {
      id: id
    }
  }, config)
  http.post('/ai_task/delete', config)
})


export const getAIBusinessPointInfo = defineApi((config, executionId) => {
  config.params = {
    executionId: executionId
  }
  http.get('/config/ai_business', config)
})


export const aiTaskExecEnlarge = defineApi((config, data) => {
  config.data = data
  http.post('/ai_task/exec/ai/enlarge', config)
})
