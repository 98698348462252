<template>
  <div
    v-if="createMenu"
    :style="{ height: '100%', borderRight: 0 }"
    class="app-menu"
  >
    <template v-for="menu in menus">
      <div class="icon-btn" :key="menu.id" @click="forward(menu)">
        <div class="icon-content">
          <span class="piece" :class="{'active': activeMenuId === menu.id}"></span>
          <div class="icon-view">
            <img class="icon" :class="{'active': activeMenuId === menu.id}" :src="iconSrc(menu.icon)" :alt="menu.title" >
            <div class="label">{{ menu.title }}</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import menuData from './workspace-menu-data'
import { userStore } from '@/store'
import { ROUTE_AGENT_PLATFORM } from '@/router/router-constants'

export default {
  data () {
    return {
      menus: this.initMenus(menuData),
      createMenu: false,
      asdf: require('../assets/images/model.png'),
      activeMenuId: ROUTE_AGENT_PLATFORM
    }
  },
  computed: {
    ...(userStore.mapState({
      isLogin: 'isLogin',
      userType: 'type'
    })),
    defaultOpenKeys () {
      const found = this.menus.find(m => m.visible)
      return found ? [found.id] : []
    },
    iconSrc () {
      return function (icon) {
        return require(`../assets/images/${icon}.png`)
      }
    }
  },
  watch: {
    isLogin: {
      handler: function () {
        if (this.isLogin) {
          this.updateMenuVisible(userStore.state.menuList)
          this.createMenu = true
        }
      },
      immediate: true
    }
  },
  methods: {
    initMenus (menus) {
      for (const m of menus) {
        if (m.menus && m.menus.length > 0) {
          m.visible = false
          // is group
          for (const mm of m.menus) {
            mm.visible = false
          }
        } else {
          m.visible = false
        }
      }
      return menus
    },
    /**
     * @param userMenus {Array} 当前登录用户拥有的菜单id
     */
    updateMenuVisible (userMenus) {
      const map = {
        all: userMenus.length === 1 && userMenus[0] === '_all'
      }
      for (const m of userMenus) {
        map[m] = true
      }
      for (const m of this.menus) {
        if (m.menus && m.menus.length > 0) {
          if (map.all || map[m.id]) {
            m.visible = true
            for (const mm of m.menus) {
              mm.visible = true
            }
          } else {
            for (const mm of m.menus) {
              mm.visible = map.all || map[mm.id]
              if (mm.visible) {
                m.visible = true
              }
            }
          }
        } else {
          m.visible = map.all || map[m.id]
        }
      }
    },
    forward (menu) {
      if (menu.click) {
        menu.click()
      } else {
        if (this.activeMenuId !== menu.id) {
          this.activeMenuId = menu.id
          this.$emit('menuForward', menu)
        }
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="less">
.app-menu {
  overflow-y: auto;
  overflow-x: hidden;
  // 隐藏滚动条
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  padding-top: 30px;
  padding-bottom: 100px;
  &::-webkit-scrollbar {
    display: none;
  }
  //border-right: solid 1px #e2e2e2 !important;

  .ant-menu-item-selected {
    background-color: #f6f6f6 !important;
    color: #2a2a2a !important;
  }
  .ant-menu-item::after {
    border-color: #2b2b2b !important;
  }

  .icon-btn {
    position: relative;
    display: flex;
    flex-direction: column;
    .icon-content {
      position: relative;
      display: flex;
      width: 100%;
      padding-left: 2px;
      .piece {
        width: 4px;
        height: 36px;
        background: transparent;
        border-radius: 0 4px 4px 0;
        margin-top: 6px;
        &.active {
          background: #7530fe;
        }
      }
      .icon-view {
        width: 100%;
        margin-bottom: 12px;
        cursor: pointer;
        margin-left: -4px;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;

        .icon {
          position: relative;
          width: 48px;
          height: 48px;
          margin-bottom: 4px;
          border-radius: 50%;
          &.active {
            border-radius: 8px;
          }
        }

        .label {
          position: relative;
          font-size: 12px;
          line-height: 20px;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
