<template>
  <div class="content">
    <div class="workspace-list-content">
      <task-content ref="taskListContent" :business-type="businessType" :current-task="currentTask" @handleTaskClick="handleTaskClick" @handleDeleteTask="handleDeleteTask"></task-content>
    </div>

    <div ref="workspaceContent" class="workspace-content">
      <template v-if="businessType === TYPE_RTT()">
        <rtt-open-edit-content ref="rttOpenEditContent" :business-type="businessType" :current-task="currentTask" @handleExecAITaskImageGenerateRes="handleExecAITaskImageGenerateRes"
                               @handleImageRegion="handleImageRegion"></rtt-open-edit-content>
      </template>
      <template v-if="businessType === TYPE_ENLARGE()">
        <enlarge-open-edit-content ref="enlargeOpenEditContent" :business-type="businessType" :current-task="currentTask" @handleExecAiTaskExecEnlarge="handleExecAiTaskExecEnlarge"
                                   @handleImageExpand="handleImageExpand"></enlarge-open-edit-content>
      </template>

      <output-content ref="outputContent" :business-type="businessType" :current-task="currentTask" @changeOpenEditShow="changeOpenEditShow"
                      @handleExecResultEditAgain="handleExecResultEditAgain"
                      @handleImageViewerOpen="handleImageViewerOpen"></output-content>

      <div class="case-content"  v-if="!currentTask">
        <case-tabs @handleImageViewerOpen="handleImageViewerOpen"></case-tabs>
      </div>
    </div>

    <image-viewer ref="imageViewer"></image-viewer>

    <image-region ref="imageRegion" @regionResult="handleRegionResult" @showImagePaintingBrush="handleImagePaintingBrushClick"></image-region>

    <image-painting-brush ref="imagePaintingBrush" @regionResult="handleRegionResult"></image-painting-brush>

    <img-cropper ref="imgCropper" @handleCropperImg="handleCropperImg"></img-cropper>

    <image-expand ref="imageExpand" @handleExpandImg="handleExpandImg"></image-expand>
  </div>
</template>

<script>
import { ROUTE_AGENT_PLATFORM } from '@/router/router-constants'
import ImgCropper from '@/pages/workspace/agent-platform/comp/img-cropper.vue'
import ImageViewer from '@/pages/workspace/agent-platform/comp/image-viewer.vue'

import ImageRegion from '@/pages/workspace/agent-platform/comp/image-region.vue'
import ImagePaintingBrush from '@/pages/workspace/agent-platform/comp/image-painting-brush.vue'
import TaskContent from '@/pages/workspace/agent-platform/task-content.vue'
import RttOpenEditContent from '@/pages/workspace/agent-platform/rtt-open-edit-content.vue'
import EnlargeOpenEditContent from '@/pages/workspace/agent-platform/enlarge-open-edit-content.vue'
import OutputContent from '@/pages/workspace/agent-platform/output-content.vue'
import CaseTabs from '@/pages/workspace/agent-platform/case-tabs.vue'
import ImageExpand from '@/pages/workspace/agent-platform/comp/image-expand.vue'

import { TYPE_ENLARGE, TYPE_RTT } from '@/pages/workspace/business-type'

export default {
  components: { TaskContent, RttOpenEditContent, EnlargeOpenEditContent, OutputContent, ImgCropper, ImageViewer, ImageRegion, ImagePaintingBrush, CaseTabs, ImageExpand },
  props: {
    businessType: { type: String }
  },
  data () {
    return {
      routeName: ROUTE_AGENT_PLATFORM,
      currentTask: null
    }
  },
  watch: {
    businessType (newVal) {
      this.currentTask = null
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    openEditContent () {
      let openEditContent
      if (this.businessType === this.TYPE_RTT()) {
        openEditContent = this.$refs.rttOpenEditContent
      } else if (this.businessType === this.TYPE_ENLARGE()) {
        openEditContent = this.$refs.enlargeOpenEditContent
      }
      return openEditContent
    },
    handleTaskClick (task) {
      if (task) {
        if (!this.currentTask || task.id !== this.currentTask.id) {
          this.currentTask = task
          this.$refs.outputContent.handleGetAITaskExecListByTaskId(this.currentTask.id, false)
        }
      } else {
        this.currentTask = task
      }
    },
    handleRegionResult (region) {
      this.currentTask.mask = region
      this.currentTask.maskWidth = this.currentTask.imgWidth
      this.currentTask.maskHeight = this.currentTask.imgHeight
      this.$refs.imageRegion.close()
    },
    handleImagePaintingBrushClick (maskList) {
      this.$refs.imagePaintingBrush.open(this.currentTask.img, maskList, document.body.clientWidth, this.currentTask.imgWidth, this.currentTask.imgHeight)
    },
    changeOpenEditShow (isOpenEditShow) {
      this.openEditContent().handleRttOpenEditContentControl({
        type: 'changeOpenEditShow',
        isOpenEditShow: isOpenEditShow
      })
    },
    handleExecResultEditAgain (exec) {
      this.openEditContent().handleRttOpenEditContentControl({
        type: 'handleExecResultEditAgain',
        exec: exec
      })
    },
    handleExecAITaskImageGenerateRes (id) {
      this.$refs.outputContent.handleExecAITaskImageGenerateRes(id)
    },
    handleExecAiTaskExecEnlarge (id) {
      this.$refs.outputContent.handleExecAITaskImageGenerateRes(id)
    },
    handleImageRegion () {
      this.$refs.imageRegion.open(this.currentTask, document.body.clientWidth)
    },
    handleImageViewerOpen (data) {
      this.$refs.imageViewer.open(data.arr, data.index)
    },
    handleCropperImg (data) {
      this.openEditContent().handleRttOpenEditContentControl({
        type: 'handleCropperImg',
        data: data
      })
    },
    handleExpandImg (data) {
      this.openEditContent().handleRttOpenEditContentControl({
        type: 'handleExpandImg',
        data: data
      })
    },
    handleDeleteTask (id) {
      if (this.currentTask && this.currentTask.id === id) {
        this.currentTask = null
        this.changeOpenEditShow(false)
      }
    },
    handleImageExpand (opt) {
      this.$refs.imageExpand.open(opt)
    },
    TYPE_RTT () {
      return TYPE_RTT
    },
    TYPE_ENLARGE () {
      return TYPE_ENLARGE
    }
  }
}
</script>

<style lang="less" scoped>
@deep: ~'>>>';
.content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;

  .workspace-list-content {
    position: relative;
    width: 320px;
    height: 100%;
    background-color: #ffffff;
    padding: 16px 8px;
    border-right: 1px solid #f5f3f3;
    display: flex;
    flex-direction: column;
  }

  .workspace-content {
    position: relative;
    flex: 1;
    overflow: hidden;

    &@{deep} ::-webkit-scrollbar {
      display: none;
    }

    .case-content {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.font24 {
  font-size: 24px;
}

/*修改滚动条样式*/
::-webkit-scrollbar{
  width:10px;
  height:10px;
}
::-webkit-scrollbar-track{
  opacity:0;/*完全透明*/
  border-radius:2px;
}
::-webkit-scrollbar-thumb{
  background: #bfbfbf;
  border-radius:10px;
}
::-webkit-scrollbar-thumb:hover{
  background: #bfbfbf;
}
::-webkit-scrollbar-corner{
  background: #ffffff;
  border-radius:50px;
}
</style>
