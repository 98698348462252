<template>
  <div class="opt-tab-kjmb">
    <template v-for="temp in templateList">
      <div class="template-content" :key="temp.id">
        <div class="title">{{temp.title}}</div>
        <div class="content">
          <template v-for="item in temp.items">
            <div v-if="temp.type === 'lora' || temp.type === 'textImage' || temp.type === 'bg'" class="item-img" :key="item.id" @click="handleTemplateItemSelect(temp, item)">
              <img :src="item.image" :class="{'active': item.active}">{{item.title}}
            </div>
            <div v-else class="item-label" :class="{'active': item.active}" :key="item.id" @click="handleTemplateItemSelect(temp, item)">{{item.title}}</div>
          </template>
        </div>
      </div>
    </template>
    <div class="template-content">
      <span>自定义提示词 ({{currentNum}} / 500)</span>
      <a-textarea class="description-textarea" v-model="descriptionProp" :maxLength="maxNum" @change="handleDescriptionPropChange"/>
    </div>
  </div>
</template>

<script>
import { getPromptTemplateList } from '@/http/api/ai-task'
import kit from '@/utils/kit'

export default {
  props: {
    customDescription: { type: String }
  },
  watch: {
    customDescription: {
      handler (newVal, oldVal) {
        this.descriptionProp = newVal
      }
    }
  },
  data () {
    return {
      templateList: [],
      templateSelected: [],
      descriptionProp: this.customDescription,
      ids: [],
      maxNum: 500
    }
  },
  mounted () {
  },
  computed: {
    currentNum () {
      return this.descriptionProp.length
    }
  },
  methods: {
    show (templateSelected, reload = true, ids = []) {
      this.templateSelected = [].concat(templateSelected)
      this.ids = ids
      if (reload) {
        this.handleGetPromptTemplateList()
      } else {
        this.changeTemplateListActive()
      }
    },
    handleGetPromptTemplateList () {
      getPromptTemplateList()
        .complete(() => {})
        .success(res => {
          this.templateList = res.data
          if (this.ids.length > 0) {
            this.templateSelected = []
            this.templateList.forEach(temp => {
              temp.items.forEach(item => {
                const index = this.ids.findIndex(obj => obj === item.id)
                if (index > -1) {
                  this.templateSelected.push({
                    id: item.id,
                    name: item.title
                  })
                }
              })
            })
            this.$emit('changeTemplateSelected', this.templateSelected)
            this.ids = []
          }
          this.changeTemplateListActive()
        })
        .send()
    },
    changeTemplateListActive () {
      this.templateList.forEach(temp => {
        temp.items.map(item => {
          const index = this.templateSelected.findIndex(obj => obj.id === item.id)
          return Object.assign(item, {
            active: index > -1
          })
        })
      })
      this.templateList = [].concat(this.templateList)
      this.$forceUpdate()
    },
    handleTemplateItemSelect (temp, item) {
      const obj = this.templateSelected.find(obj => obj.id === item.id)
      if (obj) {
        kit.arr.remove(this.templateSelected, obj, 'id')
      } else {
        if (!temp.multiSelect) {
          temp.items.forEach(obj => {
            const ts = this.templateSelected.find(ts => ts.id === obj.id)
            if (ts) {
              kit.arr.remove(this.templateSelected, ts, 'id')
            }
          })
        }
        this.templateSelected.push({
          id: item.id,
          name: item.title
        })
      }
      this.changeTemplateListActive()
      this.$emit('changeTemplateSelected', this.templateSelected)
    },
    handleDescriptionPropChange (e) {
      this.$emit('update:customDescription', this.descriptionProp)
    }
  }
}
</script>

<style lang="less" scoped>
.opt-tab-kjmb {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  .template-content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    .title {
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
      color: #403d3c;
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      .item-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 12px;
        margin-right: 16px;
        margin-top: 16px;
        cursor: pointer;
        color: #7d7675;
        img {
          width: 136px;
          height: 136px;
          border-radius: 12px;
          object-fit: cover;
          border: 4px solid transparent;
          &.active {
            border-color: #7530fe;
          }
        }
      }
      .item-label {
        margin-top: 16px;
        padding: 7px 16px;
        height: 36px;
        border-radius: 20px;
        border: 1px solid #d7d4d3;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #7d7675;
        margin-right: 8px;
        margin-bottom: 10px;
        &.active {
          border: 1px solid #7530fe;
          color: #7530fe;
        }
      }
    }
  }
  .description-textarea {
    width: 100%;
    background-color: #faf9f9;
    height: 168px;
    border-radius: 12px;
    color: #403d3c;
    padding: 12px;
    border: none;
    outline: none;
  }
}
</style>
