<template>
  <a-layout id="member">
    <app-header class="header"></app-header>
    <a-layout>
      <a-layout id="member-body-container" class="member-content">
        <div class="point-content">
          <div class="detail-content">
            <div class="item">
              <div class="title">当前套餐</div>
              <div class="label-content">
                <div class="label">我的套餐</div>
                <div class="label" v-if="vip">{{vip.title}}</div>
              </div>
              <div class="label-content">
                <div class="label">购买加油包折扣</div>
                <div class="label" v-if="vip">{{discountOnPointProductStr(vip.discountOnPointProduct)}}折</div>
              </div>
              <div class="label-content">
                <div class="label">同时执行任务数</div>
                <div class="label" v-if="vip">{{vip.runningTaskTotal}}</div>
              </div>
              <div class="label-content">
                <div class="label">到期时间</div>
                <div class="label" v-if="vip">{{expireDate(vip.expireDate)}}</div>
              </div>

              <a-button class="btn" type="primary" @click="handleBuyPoint">购买加油包</a-button>
            </div>
<!--            <div class="item flex-end">-->
<!--              <a-button class="btn" type="primary" @click="handleBuyPoint">购买点数包</a-button>-->
<!--            </div>-->
          </div>
        </div>
        <div class="package-content">
          <a-spin :spinning="isCreateOrder && !isOrderPointProduct">
            <div class="package-body" ref="vipServicesContent">
              <template v-for="cvs in customerVipServicesArr">
                <div class="package-item" :class="{'active': vip && vip.vipId === cvs.id}" :key="cvs.id">
                  <div class="title-content">
                    <div class="title">{{cvs.title}}</div>
                    <div class="label">{{cvs.type === 'default' ? '注册账号，可获得' : '购买后，您将获得'}}</div>
                  </div>
                  <div class="discount-content">
                    <div class="item" v-if="cvs.discountOnPointProductVisible">
                      <a-icon v-if="cvs.discountOnPointProductEnabled" class="green" type="check-square" />
                      <a-icon v-else class="red" type="close" />
                      <div class="label">购买加油包享{{discountOnPointProductStr(cvs.discountOnPointProduct)}}折</div>
                    </div>
                    <div class="item" v-if="cvs.runningTaskTotalVisible">
                      <a-icon v-if="cvs.runningTaskTotalEnabled" class="green" type="check-square" />
                      <a-icon v-else class="red" type="close" />
                      <div class="label">最多同时执行{{cvs.runningTaskTotal}}条任务</div>
                    </div>
                  </div>
                  <div class="prices-content">
                    <template v-for="price in cvs.prices">
                      <div class="item" :key="price.id">
                        <div class="label">点数{{price.point}} / {{price.price}}元 / {{price.days}}天内有效</div>
                        <a-button type="primary" v-if="cvs.type !== 'default'" @click="handleVipServicesPay(cvs, price)">购买</a-button>
                      </div>
                    </template>
                  </div>
                  <div class="my-package" v-if="vip && vip.vipId === cvs.id">
                    <div class="label">我的套餐</div>
                  </div>
                </div>
              </template>
            </div>
          </a-spin>
        </div>


        <div class="buy-point-content" v-if="isBuyPointModelShow">
          <div class="by-content">
            <div class="by-header">
              <div class="label">购买加油包</div><a-icon type="close" class="close" @click="handleBuyPointClose"/>
            </div>
            <div class="by-body" ref="pointProductContent">
              <template v-for="ppl in pointProductList">
                <div class="point-product" :class="{'active': selectedPointProductId === ppl.id}" :key="ppl.id" @click="handlePointProductClick(ppl)">
                  <div class="point">{{ppl.point}}点数</div>
                  <div class="price">¥{{ppl.price}}</div>
                  <div class="label">{{ppl.days}}天加油包</div>
                </div>
              </template>
            </div>
            <div class="by-footer">
              <a-button class="buy-btn" type="primary" :loading="isCreateOrder" @click="handlePointProductBuyClick">立即购买</a-button>
            </div>
          </div>
        </div>

        <div class="buy-point-qrcode-content" v-if="isBuyPointQRCodeModelShow">
          <div class="byq-content">
            <div v-if="isOrderPointProduct" class="byq-header">
              <div class="label">购买{{selectedPointProduct.days}}天{{selectedPointProduct.point}}加油包</div><a-icon type="close" class="close" @click="handleBuyPointQRCodeClose"/>
            </div>
            <div v-else class="byq-header">
              <div class="label">{{selectedVipServices.title}}</div><a-icon type="close" class="close" @click="handleBuyPointQRCodeClose"/>
            </div>
            <div class="byq-body">
              <div class="qrcode" ref="qrcode-config">
                <div class="unactive" v-if="isOrderStatusExpire || isOrderStatusFail"></div>
                <div class="status" v-if="isOrderStatusExpire" @click="handleReLoadOrder"><div>支付码过期</div><div>点击重新获取二维码</div></div>
                <div class="status" v-if="isOrderStatusFail" @click="handleReLoadOrder"><div>支付失败</div><div>点击重新获取二维码</div></div>
              </div>
              <div class="detail">
                <div class="label1 ag14">
                  扫码支付：<div class="ag32">¥{{order.discountPrice}}</div><div class="discount" v-if="order.price !== order.discountPrice">¥{{order.price}}</div>
                </div>
                <div class="label2 ag14"><img width="24" height="24" src="../../assets/images/svg/wechat.svg" alt="wechat">请使用微信扫码支付</div>
                <div class="label2 ag12">支付完成后默认您同意<a href="https://www.baidu.com" target="_blank">相关协议条例</a></div>
                <div class="label3 ag12">虚拟商品, 不支持退款, 最终解释权归AIShop所有</div>
              </div>
            </div>
          </div>
        </div>
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<script>
import AppHeader from '@/layout/app-header.vue'
import { getPointProductList, pointProductOrderCreate, getPayOrderStatus, getCustomerVipServices, customerVipOrderCreate } from '@/http/api/member'
import kit from '@/utils/kit'
import QRCode from 'qrcodejs2'
import { userStore } from '@/store'

export default {
  components: { AppHeader },
  data () {
    return {
      isBuyPointModelShow: false,
      pointProductList: [],
      selectedPointProduct: null,
      selectedPointProductId: '',
      clickObj: {
        firstTime: '',
        lastTime: '',
        isClick: false
      },
      isBuyPointQRCodeModelShow: false,
      qrcode: null,
      order: null,
      checkPayOrderStatusInterval: null,
      isOrderStatusExpire: false,
      isOrderStatusFail: false,
      isCreateOrder: false,
      isOrderPointProduct: true,
      customerVipServicesArr: [],
      selectedVipServices: null
    }
  },
  computed: {
    ...(userStore.mapState(['vip'])),
    discountOnPointProductStr () {
      return function (val) {
        return val === 1 ? '不打' : val * 10
      }
    },
    expireDate () {
      return function (val) {
        return kit.str.isBlank(val) ? '永久' : val
      }
    }
  },
  mounted () {
    this.handleCustomerVipServices()
    this.$nextTick(() => {
      this.scrollInit(this.$refs.vipServicesContent)
    })
  },
  beforeDestroy () {
    this.clearCheckPayOrderStatusInterval()
  },
  methods: {
    handleBuyPoint () {
      this.selectedPointProduct = null
      this.selectedPointProductId = ''
      getPointProductList()
        .success(res => {
          this.pointProductList = res.data
          this.isBuyPointModelShow = true
          this.$nextTick(() => {
            this.scrollInit(this.$refs.pointProductContent)
          })
        })
        .send()
    },
    handleBuyPointClose () {
      this.isBuyPointModelShow = false
      this.isCreateOrder = false
    },
    handlePointProductClick (ppl) {
      if (this.clickObj.isClick) {
        this.selectedPointProduct = ppl
        this.selectedPointProductId = ppl.id
      }
    },
    handlePointProductBuyClick () {
      if (kit.str.isBlank(this.selectedPointProductId)) {
        this.$message.warn('请选择需要购买的点数包')
      } else {
        this.isOrderPointProduct = true
        this.isCreateOrder = true
        pointProductOrderCreate()
          .complete(() => {
            this.isCreateOrder = false
          })
          .success(res => {
            this.order = res.data
            this.isBuyPointQRCodeModelShow = true
            this.$nextTick(() => {
              this.clearCheckPayOrderStatusInterval()
              this.createQrcode(this.order.qrCode)
            })
          })
          .send(this.selectedPointProductId)
      }
    },
    createQrcode (codeUrl) {
      if (codeUrl && codeUrl !== '') {
        this.qrcode = new QRCode(this.$refs['qrcode-config'], {
          width: 240,
          height: 240,
          text: codeUrl
        })
        this.queryOrder()
      }
    },
    queryOrder () {
      if (!this.checkPayOrderStatusInterval) {
        this.checkPayOrderStatusInterval = setInterval(() => {
          getPayOrderStatus()
            .success(res => {
              switch (res.data) {
                case 'success': // 支付成功
                  this.handleBuyPointQRCodeClose()
                  if (this.isOrderPointProduct) {
                    this.handleBuyPointClose()
                  }
                  this.$message.success(this.isOrderPointProduct ? '购买点数包成功' : '购买套餐成功')
                  this.$dispatcher.dispatch('changeCustomerMyInfoListen')
                  break
                case 'fail': // 支付失败
                  this.clearCheckPayOrderStatusInterval()
                  this.isOrderStatusFail = true
                  break
                case 'expire': // 支付码过期
                  this.clearCheckPayOrderStatusInterval()
                  this.isOrderStatusExpire = true
                  break
                case 'wait': // 等待客户支付
                  break
              }
            })
            .send(this.order.orderNo)
        }, 3000)
      }
    },
    handleReLoadOrder () {
      this.handleBuyPointQRCodeClose()
      this.handlePointProductBuyClick()
    },
    clearCheckPayOrderStatusInterval () {
      if (this.checkPayOrderStatusInterval) {
        clearInterval(this.checkPayOrderStatusInterval)
        this.checkPayOrderStatusInterval = null
      }
    },
    handleBuyPointQRCodeClose () {
      this.isBuyPointQRCodeModelShow = false
      this.isOrderStatusExpire = false
      this.isOrderStatusFail = false
      this.selectedVipServices = null
      this.isCreateOrder = false
      this.order = null
      this.clearCheckPayOrderStatusInterval()
    },
    handleCustomerVipServices () {
      getCustomerVipServices()
        .success(res => {
          this.customerVipServicesArr = res.data
        })
        .send(this.selectedPointProductId)
    },
    handleVipServicesPay (cvs, price) {
      if (!this.isCreateOrder) {
        this.isOrderPointProduct = false
        this.isCreateOrder = true
        customerVipOrderCreate()
          .complete(() => {
            this.isCreateOrder = false
          })
          .success(res => {
            this.order = res.data
            this.selectedVipServices = cvs
            this.isBuyPointQRCodeModelShow = true
            this.$nextTick(() => {
              this.clearCheckPayOrderStatusInterval()
              this.createQrcode(this.order.qrCode)
            })
          })
          .send(cvs.id, price.id)
      }
    },
    scrollInit (ppc) {
      let flag
      let downX
      let scrollLeft
      const that = this
      ppc.addEventListener('mousedown', function (event) {
        flag = true
        downX = event.clientX
        scrollLeft = this.scrollLeft
        that.clickObj.firstTime = new Date().getTime()
      })
      ppc.addEventListener('mousemove', function (event) {
        if (flag) {
          var moveX = event.clientX
          var scrollX = moveX - downX
          this.scrollLeft = scrollLeft - scrollX
          that.clickObj.lastTime = new Date().getTime()
        }
      })
      ppc.addEventListener('mouseup', function (event) {
        flag = false
        that.clickObj.isClick = (that.clickObj.lastTime - that.clickObj.firstTime) < 200
      })
      ppc.addEventListener('mouseleave', function (event) {
        flag = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
#member {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
}
.member-content {
  padding: 0;
  position: relative !important;
  overflow-x: hidden;
  overflow-y: auto;
  .point-content {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 80px 0;
    .detail-content {
      display: flex;
      margin-top: 60px;
      max-width: 600px;
      width: 100%;
      .item {
        position: relative;
        display: flex;
        flex: 1 1;
        flex-direction: column;
        border-radius: 12px;
        background-color: #15084c;
        color: #ffffff;
        padding: 36px 36px 42px;
        .title {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 28px;
          font-weight: 500;
        }
        .label-content {
          display: flex;
          height: 32px;
          width: 100%;
          justify-content: space-between;
          .label {
            font-size: 16px;
            line-height: 24px;
          }
        }
        .btn {
          position: relative;
        }

        &:not(:last-child) {
          margin-right: 40px;
        }
      }

      .flex-end {
        justify-content: flex-end;
      }
    }
  }

  .package-content {
    position: relative;
    width: 100%;
    padding: 80px 120px;
    background-color: #eae7fe;
    margin: 0 auto;
    .package-body {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      justify-content: center;
      .package-item {
        position: relative;
        display: inline-flex;
        min-width: 400px;
        flex-direction: column;
        flex-shrink: 0;
        background-color: #ffffff;
        border-radius: 12px;
        color: #403d3c;
        max-width: 305px;
        &.active {
          color: #ffffff;
          background-color: #15084c;
        }
        &:not(:last-child) {
          margin-right: 20px;
        }
        .title-content {
          display: flex;
          flex-direction: column;
          margin: 32px 32px 16px;
          .title {
            align-self: center;
            font-size: 26px;
            line-height: 32px;
            font-weight: 500;
            margin-bottom: 16px;
          }
          .label {
            font-size: 16px;
          }
        }
        .discount-content {
          border-top: 1px solid #edeaea;
          display: flex;
          flex-direction: column;
          padding: 15px 55px;
          .item {
            display: flex;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            align-items: center;
            &:not(:last-child) {
              margin-bottom: 13px;
            }
            .label {
              margin-left: 8px;
            }
            .red {
              color: red;
            }
            .green {
              color: green;
            }
          }
        }
        .prices-content {
          border-top: 1px solid #edeaea;
          padding: 22px 32px;
          display: flex;
          flex-direction: column;
          .item {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            justify-content: space-between;
            .label {
              flex-shrink: 0;
              font-size: 16px;
            }
            &:not(:last-child) {
              margin-bottom: 13px;
            }
          }
        }
        .my-package {
          display: flex;
          padding: 20px 32px;
          justify-content: center;
          align-items: center;
          .label {
            width: 100%;
            background-color: rgb(35, 14, 120);
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            text-align: center;
            padding: 8px 0;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

.buy-point-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.45);
  z-index: 2800;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .by-content {
    position: relative;
    min-width: 500px;
    max-width: 800px;
    padding: 25px;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    line-height: 22px;
    .by-header {
      position: relative;
      display: flex;
      width: 100%;
      color: #000000;
      align-items: center;
      font-size: 18px;
      margin-bottom: 15px;
      .label {
        flex: 1;
        text-align: center;
      }
      .close {
        position: absolute;
        right: 0;
        padding: 2px;
        cursor: pointer;
      }
    }
    .by-body {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 10px 0;
      .point-product {
        position: relative;
        width: 150px;
        background-color: #f5f3f3;
        padding: 16px;
        border-radius: 12px;
        flex-shrink: 0;
        color: #403d3c;
        cursor: pointer;
        border: 1px solid transparent;
        &.active {
          border-color: #7530fe;
          background-color: #eae7fe;
        }
        &:not(:last-child) {
          margin-right: 10px;
        }
        .point {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
        }
        .price {
          margin-top: 12px;
          font-size: 32px;
          line-height: 40px;
          font-weight: 500;
        }
        .label {
          font-size: 12px;
          line-height: 20px;
          font-weight: 400;
        }
      }
    }
    .by-footer {
      display: flex;
      margin-top: 15px;
      justify-content: center;
      .buy-btn {
        flex: 1;
        border-radius: 10px;
        height: 48px;
      }
    }
  }


}

.buy-point-qrcode-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.45);
  z-index: 2820;
  display: flex;
  justify-content: center;
  align-items: center;
  .byq-content {
    position: relative;
    min-width: 350px;
    background-color: #ffffff;
    background-clip: padding-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #403d3c;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    padding: 20px 24px;
    .byq-header {
      position: relative;
      display: flex;
      width: 100%;
      color: #000000;
      align-items: center;
      font-size: 18px;
      margin-bottom: 24px;
      .label {
        flex: 1;
        text-align: center;
        font-weight: 500;
        line-height: 26px;
      }
      .close {
        position: absolute;
        right: 0;
        padding: 2px;
        cursor: pointer;
      }
    }
    .byq-body {
      width: 360px;
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: #faf9f9;
      padding: 24px 0;
      border-radius: 8px;
      .qrcode {
        box-sizing: border-box;
        margin: 0;
        padding: 12px;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        line-height: 1.5714285714285714;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid rgba(5, 5, 5, 0.06);
        position: relative;
        width: 240px;
        height: 240px;
        overflow: hidden;

        .unactive {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba(0,0,0,.45);
        }

        .status {
          position: absolute;
          line-height: 22px;
          background-color: #ffffff;
          border-radius: 8px;
          box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
          padding: 20px 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
        }
      }
      .detail {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 24px;
        line-height: 22px;
        .label1 {
          display: flex;
          justify-content: center;
          align-items: baseline;
          .discount {
            margin-left: 5px;
            text-decoration: line-through;
          }
        }
        .label2 {
          margin-top: 12px;
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
          }
        }
        .label3 {
          color: #b8b2b2;
          margin-top: 33px;
        }
      }
    }
  }
}

.order-status-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.45);
  z-index: 2830;
  display: flex;
  justify-content: center;
  align-items: center;
  .os-content {
    position: relative;
    min-width: 350px;
    background-color: #ffffff;
    background-clip: padding-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #403d3c;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    padding: 20px 24px;
    line-height: 22px;
  }
}

.ag12 {
  font-size: 12px;
  font-weight: 400;
}
.ag14 {
  font-size: 14px;
  font-weight: 400;
}

.ag32 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
}

/*修改滚动条样式*/
::-webkit-scrollbar{
  width:10px;
  height:10px;
}
::-webkit-scrollbar-track{
  opacity:0;/*完全透明*/
  border-radius:2px;
}
::-webkit-scrollbar-thumb{
  background: #bfbfbf;
  border-radius:10px;
}
::-webkit-scrollbar-thumb:hover{
  background: #bfbfbf;
}
::-webkit-scrollbar-corner{
  background: #ffffff;
  border-radius:50px;
}
</style>
