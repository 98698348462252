<template>
  <a-layout id="pointHistory">
    <app-header class="header"></app-header>
    <a-layout id="history-body-container" class="history-content">
      <app-page :route-name="routeName" :content-height.sync="contentHeight" :header-show="false">
        <a-table
          class="history-table"
          slot="center"
          size="middle"
          rowKey="id"
          :columns="dataColumns"
          :data-source="dataList"
          :pagination="false"
          :scroll="{ y: antdTableY }"
          :loading="dataLoading"
        >
        </a-table>

        <div slot="bottom">
          <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
        </div>
      </app-page>
    </a-layout>
  </a-layout>
</template>

<script>
import { ROUTE_POINT_HISTORY } from '@/router/router-constants'
import AppHeader from '@/layout/app-header.vue'
import { getPointHistoryListMy } from '@/http/api/user'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import PaginationMixin from '@/mixins/pagination'

export default {
  components: { AppHeader },
  data () {
    return {
      routeName: ROUTE_POINT_HISTORY,
      dataList: [],
      dataLoading: false,
      dataColumns: [
        { title: '剩余点数', dataIndex: 'point' },
        { title: '变动点数', dataIndex: 'pointChanged' },
        { title: '时间', dataIndex: 'createTime' },
        { title: '类型', dataIndex: 'type', customRender: (type) => this.$const.pointHistoryType.text[type] },
        { title: '消息', dataIndex: 'message' }
      ]
    }
  },
  mixins: [AntdTableHeightMixin, PaginationMixin],
  computed: {
  },
  mounted () {
    this.loadData()
  },
  beforeDestroy () {
  },
  methods: {
    loadData () {
      this.dataLoading = true
      getPointHistoryListMy()
        .complete(() => (this.dataLoading = false))
        .success(res => {
          this.dataList = res.data.records
          this.page.total = res.data.total
        })
        .send(this.getDefaultRequestParams())
    }
  }
}
</script>

<style lang="less" scoped>
@deep: ~'>>>';
#pointHistory {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
}
.history-content {
  padding: 0;
  position: relative !important;
  overflow-x: hidden;
  overflow-y: auto;
  .history-table {
    padding: 0 20px;
  }
}

/*修改滚动条样式*/
@{deep} ::-webkit-scrollbar{
  width:10px;
  height:10px;
}
@{deep} ::-webkit-scrollbar-track{
  opacity:0;/*完全透明*/
  border-radius:2px;
}
@{deep} ::-webkit-scrollbar-thumb{
  background: #bfbfbf;
  border-radius:10px;
}
@{deep} ::-webkit-scrollbar-thumb:hover{
  background: #bfbfbf;
}
@{deep} ::-webkit-scrollbar-corner{
  background: #ffffff;
  border-radius:50px;
}
</style>
