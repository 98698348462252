<template>
  <div class="case-show-model" v-if="currentShowCase">
    <div class="show-detail">
      <div class="model-mask" @click.stop.prevent="handleModelClose"></div>
      <div class="model-head">
        <a-icon type="close" class="model-head-btn" @click="handleModelClose"/>
        <a-icon type="zoom-in" class="model-head-btn" :class="{'not-click': !(params.zoomVal < 8.0)}" @click="handleZoomIn"/>
        <a-icon type="zoom-out" class="model-head-btn" :class="{'not-click': !(params.zoomVal > 1.0)}" @click="handleZoomOut"/>
        <a-icon type="redo" class="model-head-btn" @click="handleRotateRight"/>
        <a-icon type="undo" class="model-head-btn" @click="handleRotateLeft"/>
        <a-icon type="swap" class="model-head-btn" @click="handleFlipX"/>
        <a-icon type="swap" class="model-head-btn rotate90"  @click="handleFlipY"/>
        <div v-if="showCases.length > 0" class="title">{{showNum + 1}} / {{showCases.length}}</div>
      </div>
      <div class="origin-image left">
        <div class="img-box">
          <div class="fill-box"></div>
          <img ref="oriImage" id="oriImage" class="origin" :src="currentShowCase.ori" alt="origin">
          <img class="mask-img show" v-show="currentShowCase.mask" :style="noOpiStyle" :src="currentShowCase.mask" alt="mask">
        </div>
      </div>
      <div class="origin-image right" @mousewheel="wheelImg(this)">
        <div class="opi-div"></div>

        <img v-if="currentShowCase.opi" :src="currentShowCase.opi" alt="origin" class="zoom-img" :class="{'active': isImgActive}" :style="zoomStyle" @mousedown="imgMove" />

        <div v-else class="zoom-img" :style="noOpiStyle">
          <div class="no-opi-img" :class="{'active': isImgActive}" :style="zoomStyle" @mousedown="imgMove">失败</div>
        </div>

        <div class="show-prompt" :class="{'show' : isShowPromptDetail}">
          <div class="prompt-detail">
            <div class="type-text">{{currentShowCase.type}}</div>
            <template v-for="description in currentShowCase.descriptions">
              <div class="description-text" :key="description">{{description}}</div>
            </template>
          </div>
        </div>
      </div>

      <div v-if="showCases.length > 0" class="preview-switch-btn btn-left" @click="handleBtnLeft"><a-icon type="left" /></div>
      <div v-if="showCases.length > 0" class="preview-switch-btn btn-right" @click="handleBtnRight"><a-icon type="right" /></div>
    </div>

  </div>
</template>

<script>

export default {
  data () {
    return {
      isShowPromptDetail: false,
      showCases: [],
      showNum: 0,
      currentShowCase: null,
      zoomStyle: {
        transform: 'scale(1, 1)'
      },
      noOpiStyle: {
        width: 0,
        height: 0
      },
      isFlipX: false,
      isFlipY: false,
      rotateVal: 0,
      scaleMax: 7.0,
      params: {
        zoomVal: 1,
        left: 0,
        top: 0
      },
      isImgActive: false,
      imgTransitionTimeout: null
    }
  },
  computed: {
  },
  methods: {
    open (val, num) {
      this.showCases = val
      this.showNum = num
      this.changeCurrentShowCase(num)
      window.addEventListener('resize', this.handleResize)
    },
    handleModelClose () {
      this.currentShowCase = null
      this.showCases = []
      window.removeEventListener('resize', this.handleResize)
      this.handleClose()
    },
    wheelImg (e) {
      const event = e || window.event
      this.params.zoomVal += event.wheelDelta / 1200
      if (this.params.zoomVal >= this.scaleMax) {
        this.params.zoomVal = this.scaleMax
        this.changeImageTransform()
      } else if (this.params.zoomVal >= 1.0) {
        this.changeImageTransform()
      } else {
        this.params.zoomVal = 1.0
        this.changeImageTransform()
        return false
      }
    },
    imgMove (e) {
      const oImg = e.target
      const disX = e.clientX - oImg.offsetLeft
      const disY = e.clientY - oImg.offsetTop
      document.onmousemove = (e) => {
        e.preventDefault()
        const left = e.clientX - disX
        const top = e.clientY - disY
        this.$nextTick(() => {
          this.zoomStyle = Object.assign({}, this.zoomStyle, {
            left: ` ${left}px`,
            top: `${top}px`
          })
        })
      }
      document.onmouseup = (e) => {
        document.onmousemove = null
        document.onmouseup = null
      }
    },
    handleClose () {
      this.zoomStyle = {
        transform: 'scale(1, 1)'
      }
      this.params = {
        zoomVal: 1,
        left: 0,
        top: 0
      }
      this.showNum = 0
      this.rotateVal = 0
      this.isFlipX = false
      this.isFlipY = false
    },
    handleZoomIn () {
      const num = this.params.zoomVal + 0.5
      this.params.zoomVal = Math.min(num, this.scaleMax)
      this.changeImageTransform()
    },
    handleZoomOut () {
      const num = this.params.zoomVal - 0.5
      this.params.zoomVal = Math.max(num, 1.0)
      this.changeImageTransform()
    },
    handleRotateLeft () {
      this.rotateVal -= 90
      this.changeImageTransform()
    },
    handleRotateRight () {
      this.rotateVal += 90
      this.changeImageTransform()
    },
    handleFlipX () {
      this.isFlipX = !this.isFlipX
      this.changeImageTransform()
    },
    handleFlipY () {
      this.isFlipY = !this.isFlipY
      this.changeImageTransform()
    },
    changeImageTransform (isActive = true) {
      if (isActive) {
        this.isImgActive = true
        if (!this.imgTransitionTimeout) {
          clearTimeout(this.imgTransitionTimeout)
        }
        this.imgTransitionTimeout = setTimeout(() => {
          this.isImgActive = false
          this.imgTransitionTimeout = null
        }, 500)
      }
      const zoomVal = this.params.zoomVal
      // this.zoomStyle = `transform:scale(${this.isFlipX ? '-' : ''}${zoomVal}, ${this.isFlipY ? '-' : ''}${zoomVal}) rotate(${this.rotateVal}deg);`
      this.zoomStyle = Object.assign({}, this.zoomStyle, {
        transform: `scale(${this.isFlipX ? '-' : ''}${zoomVal}, ${this.isFlipY ? '-' : ''}${zoomVal}) rotate(${this.rotateVal}deg)`
      })
    },
    handleBtnLeft () {
      if (this.showNum === 0) {
        this.showNum = this.showCases.length - 1
      } else {
        this.showNum -= 1
      }
      this.changeCurrentShowCase(this.showNum)
    },
    handleBtnRight () {
      if (this.showNum === this.showCases.length - 1) {
        this.showNum = 0
      } else {
        this.showNum += 1
      }
      this.changeCurrentShowCase(this.showNum)
    },
    changeCurrentShowCase (num) {
      this.currentShowCase = this.showCases[num]
      this.changeNoOpiStyle()
    },
    changeNoOpiStyle () {
      this.$nextTick(() => {
        const height = Math.min(document.body.clientHeight, this.currentShowCase.imageHeight)
        const scale = height / this.currentShowCase.imageHeight
        const width = scale * this.currentShowCase.imageWidth
        Object.assign(this.noOpiStyle, {
          width: ` ${width}px`,
          height: `${height}px`
        })
      })
    },
    handleResize () {
      this.changeNoOpiStyle()
    }
  }
}
</script>

<style lang="less" scoped>
.case-show-model {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .show-detail {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .model-mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.55);
    }
    .model-head {
      position: fixed;
      top: 0;
      width: 100%;
      height: 46px;
      background: rgba(0,0,0,.1);
      z-index: 2;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding-right: 24px;
      .model-head-btn {
        font-size: 22px;
        color: #ffffff;
        cursor: pointer;
        &.rotate90 {
          transform: rotate(90deg);
        }
        &:not(:first-child) {
          margin-right: 35px;
        }
        &.not-click {
          cursor: default;
          color: #5b5b5b;
        }
      }
      .title {
        position: absolute;
        align-self: center;
        color: #ffffff;
        font-size: 18px;
        width: 60px;
        left: calc(50% - 32px);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .origin-image {
      max-width: 50%;
      min-width: 300px;
      font-size: 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .img-box {
        pointer-events: auto;
        max-width: 100%;
        max-height: 100%;
        transform: translateZ(0) scaleX(1) rotate(0deg);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .fill-box {
          position: relative;
          width: 50vw;
          height: 100vh;
        }
        &.active {
          transition: all 0.4s ease-in;
        }
        .origin {
          position: absolute;
        }
        img {
          object-fit: cover;
          max-width: 100%;
          max-height: 100%;
          height: auto;
        }

        .mask-img {
          position: absolute;
          display: none;
        }
      }
      &.left:hover .show {
        display: block;
      }
      .show-prompt {
        position: absolute;
        width: 100%;
        display: none;
        flex-direction: column;
        align-items: center;
        bottom: 0;
        z-index: 3;
        .prompt-detail {
          background-color: #ffffff;
          padding: 24px;
          border-radius: 12px;
          text-align: left;
          position: absolute;
          bottom: 20px;
          width: 90%;
          box-shadow: 0 4px 10px 0 #0000001a;
          .type-text {
            font-size: 18px;
          }
          .description-text {
            margin-top: 14px;
            font-size: 16px;
          }
        }
      }

      .opi-div {
        position: relative;
        width: 100vw;
        height: 100vh;
      }
      .zoom-img {
        position: absolute;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
        height: auto;
        z-index: 1;
        cursor: move;
        .no-opi-img {
          position: relative;
          width: 100%;
          height: 100%;
          background-color: #ffffff;
          color: #000000;
          font-size: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &.right {
        justify-content: flex-start;
      }

      &.right:hover .show {
        display: flex;
      }
    }
  }
}

.preview-switch-btn {
  position: fixed;
  top: 50%;
  z-index: 1081;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  color: rgb(255, 255, 255);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s;
  pointer-events: auto;
  &.btn-right {
    right: 12px;
  }
  &.btn-left {
    left: 12px;
  }
}

</style>
