<template>
  <div class="panel login-panel">

    <a-form-model @submit="login()" @submit.native.prevent :label-col="{span: 4}" :wrapper-col="{span: 18}">
      <a-form-model-item label="手机号">
        <a-input v-model="phone" allow-clear>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="验证码">
        <a-input class="validate-code-input" :maxLength="10" v-model="validateCode">
        </a-input>
        <div v-if="isGetValidateCode" class="validate-code-btn gray">{{count}}s后可再次获取</div>
        <div v-else class="validate-code-btn" @click.stop="handleValidateCodeClick">获取验证码</div>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 18, offset: 4 }">
        <a-button type="primary" @click="login" block :loading="loading">{{loginButtonText}}</a-button>
      </a-form-model-item>
    </a-form-model>

  </div>
</template>

<script>
import { login, getValidateCodeForPhone } from '@/http/api/user'
import { ROUTE_WORKSPACE } from '@/router/router-constants'
import kit from '@/utils/kit'

export default {
  data () {
    return {
      loginButtonText: '下一步',
      loading: false,
      phone: null,
      validateCode: null,
      isGetValidateCode: false,
      count: 60,
      countInterval: null
    }
  },
  computed: {
  },
  methods: {
    login () {
      if (kit.str.isBlank(this.phone)) {
        this.$message.error('请填写手机号')
      } else if (kit.str.isBlank(this.validateCode)) {
        this.$message.error('请填写验证码')
      } else {
        this.loading = true
        login()
          .complete(() => {
            this.loading = false
          })
          .success(res => {
            localStorage.setItem('phone', this.phone)
            localStorage.setItem('vip', res.data.vip)
            this.$router.push({ name: ROUTE_WORKSPACE })
          })
          .send(this.phone, this.validateCode)
      }
    },
    clearUsername () {
      this.phone = ''
      localStorage.removeItem('phone')
    },
    handleValidateCodeClick () {
      if (kit.str.isBlank(this.phone)) {
        this.$message.error('请填写手机号')
      } else {
        this.isGetValidateCode = true
        getValidateCodeForPhone()
          .complete(() => {
          })
          .success(res => {
            if (res.code && res.code === 500) {
              this.$message.error(res.message)
            }
          })
          .send(this.phone)
        this.countInterval = setInterval(() => {
          if (this.count > 1) {
            this.count -= 1
          } else {
            this.isGetValidateCode = false
            this.count = 60
            clearInterval(this.countInterval)
          }
        }, 1000)
      }
    }
  },
  beforeDestroy () {
    if (this.countInterval) {
      clearInterval(this.countInterval)
      this.countInterval = null
    }
  },
  mounted () {
    this.phone = localStorage.getItem('phone')
  }
}
</script>

<style lang="less" scoped>
</style>
